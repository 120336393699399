@charset "UTF-8";
.flex-center {
  display: inline-flex !important;
}

.border-dashed {
  border-style: dashed !important;
  border-width: inherit !important;
}

[class~=hover-shadow-],
[class*=hover-shadow-],
.hover-shadow {
  transition: all 0.25s ease-in-out;
}

.hover-shadow-sm:hover {
  box-shadow: 0 0.125rem 0.25rem rgba(10, 13, 16, 0.075) !important;
}

.hover-shadow:hover {
  box-shadow: 0 0.5rem 1rem rgba(10, 13, 16, 0.1) !important;
}

.hover-shadow-lg:hover {
  box-shadow: 0 1rem 3rem rgba(10, 13, 16, 0.125) !important;
}

.hover-shadow-xl:hover {
  box-shadow: 0 2rem 4rem rgba(10, 13, 16, 0.175) !important;
}

.hover-shadow-3d:hover {
  box-shadow: 5px 5px 0px 0px #0a0d10 !important;
}

.grid-separator {
  overflow: hidden;
}
.grid-separator > * {
  box-shadow: -1px -1px 0px 0px #f0f5fa;
}
.grid-separator > [class*=col-],
.grid-separator [class^=col-] {
  padding: 2rem 1rem;
}

.text-white .grid-separator > * {
  box-shadow: -1px -1px 0 0 rgba(255, 255, 255, 0.125);
}

/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #0072ff;
  --bs-indigo: #5865f5;
  --bs-purple: #C26BF8;
  --bs-pink: #F15B66;
  --bs-red: #F93131;
  --bs-orange: #FF6635;
  --bs-yellow: #FCC63B;
  --bs-green: #4FC04D;
  --bs-teal: #389769;
  --bs-cyan: #22b5e7;
  --bs-white: #fff;
  --bs-gray: #39414A;
  --bs-gray-dark: #1F252C;
  --bs-gray-100: #F8FBFE;
  --bs-gray-200: #F0F5FA;
  --bs-gray-300: #E5EBF1;
  --bs-gray-400: #DAE1E8;
  --bs-gray-500: #9EA7B0;
  --bs-gray-600: #39414A;
  --bs-gray-700: #2B323A;
  --bs-gray-800: #1F252C;
  --bs-gray-900: #14191E;
  --bs-primary: #389769;
  --bs-secondary: #2B323A;
  --bs-success: #4FC04D;
  --bs-info: #0072ff;
  --bs-warning: #FCC63B;
  --bs-danger: #F15B66;
  --bs-light: #F8FBFE;
  --bs-white: #fff;
  --bs-gray-200: #F0F5FA;
  --bs-gray-800: #1F252C;
  --bs-dark: #0A0D10;
  --bs-primary-rgb: 56, 151, 105;
  --bs-secondary-rgb: 43, 50, 58;
  --bs-success-rgb: 79, 192, 77;
  --bs-info-rgb: 0, 114, 255;
  --bs-warning-rgb: 252, 198, 59;
  --bs-danger-rgb: 241, 91, 102;
  --bs-light-rgb: 248, 251, 254;
  --bs-white-rgb: 255, 255, 255;
  --bs-gray-200-rgb: 240, 245, 250;
  --bs-gray-800-rgb: 31, 37, 44;
  --bs-dark-rgb: 10, 13, 16;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 10, 13, 16;
  --bs-body-color-rgb: 20, 25, 30;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: "Jost", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #14191E;
  --bs-body-bg: #fff;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(10, 13, 16, 0);
}

hr {
  margin: 1rem 0;
  color: currentColor;
  background-color: currentColor;
  border: 0;
  opacity: 0.1;
}

hr:not([size]) {
  height: 1px;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: 700;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #389769;
  text-decoration: none;
}
a:hover {
  color: #2d7954;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #F15B66;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #14191E;
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 600;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #39414A;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 400;
}

.display-1 {
  font-size: calc(1.725rem + 5.7vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 6rem;
  }
}

.display-2 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.4rem + 1.8vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.75rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #39414A;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #E5EBF1;
  border-radius: 0.375rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #39414A;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 2rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 2rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 2.5rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 2.5rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 3rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 3rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 3.5rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 3.5rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 4rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 4rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 4.5rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 4.5rem;
}

.g-11,
.gx-11 {
  --bs-gutter-x: 5rem;
}

.g-11,
.gy-11 {
  --bs-gutter-y: 5rem;
}

.g-12,
.gx-12 {
  --bs-gutter-x: 5.5rem;
}

.g-12,
.gy-12 {
  --bs-gutter-y: 5.5rem;
}

.g-13,
.gx-13 {
  --bs-gutter-x: 6rem;
}

.g-13,
.gy-13 {
  --bs-gutter-y: 6rem;
}

.g-14,
.gx-14 {
  --bs-gutter-x: 6.5rem;
}

.g-14,
.gy-14 {
  --bs-gutter-y: 6.5rem;
}

.g-15,
.gx-15 {
  --bs-gutter-x: 7rem;
}

.g-15,
.gy-15 {
  --bs-gutter-y: 7rem;
}

.g-16,
.gx-16 {
  --bs-gutter-x: 8rem;
}

.g-16,
.gy-16 {
  --bs-gutter-y: 8rem;
}

.g-17,
.gx-17 {
  --bs-gutter-x: 9rem;
}

.g-17,
.gy-17 {
  --bs-gutter-y: 9rem;
}

.g-18,
.gx-18 {
  --bs-gutter-x: 10rem;
}

.g-18,
.gy-18 {
  --bs-gutter-y: 10rem;
}

.g-19,
.gx-19 {
  --bs-gutter-x: 11rem;
}

.g-19,
.gy-19 {
  --bs-gutter-y: 11rem;
}

.g-20,
.gx-20 {
  --bs-gutter-x: 12rem;
}

.g-20,
.gy-20 {
  --bs-gutter-y: 12rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 2rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 2rem;
  }

  .g-sm-6,
.gx-sm-6 {
    --bs-gutter-x: 2.5rem;
  }

  .g-sm-6,
.gy-sm-6 {
    --bs-gutter-y: 2.5rem;
  }

  .g-sm-7,
.gx-sm-7 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-7,
.gy-sm-7 {
    --bs-gutter-y: 3rem;
  }

  .g-sm-8,
.gx-sm-8 {
    --bs-gutter-x: 3.5rem;
  }

  .g-sm-8,
.gy-sm-8 {
    --bs-gutter-y: 3.5rem;
  }

  .g-sm-9,
.gx-sm-9 {
    --bs-gutter-x: 4rem;
  }

  .g-sm-9,
.gy-sm-9 {
    --bs-gutter-y: 4rem;
  }

  .g-sm-10,
.gx-sm-10 {
    --bs-gutter-x: 4.5rem;
  }

  .g-sm-10,
.gy-sm-10 {
    --bs-gutter-y: 4.5rem;
  }

  .g-sm-11,
.gx-sm-11 {
    --bs-gutter-x: 5rem;
  }

  .g-sm-11,
.gy-sm-11 {
    --bs-gutter-y: 5rem;
  }

  .g-sm-12,
.gx-sm-12 {
    --bs-gutter-x: 5.5rem;
  }

  .g-sm-12,
.gy-sm-12 {
    --bs-gutter-y: 5.5rem;
  }

  .g-sm-13,
.gx-sm-13 {
    --bs-gutter-x: 6rem;
  }

  .g-sm-13,
.gy-sm-13 {
    --bs-gutter-y: 6rem;
  }

  .g-sm-14,
.gx-sm-14 {
    --bs-gutter-x: 6.5rem;
  }

  .g-sm-14,
.gy-sm-14 {
    --bs-gutter-y: 6.5rem;
  }

  .g-sm-15,
.gx-sm-15 {
    --bs-gutter-x: 7rem;
  }

  .g-sm-15,
.gy-sm-15 {
    --bs-gutter-y: 7rem;
  }

  .g-sm-16,
.gx-sm-16 {
    --bs-gutter-x: 8rem;
  }

  .g-sm-16,
.gy-sm-16 {
    --bs-gutter-y: 8rem;
  }

  .g-sm-17,
.gx-sm-17 {
    --bs-gutter-x: 9rem;
  }

  .g-sm-17,
.gy-sm-17 {
    --bs-gutter-y: 9rem;
  }

  .g-sm-18,
.gx-sm-18 {
    --bs-gutter-x: 10rem;
  }

  .g-sm-18,
.gy-sm-18 {
    --bs-gutter-y: 10rem;
  }

  .g-sm-19,
.gx-sm-19 {
    --bs-gutter-x: 11rem;
  }

  .g-sm-19,
.gy-sm-19 {
    --bs-gutter-y: 11rem;
  }

  .g-sm-20,
.gx-sm-20 {
    --bs-gutter-x: 12rem;
  }

  .g-sm-20,
.gy-sm-20 {
    --bs-gutter-y: 12rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 2rem;
  }

  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 2rem;
  }

  .g-md-6,
.gx-md-6 {
    --bs-gutter-x: 2.5rem;
  }

  .g-md-6,
.gy-md-6 {
    --bs-gutter-y: 2.5rem;
  }

  .g-md-7,
.gx-md-7 {
    --bs-gutter-x: 3rem;
  }

  .g-md-7,
.gy-md-7 {
    --bs-gutter-y: 3rem;
  }

  .g-md-8,
.gx-md-8 {
    --bs-gutter-x: 3.5rem;
  }

  .g-md-8,
.gy-md-8 {
    --bs-gutter-y: 3.5rem;
  }

  .g-md-9,
.gx-md-9 {
    --bs-gutter-x: 4rem;
  }

  .g-md-9,
.gy-md-9 {
    --bs-gutter-y: 4rem;
  }

  .g-md-10,
.gx-md-10 {
    --bs-gutter-x: 4.5rem;
  }

  .g-md-10,
.gy-md-10 {
    --bs-gutter-y: 4.5rem;
  }

  .g-md-11,
.gx-md-11 {
    --bs-gutter-x: 5rem;
  }

  .g-md-11,
.gy-md-11 {
    --bs-gutter-y: 5rem;
  }

  .g-md-12,
.gx-md-12 {
    --bs-gutter-x: 5.5rem;
  }

  .g-md-12,
.gy-md-12 {
    --bs-gutter-y: 5.5rem;
  }

  .g-md-13,
.gx-md-13 {
    --bs-gutter-x: 6rem;
  }

  .g-md-13,
.gy-md-13 {
    --bs-gutter-y: 6rem;
  }

  .g-md-14,
.gx-md-14 {
    --bs-gutter-x: 6.5rem;
  }

  .g-md-14,
.gy-md-14 {
    --bs-gutter-y: 6.5rem;
  }

  .g-md-15,
.gx-md-15 {
    --bs-gutter-x: 7rem;
  }

  .g-md-15,
.gy-md-15 {
    --bs-gutter-y: 7rem;
  }

  .g-md-16,
.gx-md-16 {
    --bs-gutter-x: 8rem;
  }

  .g-md-16,
.gy-md-16 {
    --bs-gutter-y: 8rem;
  }

  .g-md-17,
.gx-md-17 {
    --bs-gutter-x: 9rem;
  }

  .g-md-17,
.gy-md-17 {
    --bs-gutter-y: 9rem;
  }

  .g-md-18,
.gx-md-18 {
    --bs-gutter-x: 10rem;
  }

  .g-md-18,
.gy-md-18 {
    --bs-gutter-y: 10rem;
  }

  .g-md-19,
.gx-md-19 {
    --bs-gutter-x: 11rem;
  }

  .g-md-19,
.gy-md-19 {
    --bs-gutter-y: 11rem;
  }

  .g-md-20,
.gx-md-20 {
    --bs-gutter-x: 12rem;
  }

  .g-md-20,
.gy-md-20 {
    --bs-gutter-y: 12rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 2rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 2rem;
  }

  .g-lg-6,
.gx-lg-6 {
    --bs-gutter-x: 2.5rem;
  }

  .g-lg-6,
.gy-lg-6 {
    --bs-gutter-y: 2.5rem;
  }

  .g-lg-7,
.gx-lg-7 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-7,
.gy-lg-7 {
    --bs-gutter-y: 3rem;
  }

  .g-lg-8,
.gx-lg-8 {
    --bs-gutter-x: 3.5rem;
  }

  .g-lg-8,
.gy-lg-8 {
    --bs-gutter-y: 3.5rem;
  }

  .g-lg-9,
.gx-lg-9 {
    --bs-gutter-x: 4rem;
  }

  .g-lg-9,
.gy-lg-9 {
    --bs-gutter-y: 4rem;
  }

  .g-lg-10,
.gx-lg-10 {
    --bs-gutter-x: 4.5rem;
  }

  .g-lg-10,
.gy-lg-10 {
    --bs-gutter-y: 4.5rem;
  }

  .g-lg-11,
.gx-lg-11 {
    --bs-gutter-x: 5rem;
  }

  .g-lg-11,
.gy-lg-11 {
    --bs-gutter-y: 5rem;
  }

  .g-lg-12,
.gx-lg-12 {
    --bs-gutter-x: 5.5rem;
  }

  .g-lg-12,
.gy-lg-12 {
    --bs-gutter-y: 5.5rem;
  }

  .g-lg-13,
.gx-lg-13 {
    --bs-gutter-x: 6rem;
  }

  .g-lg-13,
.gy-lg-13 {
    --bs-gutter-y: 6rem;
  }

  .g-lg-14,
.gx-lg-14 {
    --bs-gutter-x: 6.5rem;
  }

  .g-lg-14,
.gy-lg-14 {
    --bs-gutter-y: 6.5rem;
  }

  .g-lg-15,
.gx-lg-15 {
    --bs-gutter-x: 7rem;
  }

  .g-lg-15,
.gy-lg-15 {
    --bs-gutter-y: 7rem;
  }

  .g-lg-16,
.gx-lg-16 {
    --bs-gutter-x: 8rem;
  }

  .g-lg-16,
.gy-lg-16 {
    --bs-gutter-y: 8rem;
  }

  .g-lg-17,
.gx-lg-17 {
    --bs-gutter-x: 9rem;
  }

  .g-lg-17,
.gy-lg-17 {
    --bs-gutter-y: 9rem;
  }

  .g-lg-18,
.gx-lg-18 {
    --bs-gutter-x: 10rem;
  }

  .g-lg-18,
.gy-lg-18 {
    --bs-gutter-y: 10rem;
  }

  .g-lg-19,
.gx-lg-19 {
    --bs-gutter-x: 11rem;
  }

  .g-lg-19,
.gy-lg-19 {
    --bs-gutter-y: 11rem;
  }

  .g-lg-20,
.gx-lg-20 {
    --bs-gutter-x: 12rem;
  }

  .g-lg-20,
.gy-lg-20 {
    --bs-gutter-y: 12rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 2rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 2rem;
  }

  .g-xl-6,
.gx-xl-6 {
    --bs-gutter-x: 2.5rem;
  }

  .g-xl-6,
.gy-xl-6 {
    --bs-gutter-y: 2.5rem;
  }

  .g-xl-7,
.gx-xl-7 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-7,
.gy-xl-7 {
    --bs-gutter-y: 3rem;
  }

  .g-xl-8,
.gx-xl-8 {
    --bs-gutter-x: 3.5rem;
  }

  .g-xl-8,
.gy-xl-8 {
    --bs-gutter-y: 3.5rem;
  }

  .g-xl-9,
.gx-xl-9 {
    --bs-gutter-x: 4rem;
  }

  .g-xl-9,
.gy-xl-9 {
    --bs-gutter-y: 4rem;
  }

  .g-xl-10,
.gx-xl-10 {
    --bs-gutter-x: 4.5rem;
  }

  .g-xl-10,
.gy-xl-10 {
    --bs-gutter-y: 4.5rem;
  }

  .g-xl-11,
.gx-xl-11 {
    --bs-gutter-x: 5rem;
  }

  .g-xl-11,
.gy-xl-11 {
    --bs-gutter-y: 5rem;
  }

  .g-xl-12,
.gx-xl-12 {
    --bs-gutter-x: 5.5rem;
  }

  .g-xl-12,
.gy-xl-12 {
    --bs-gutter-y: 5.5rem;
  }

  .g-xl-13,
.gx-xl-13 {
    --bs-gutter-x: 6rem;
  }

  .g-xl-13,
.gy-xl-13 {
    --bs-gutter-y: 6rem;
  }

  .g-xl-14,
.gx-xl-14 {
    --bs-gutter-x: 6.5rem;
  }

  .g-xl-14,
.gy-xl-14 {
    --bs-gutter-y: 6.5rem;
  }

  .g-xl-15,
.gx-xl-15 {
    --bs-gutter-x: 7rem;
  }

  .g-xl-15,
.gy-xl-15 {
    --bs-gutter-y: 7rem;
  }

  .g-xl-16,
.gx-xl-16 {
    --bs-gutter-x: 8rem;
  }

  .g-xl-16,
.gy-xl-16 {
    --bs-gutter-y: 8rem;
  }

  .g-xl-17,
.gx-xl-17 {
    --bs-gutter-x: 9rem;
  }

  .g-xl-17,
.gy-xl-17 {
    --bs-gutter-y: 9rem;
  }

  .g-xl-18,
.gx-xl-18 {
    --bs-gutter-x: 10rem;
  }

  .g-xl-18,
.gy-xl-18 {
    --bs-gutter-y: 10rem;
  }

  .g-xl-19,
.gx-xl-19 {
    --bs-gutter-x: 11rem;
  }

  .g-xl-19,
.gy-xl-19 {
    --bs-gutter-y: 11rem;
  }

  .g-xl-20,
.gx-xl-20 {
    --bs-gutter-x: 12rem;
  }

  .g-xl-20,
.gy-xl-20 {
    --bs-gutter-y: 12rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 2rem;
  }

  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 2rem;
  }

  .g-xxl-6,
.gx-xxl-6 {
    --bs-gutter-x: 2.5rem;
  }

  .g-xxl-6,
.gy-xxl-6 {
    --bs-gutter-y: 2.5rem;
  }

  .g-xxl-7,
.gx-xxl-7 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-7,
.gy-xxl-7 {
    --bs-gutter-y: 3rem;
  }

  .g-xxl-8,
.gx-xxl-8 {
    --bs-gutter-x: 3.5rem;
  }

  .g-xxl-8,
.gy-xxl-8 {
    --bs-gutter-y: 3.5rem;
  }

  .g-xxl-9,
.gx-xxl-9 {
    --bs-gutter-x: 4rem;
  }

  .g-xxl-9,
.gy-xxl-9 {
    --bs-gutter-y: 4rem;
  }

  .g-xxl-10,
.gx-xxl-10 {
    --bs-gutter-x: 4.5rem;
  }

  .g-xxl-10,
.gy-xxl-10 {
    --bs-gutter-y: 4.5rem;
  }

  .g-xxl-11,
.gx-xxl-11 {
    --bs-gutter-x: 5rem;
  }

  .g-xxl-11,
.gy-xxl-11 {
    --bs-gutter-y: 5rem;
  }

  .g-xxl-12,
.gx-xxl-12 {
    --bs-gutter-x: 5.5rem;
  }

  .g-xxl-12,
.gy-xxl-12 {
    --bs-gutter-y: 5.5rem;
  }

  .g-xxl-13,
.gx-xxl-13 {
    --bs-gutter-x: 6rem;
  }

  .g-xxl-13,
.gy-xxl-13 {
    --bs-gutter-y: 6rem;
  }

  .g-xxl-14,
.gx-xxl-14 {
    --bs-gutter-x: 6.5rem;
  }

  .g-xxl-14,
.gy-xxl-14 {
    --bs-gutter-y: 6.5rem;
  }

  .g-xxl-15,
.gx-xxl-15 {
    --bs-gutter-x: 7rem;
  }

  .g-xxl-15,
.gy-xxl-15 {
    --bs-gutter-y: 7rem;
  }

  .g-xxl-16,
.gx-xxl-16 {
    --bs-gutter-x: 8rem;
  }

  .g-xxl-16,
.gy-xxl-16 {
    --bs-gutter-y: 8rem;
  }

  .g-xxl-17,
.gx-xxl-17 {
    --bs-gutter-x: 9rem;
  }

  .g-xxl-17,
.gy-xxl-17 {
    --bs-gutter-y: 9rem;
  }

  .g-xxl-18,
.gx-xxl-18 {
    --bs-gutter-x: 10rem;
  }

  .g-xxl-18,
.gy-xxl-18 {
    --bs-gutter-y: 10rem;
  }

  .g-xxl-19,
.gx-xxl-19 {
    --bs-gutter-x: 11rem;
  }

  .g-xxl-19,
.gy-xxl-19 {
    --bs-gutter-y: 11rem;
  }

  .g-xxl-20,
.gx-xxl-20 {
    --bs-gutter-x: 12rem;
  }

  .g-xxl-20,
.gy-xxl-20 {
    --bs-gutter-y: 12rem;
  }
}
.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #14191E;
  --bs-table-striped-bg: rgba(10, 13, 16, 0.05);
  --bs-table-active-color: #14191E;
  --bs-table-active-bg: rgba(10, 13, 16, 0.1);
  --bs-table-hover-color: #14191E;
  --bs-table-hover-bg: rgba(10, 13, 16, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #14191E;
  vertical-align: top;
  border-color: #F0F5FA;
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table > :not(:first-child) {
  border-top: 2px solid currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #d7eae1;
  --bs-table-striped-bg: #cddfd7;
  --bs-table-striped-color: #0A0D10;
  --bs-table-active-bg: #c3d4cc;
  --bs-table-active-color: #0A0D10;
  --bs-table-hover-bg: #c8d9d1;
  --bs-table-hover-color: #0A0D10;
  color: #0A0D10;
  border-color: #c3d4cc;
}

.table-secondary {
  --bs-table-bg: #d5d6d8;
  --bs-table-striped-bg: #cbccce;
  --bs-table-striped-color: #0A0D10;
  --bs-table-active-bg: #c1c2c4;
  --bs-table-active-color: #0A0D10;
  --bs-table-hover-bg: #c6c7c9;
  --bs-table-hover-color: #0A0D10;
  color: #0A0D10;
  border-color: #c1c2c4;
}

.table-success {
  --bs-table-bg: #dcf2db;
  --bs-table-striped-bg: #d2e7d1;
  --bs-table-striped-color: #0A0D10;
  --bs-table-active-bg: #c7dbc7;
  --bs-table-active-color: #0A0D10;
  --bs-table-hover-bg: #cce1cc;
  --bs-table-hover-color: #0A0D10;
  color: #0A0D10;
  border-color: #c7dbc7;
}

.table-info {
  --bs-table-bg: #cce3ff;
  --bs-table-striped-bg: #c2d8f3;
  --bs-table-striped-color: #0A0D10;
  --bs-table-active-bg: #b9cee7;
  --bs-table-active-color: #0A0D10;
  --bs-table-hover-bg: #bdd3ed;
  --bs-table-hover-color: #0A0D10;
  color: #0A0D10;
  border-color: #b9cee7;
}

.table-warning {
  --bs-table-bg: #fef4d8;
  --bs-table-striped-bg: #f2e8ce;
  --bs-table-striped-color: #0A0D10;
  --bs-table-active-bg: #e6ddc4;
  --bs-table-active-color: #0A0D10;
  --bs-table-hover-bg: #ece3c9;
  --bs-table-hover-color: #0A0D10;
  color: #0A0D10;
  border-color: #e6ddc4;
}

.table-danger {
  --bs-table-bg: #fcdee0;
  --bs-table-striped-bg: #f0d4d6;
  --bs-table-striped-color: #0A0D10;
  --bs-table-active-bg: #e4c9cb;
  --bs-table-active-color: #0A0D10;
  --bs-table-hover-bg: #eaced0;
  --bs-table-hover-color: #0A0D10;
  color: #0A0D10;
  border-color: #e4c9cb;
}

.table-light {
  --bs-table-bg: #F8FBFE;
  --bs-table-striped-bg: #eceff2;
  --bs-table-striped-color: #0A0D10;
  --bs-table-active-bg: #e0e3e6;
  --bs-table-active-color: #0A0D10;
  --bs-table-hover-bg: #e6e9ec;
  --bs-table-hover-color: #0A0D10;
  color: #0A0D10;
  border-color: #e0e3e6;
}

.table-dark {
  --bs-table-bg: #0A0D10;
  --bs-table-striped-bg: #16191c;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #232528;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #1c1f22;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #232528;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.475rem + 1px);
  padding-bottom: calc(0.475rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.75rem + 1px);
  padding-bottom: calc(0.75rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.325rem + 1px);
  padding-bottom: calc(0.325rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #39414A;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.475rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #14191E;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #E5EBF1;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #14191E;
  background-color: #fff;
  border-color: #9ccbb4;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(56, 151, 105, 0.1);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::placeholder {
  color: #39414A;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #F8FBFE;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.475rem 1rem;
  margin: -0.475rem -1rem;
  margin-inline-end: 1rem;
  color: #14191E;
  background-color: #F0F5FA;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: all 0.25s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #e4e9ee;
}
.form-control::-webkit-file-upload-button {
  padding: 0.475rem 1rem;
  margin: -0.475rem -1rem;
  margin-inline-end: 1rem;
  color: #14191E;
  background-color: #F0F5FA;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: all 0.25s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #e4e9ee;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.475rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #14191E;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.65rem + 2px);
  padding: 0.325rem 0.75rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}
.form-control-sm::file-selector-button {
  padding: 0.325rem 0.75rem;
  margin: -0.325rem -0.75rem;
  margin-inline-end: 0.75rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.325rem 0.75rem;
  margin: -0.325rem -0.75rem;
  margin-inline-end: 0.75rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1.5rem + 2px);
  padding: 0.75rem 1.75rem;
  font-size: 1.25rem;
  border-radius: 0.575rem;
}
.form-control-lg::file-selector-button {
  padding: 0.75rem 1.75rem;
  margin: -0.75rem -1.75rem;
  margin-inline-end: 1.75rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.75rem 1.75rem;
  margin: -0.75rem -1.75rem;
  margin-inline-end: 1.75rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.95rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.65rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1.5rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.475rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.375rem;
}
.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.375rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.475rem 3rem 0.475rem 1rem;
  -moz-padding-start: calc(1rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #14191E;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%231F252C' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 16px 12px;
  border: 1px solid #E5EBF1;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #9ccbb4;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(56, 151, 105, 0.1);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 1rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #F0F5FA;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #14191E;
}

.form-select-sm {
  padding-top: 0.325rem;
  padding-bottom: 0.325rem;
  padding-left: 0.75rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.form-select-lg {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.75rem;
  font-size: 1.25rem;
  border-radius: 0.575rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.875em;
  margin-bottom: 0.25rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.875em;
}

.form-check-input {
  width: 1.375em;
  height: 1.375em;
  margin-top: 0.0625em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid #E5EBF1;
  appearance: none;
  color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25rem;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #9ccbb4;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(56, 151, 105, 0.1);
}
.form-check-input:checked {
  background-color: #389769;
  border-color: #389769;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #389769;
  border-color: #389769;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 3em;
}
.form-switch .form-check-input {
  width: 2.5em;
  margin-left: -3em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23E5EBF1'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2.5em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%239ccbb4'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(56, 151, 105, 0.1);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(56, 151, 105, 0.1);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #389769;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #c3e0d2;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #E5EBF1;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #389769;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #c3e0d2;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #E5EBF1;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #9EA7B0;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #9EA7B0;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 1rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control {
  padding: 1rem 1rem;
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.475rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #14191E;
  text-align: center;
  white-space: nowrap;
  background-color: #F0F5FA;
  border: 1px solid #E5EBF1;
  border-radius: 0.375rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.75rem 1.75rem;
  font-size: 1.25rem;
  border-radius: 0.575rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.325rem 0.75rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 4rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #4FC04D;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(79, 192, 77, 0.9);
  border-radius: 0.375rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #4FC04D;
  padding-right: calc(1.5em + 0.95rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%234FC04D' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.2375rem) center;
  background-size: calc(0.75em + 0.475rem) calc(0.75em + 0.475rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #4FC04D;
  box-shadow: 0 0 0 0.25rem rgba(79, 192, 77, 0.1);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.95rem);
  background-position: top calc(0.375em + 0.2375rem) right calc(0.375em + 0.2375rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #4FC04D;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 5.5rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%231F252C' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%234FC04D' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 1rem center, center right 3rem;
  background-size: 16px 12px, calc(0.75em + 0.475rem) calc(0.75em + 0.475rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #4FC04D;
  box-shadow: 0 0 0 0.25rem rgba(79, 192, 77, 0.1);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #4FC04D;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #4FC04D;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(79, 192, 77, 0.1);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #4FC04D;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #F15B66;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(241, 91, 102, 0.9);
  border-radius: 0.375rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #F15B66;
  padding-right: calc(1.5em + 0.95rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23F15B66'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F15B66' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.2375rem) center;
  background-size: calc(0.75em + 0.475rem) calc(0.75em + 0.475rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #F15B66;
  box-shadow: 0 0 0 0.25rem rgba(241, 91, 102, 0.1);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.95rem);
  background-position: top calc(0.375em + 0.2375rem) right calc(0.375em + 0.2375rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #F15B66;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 5.5rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%231F252C' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23F15B66'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F15B66' stroke='none'/%3e%3c/svg%3e");
  background-position: right 1rem center, center right 3rem;
  background-size: 16px 12px, calc(0.75em + 0.475rem) calc(0.75em + 0.475rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #F15B66;
  box-shadow: 0 0 0 0.25rem rgba(241, 91, 102, 0.1);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #F15B66;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #F15B66;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(241, 91, 102, 0.1);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #F15B66;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn {
  display: inline-block;
  font-family: var(--bs-font-sans-serif);
  font-weight: 400;
  line-height: 1.5;
  color: #14191E;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.475rem 1rem;
  font-size: 1rem;
  border-radius: 0.375rem;
  transition: all 0.25s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #14191E;
}
.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(56, 151, 105, 0.1);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary {
  color: #fff;
  background-color: #389769;
  border-color: #389769;
}
.btn-primary:hover {
  color: #fff;
  background-color: #308059;
  border-color: #2d7954;
}
.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #308059;
  border-color: #2d7954;
  box-shadow: 0 0 0 0.25rem rgba(86, 167, 128, 0.5);
}
.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #2d7954;
  border-color: #2a714f;
}
.btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(86, 167, 128, 0.5);
}
.btn-primary:disabled, .btn-primary.disabled {
  color: #fff;
  background-color: #389769;
  border-color: #389769;
}

.btn-secondary {
  color: #fff;
  background-color: #2B323A;
  border-color: #2B323A;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #252b31;
  border-color: #22282e;
}
.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #fff;
  background-color: #252b31;
  border-color: #22282e;
  box-shadow: 0 0 0 0.25rem rgba(75, 81, 88, 0.5);
}
.btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #22282e;
  border-color: #20262c;
}
.btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(75, 81, 88, 0.5);
}
.btn-secondary:disabled, .btn-secondary.disabled {
  color: #fff;
  background-color: #2B323A;
  border-color: #2B323A;
}

.btn-success {
  color: #fff;
  background-color: #4FC04D;
  border-color: #4FC04D;
}
.btn-success:hover {
  color: #fff;
  background-color: #43a341;
  border-color: #3f9a3e;
}
.btn-check:focus + .btn-success, .btn-success:focus {
  color: #fff;
  background-color: #43a341;
  border-color: #3f9a3e;
  box-shadow: 0 0 0 0.25rem rgba(105, 201, 104, 0.5);
}
.btn-check:checked + .btn-success, .btn-check:active + .btn-success, .btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #3f9a3e;
  border-color: #3b903a;
}
.btn-check:checked + .btn-success:focus, .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(105, 201, 104, 0.5);
}
.btn-success:disabled, .btn-success.disabled {
  color: #fff;
  background-color: #4FC04D;
  border-color: #4FC04D;
}

.btn-info {
  color: #fff;
  background-color: #0072ff;
  border-color: #0072ff;
}
.btn-info:hover {
  color: #fff;
  background-color: #0061d9;
  border-color: #005bcc;
}
.btn-check:focus + .btn-info, .btn-info:focus {
  color: #fff;
  background-color: #0061d9;
  border-color: #005bcc;
  box-shadow: 0 0 0 0.25rem rgba(38, 135, 255, 0.5);
}
.btn-check:checked + .btn-info, .btn-check:active + .btn-info, .btn-info:active, .btn-info.active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #005bcc;
  border-color: #0056bf;
}
.btn-check:checked + .btn-info:focus, .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(38, 135, 255, 0.5);
}
.btn-info:disabled, .btn-info.disabled {
  color: #fff;
  background-color: #0072ff;
  border-color: #0072ff;
}

.btn-warning {
  color: #0A0D10;
  background-color: #FCC63B;
  border-color: #FCC63B;
}
.btn-warning:hover {
  color: #0A0D10;
  background-color: #fccf58;
  border-color: #fccc4f;
}
.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #0A0D10;
  background-color: #fccf58;
  border-color: #fccc4f;
  box-shadow: 0 0 0 0.25rem rgba(216, 170, 53, 0.5);
}
.btn-check:checked + .btn-warning, .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active, .show > .btn-warning.dropdown-toggle {
  color: #0A0D10;
  background-color: #fdd162;
  border-color: #fccc4f;
}
.btn-check:checked + .btn-warning:focus, .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(216, 170, 53, 0.5);
}
.btn-warning:disabled, .btn-warning.disabled {
  color: #0A0D10;
  background-color: #FCC63B;
  border-color: #FCC63B;
}

.btn-danger {
  color: #fff;
  background-color: #F15B66;
  border-color: #F15B66;
}
.btn-danger:hover {
  color: #fff;
  background-color: #cd4d57;
  border-color: #c14952;
}
.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #fff;
  background-color: #cd4d57;
  border-color: #c14952;
  box-shadow: 0 0 0 0.25rem rgba(243, 116, 125, 0.5);
}
.btn-check:checked + .btn-danger, .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #c14952;
  border-color: #b5444d;
}
.btn-check:checked + .btn-danger:focus, .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(243, 116, 125, 0.5);
}
.btn-danger:disabled, .btn-danger.disabled {
  color: #fff;
  background-color: #F15B66;
  border-color: #F15B66;
}

.btn-light {
  color: #0A0D10;
  background-color: #F8FBFE;
  border-color: #F8FBFE;
}
.btn-light:hover {
  color: #0A0D10;
  background-color: #f9fcfe;
  border-color: #f9fbfe;
}
.btn-check:focus + .btn-light, .btn-light:focus {
  color: #0A0D10;
  background-color: #f9fcfe;
  border-color: #f9fbfe;
  box-shadow: 0 0 0 0.25rem rgba(212, 215, 218, 0.5);
}
.btn-check:checked + .btn-light, .btn-check:active + .btn-light, .btn-light:active, .btn-light.active, .show > .btn-light.dropdown-toggle {
  color: #0A0D10;
  background-color: #f9fcfe;
  border-color: #f9fbfe;
}
.btn-check:checked + .btn-light:focus, .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(212, 215, 218, 0.5);
}
.btn-light:disabled, .btn-light.disabled {
  color: #0A0D10;
  background-color: #F8FBFE;
  border-color: #F8FBFE;
}

.btn-white {
  color: #0A0D10;
  background-color: #fff;
  border-color: #fff;
}
.btn-white:hover {
  color: #0A0D10;
  background-color: white;
  border-color: white;
}
.btn-check:focus + .btn-white, .btn-white:focus {
  color: #0A0D10;
  background-color: white;
  border-color: white;
  box-shadow: 0 0 0 0.25rem rgba(218, 219, 219, 0.5);
}
.btn-check:checked + .btn-white, .btn-check:active + .btn-white, .btn-white:active, .btn-white.active, .show > .btn-white.dropdown-toggle {
  color: #0A0D10;
  background-color: white;
  border-color: white;
}
.btn-check:checked + .btn-white:focus, .btn-check:active + .btn-white:focus, .btn-white:active:focus, .btn-white.active:focus, .show > .btn-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(218, 219, 219, 0.5);
}
.btn-white:disabled, .btn-white.disabled {
  color: #0A0D10;
  background-color: #fff;
  border-color: #fff;
}

.btn-gray-200 {
  color: #0A0D10;
  background-color: #F0F5FA;
  border-color: #F0F5FA;
}
.btn-gray-200:hover {
  color: #0A0D10;
  background-color: #f2f7fb;
  border-color: #f2f6fb;
}
.btn-check:focus + .btn-gray-200, .btn-gray-200:focus {
  color: #0A0D10;
  background-color: #f2f7fb;
  border-color: #f2f6fb;
  box-shadow: 0 0 0 0.25rem rgba(206, 210, 215, 0.5);
}
.btn-check:checked + .btn-gray-200, .btn-check:active + .btn-gray-200, .btn-gray-200:active, .btn-gray-200.active, .show > .btn-gray-200.dropdown-toggle {
  color: #0A0D10;
  background-color: #f3f7fb;
  border-color: #f2f6fb;
}
.btn-check:checked + .btn-gray-200:focus, .btn-check:active + .btn-gray-200:focus, .btn-gray-200:active:focus, .btn-gray-200.active:focus, .show > .btn-gray-200.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(206, 210, 215, 0.5);
}
.btn-gray-200:disabled, .btn-gray-200.disabled {
  color: #0A0D10;
  background-color: #F0F5FA;
  border-color: #F0F5FA;
}

.btn-gray-800 {
  color: #fff;
  background-color: #1F252C;
  border-color: #1F252C;
}
.btn-gray-800:hover {
  color: #fff;
  background-color: #1a1f25;
  border-color: #191e23;
}
.btn-check:focus + .btn-gray-800, .btn-gray-800:focus {
  color: #fff;
  background-color: #1a1f25;
  border-color: #191e23;
  box-shadow: 0 0 0 0.25rem rgba(65, 70, 76, 0.5);
}
.btn-check:checked + .btn-gray-800, .btn-check:active + .btn-gray-800, .btn-gray-800:active, .btn-gray-800.active, .show > .btn-gray-800.dropdown-toggle {
  color: #fff;
  background-color: #191e23;
  border-color: #171c21;
}
.btn-check:checked + .btn-gray-800:focus, .btn-check:active + .btn-gray-800:focus, .btn-gray-800:active:focus, .btn-gray-800.active:focus, .show > .btn-gray-800.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(65, 70, 76, 0.5);
}
.btn-gray-800:disabled, .btn-gray-800.disabled {
  color: #fff;
  background-color: #1F252C;
  border-color: #1F252C;
}

.btn-dark {
  color: #fff;
  background-color: #0A0D10;
  border-color: #0A0D10;
}
.btn-dark:hover {
  color: #fff;
  background-color: #090b0e;
  border-color: #080a0d;
}
.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #fff;
  background-color: #090b0e;
  border-color: #080a0d;
  box-shadow: 0 0 0 0.25rem rgba(47, 49, 52, 0.5);
}
.btn-check:checked + .btn-dark, .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #080a0d;
  border-color: #080a0c;
}
.btn-check:checked + .btn-dark:focus, .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(47, 49, 52, 0.5);
}
.btn-dark:disabled, .btn-dark.disabled {
  color: #fff;
  background-color: #0A0D10;
  border-color: #0A0D10;
}

.btn-outline-primary {
  color: #389769;
  border-color: #389769;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #389769;
  border-color: #389769;
}
.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(56, 151, 105, 0.5);
}
.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #389769;
  border-color: #389769;
}
.btn-check:checked + .btn-outline-primary:focus, .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(56, 151, 105, 0.5);
}
.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #389769;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #2B323A;
  border-color: #2B323A;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #2B323A;
  border-color: #2B323A;
}
.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(43, 50, 58, 0.5);
}
.btn-check:checked + .btn-outline-secondary, .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #fff;
  background-color: #2B323A;
  border-color: #2B323A;
}
.btn-check:checked + .btn-outline-secondary:focus, .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(43, 50, 58, 0.5);
}
.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #2B323A;
  background-color: transparent;
}

.btn-outline-success {
  color: #4FC04D;
  border-color: #4FC04D;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #4FC04D;
  border-color: #4FC04D;
}
.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(79, 192, 77, 0.5);
}
.btn-check:checked + .btn-outline-success, .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #fff;
  background-color: #4FC04D;
  border-color: #4FC04D;
}
.btn-check:checked + .btn-outline-success:focus, .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(79, 192, 77, 0.5);
}
.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #4FC04D;
  background-color: transparent;
}

.btn-outline-info {
  color: #0072ff;
  border-color: #0072ff;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #0072ff;
  border-color: #0072ff;
}
.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 114, 255, 0.5);
}
.btn-check:checked + .btn-outline-info, .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #fff;
  background-color: #0072ff;
  border-color: #0072ff;
}
.btn-check:checked + .btn-outline-info:focus, .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 114, 255, 0.5);
}
.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #0072ff;
  background-color: transparent;
}

.btn-outline-warning {
  color: #FCC63B;
  border-color: #FCC63B;
}
.btn-outline-warning:hover {
  color: #0A0D10;
  background-color: #FCC63B;
  border-color: #FCC63B;
}
.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(252, 198, 59, 0.5);
}
.btn-check:checked + .btn-outline-warning, .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #0A0D10;
  background-color: #FCC63B;
  border-color: #FCC63B;
}
.btn-check:checked + .btn-outline-warning:focus, .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(252, 198, 59, 0.5);
}
.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #FCC63B;
  background-color: transparent;
}

.btn-outline-danger {
  color: #F15B66;
  border-color: #F15B66;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #F15B66;
  border-color: #F15B66;
}
.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(241, 91, 102, 0.5);
}
.btn-check:checked + .btn-outline-danger, .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #fff;
  background-color: #F15B66;
  border-color: #F15B66;
}
.btn-check:checked + .btn-outline-danger:focus, .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(241, 91, 102, 0.5);
}
.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #F15B66;
  background-color: transparent;
}

.btn-outline-light {
  color: #F8FBFE;
  border-color: #F8FBFE;
}
.btn-outline-light:hover {
  color: #0A0D10;
  background-color: #F8FBFE;
  border-color: #F8FBFE;
}
.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 251, 254, 0.5);
}
.btn-check:checked + .btn-outline-light, .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #0A0D10;
  background-color: #F8FBFE;
  border-color: #F8FBFE;
}
.btn-check:checked + .btn-outline-light:focus, .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 251, 254, 0.5);
}
.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #F8FBFE;
  background-color: transparent;
}

.btn-outline-white {
  color: #fff;
  border-color: #fff;
}
.btn-outline-white:hover {
  color: #0A0D10;
  background-color: #fff;
  border-color: #fff;
}
.btn-check:focus + .btn-outline-white, .btn-outline-white:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-check:checked + .btn-outline-white, .btn-check:active + .btn-outline-white, .btn-outline-white:active, .btn-outline-white.active, .btn-outline-white.dropdown-toggle.show {
  color: #0A0D10;
  background-color: #fff;
  border-color: #fff;
}
.btn-check:checked + .btn-outline-white:focus, .btn-check:active + .btn-outline-white:focus, .btn-outline-white:active:focus, .btn-outline-white.active:focus, .btn-outline-white.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-outline-white:disabled, .btn-outline-white.disabled {
  color: #fff;
  background-color: transparent;
}

.btn-outline-gray-200 {
  color: #F0F5FA;
  border-color: #F0F5FA;
}
.btn-outline-gray-200:hover {
  color: #0A0D10;
  background-color: #F0F5FA;
  border-color: #F0F5FA;
}
.btn-check:focus + .btn-outline-gray-200, .btn-outline-gray-200:focus {
  box-shadow: 0 0 0 0.25rem rgba(240, 245, 250, 0.5);
}
.btn-check:checked + .btn-outline-gray-200, .btn-check:active + .btn-outline-gray-200, .btn-outline-gray-200:active, .btn-outline-gray-200.active, .btn-outline-gray-200.dropdown-toggle.show {
  color: #0A0D10;
  background-color: #F0F5FA;
  border-color: #F0F5FA;
}
.btn-check:checked + .btn-outline-gray-200:focus, .btn-check:active + .btn-outline-gray-200:focus, .btn-outline-gray-200:active:focus, .btn-outline-gray-200.active:focus, .btn-outline-gray-200.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(240, 245, 250, 0.5);
}
.btn-outline-gray-200:disabled, .btn-outline-gray-200.disabled {
  color: #F0F5FA;
  background-color: transparent;
}

.btn-outline-gray-800 {
  color: #1F252C;
  border-color: #1F252C;
}
.btn-outline-gray-800:hover {
  color: #fff;
  background-color: #1F252C;
  border-color: #1F252C;
}
.btn-check:focus + .btn-outline-gray-800, .btn-outline-gray-800:focus {
  box-shadow: 0 0 0 0.25rem rgba(31, 37, 44, 0.5);
}
.btn-check:checked + .btn-outline-gray-800, .btn-check:active + .btn-outline-gray-800, .btn-outline-gray-800:active, .btn-outline-gray-800.active, .btn-outline-gray-800.dropdown-toggle.show {
  color: #fff;
  background-color: #1F252C;
  border-color: #1F252C;
}
.btn-check:checked + .btn-outline-gray-800:focus, .btn-check:active + .btn-outline-gray-800:focus, .btn-outline-gray-800:active:focus, .btn-outline-gray-800.active:focus, .btn-outline-gray-800.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(31, 37, 44, 0.5);
}
.btn-outline-gray-800:disabled, .btn-outline-gray-800.disabled {
  color: #1F252C;
  background-color: transparent;
}

.btn-outline-dark {
  color: #0A0D10;
  border-color: #0A0D10;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #0A0D10;
  border-color: #0A0D10;
}
.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(10, 13, 16, 0.5);
}
.btn-check:checked + .btn-outline-dark, .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #0A0D10;
  border-color: #0A0D10;
}
.btn-check:checked + .btn-outline-dark:focus, .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(10, 13, 16, 0.5);
}
.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #0A0D10;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #389769;
  text-decoration: none;
}
.btn-link:hover {
  color: #2d7954;
}
.btn-link:disabled, .btn-link.disabled {
  color: #9EA7B0;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.75rem 1.75rem;
  font-size: 1.25rem;
  border-radius: 0.575rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.325rem 0.75rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 13rem;
  padding: 1rem 0.75rem;
  margin: 0;
  font-size: 1rem;
  color: #14191E;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0px solid transparent;
  border-radius: 0.575rem;
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0rem;
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0rem;
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0rem;
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #F0F5FA;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.4rem 1rem;
  clear: both;
  font-weight: 400;
  color: #39414A;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #333b43;
  background-color: #F8FBFE;
}
.dropdown-item.active, .dropdown-item:active {
  color: #389769;
  text-decoration: none;
  background-color: #F0F5FA;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #39414A;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 1rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #39414A;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.4rem 1rem;
  color: #39414A;
}

.dropdown-menu-dark {
  color: #E5EBF1;
  background-color: #1F252C;
  border-color: transparent;
}
.dropdown-menu-dark .dropdown-item {
  color: #E5EBF1;
}
.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #389769;
  background-color: #F0F5FA;
}
.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #9EA7B0;
}
.dropdown-menu-dark .dropdown-divider {
  border-color: #F0F5FA;
}
.dropdown-menu-dark .dropdown-item-text {
  color: #E5EBF1;
}
.dropdown-menu-dark .dropdown-header {
  color: #9EA7B0;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.3125rem;
  padding-left: 1.3125rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #389769;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: #2d7954;
}
.nav-link.disabled {
  color: #39414A;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #E5EBF1;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #F0F5FA #F0F5FA #E5EBF1;
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: #39414A;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #2B323A;
  background-color: #fff;
  border-color: #E5EBF1 #E5EBF1 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.375rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #389769;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  white-space: nowrap;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.375rem;
  transition: box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-sm .offcanvas-top,
.navbar-expand-sm .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-md .offcanvas-top,
.navbar-expand-md .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-lg .offcanvas-top,
.navbar-expand-lg .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xl .offcanvas-top,
.navbar-expand-xl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xxl .offcanvas-top,
.navbar-expand-xxl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas {
  position: inherit;
  bottom: 0;
  z-index: 1000;
  flex-grow: 1;
  visibility: visible !important;
  background-color: transparent;
  border-right: 0;
  border-left: 0;
  transition: none;
  transform: none;
}
.navbar-expand .offcanvas-top,
.navbar-expand .offcanvas-bottom {
  height: auto;
  border-top: 0;
  border-bottom: 0;
}
.navbar-expand .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-light .navbar-brand {
  color: rgba(10, 13, 16, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(10, 13, 16, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(10, 13, 16, 0.55);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(10, 13, 16, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(10, 13, 16, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(10, 13, 16, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(10, 13, 16, 0.55);
  border-color: rgba(10, 13, 16, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2810, 13, 16, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(10, 13, 16, 0.55);
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(10, 13, 16, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #F0F5FA;
  border-radius: 0.375rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.375rem - 1px);
  border-top-right-radius: calc(0.375rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.375rem - 1px);
  border-bottom-left-radius: calc(0.375rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(10, 13, 16, 0.03);
  border-bottom: 1px solid #F0F5FA;
}
.card-header:first-child {
  border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(10, 13, 16, 0.03);
  border-top: 1px solid #F0F5FA;
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.375rem - 1px) calc(0.375rem - 1px);
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.375rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.375rem - 1px);
  border-top-right-radius: calc(0.375rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.375rem - 1px);
  border-bottom-left-radius: calc(0.375rem - 1px);
}

.card-group > .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #14191E;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: all 0.25s ease-in-out, border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: #32885f;
  background-color: #ebf5f0;
  box-shadow: inset 0 -1px 0 rgba(10, 13, 16, 0.125);
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2332885f'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2314191E'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #9ccbb4;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(56, 151, 105, 0.1);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #fff;
  border: 1px solid rgba(10, 13, 16, 0.125);
}
.accordion-item:first-of-type {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.375rem - 1px);
  border-top-right-radius: calc(0.375rem - 1px);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.375rem - 1px);
  border-bottom-left-radius: calc(0.375rem - 1px);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  list-style: none;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #39414A;
  content: var(--bs-breadcrumb-divider, "") /* rtl: var(--bs-breadcrumb-divider, "") */;
}
.breadcrumb-item.active {
  color: #39414A;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #14191E;
  background-color: transparent;
  border: 0px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: #2d7954;
  background-color: #F8FBFE;
  border-color: #F0F5FA;
}
.page-link:focus {
  z-index: 3;
  color: #2d7954;
  background-color: #F8FBFE;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(56, 151, 105, 0.1);
}

.page-item:not(:first-child) .page-link {
  margin-left: 0px;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #389769;
  border-color: #389769;
}
.page-item.disabled .page-link {
  color: #9EA7B0;
  pointer-events: none;
  background-color: transparent;
  border-color: #F8FBFE;
}

.page-link {
  padding: 0.375rem 0.875rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.pagination-lg .page-link {
  padding: 0.5rem 1.25rem;
  font-size: 1.25rem;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.575rem;
  border-bottom-left-radius: 0.575rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.575rem;
  border-bottom-right-radius: 0.575rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.575rem;
  font-size: 0.875rem;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.badge {
  display: inline-block;
  padding: 0.275em 0.65em;
  font-size: 0.725em;
  font-weight: 500;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.375rem;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.375rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 600;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #225b3f;
  background-color: #d7eae1;
  border-color: #c3e0d2;
}
.alert-primary .alert-link {
  color: #1b4932;
}

.alert-secondary {
  color: #1a1e23;
  background-color: #d5d6d8;
  border-color: #bfc2c4;
}
.alert-secondary .alert-link {
  color: #15181c;
}

.alert-success {
  color: #2f732e;
  background-color: #dcf2db;
  border-color: #caecca;
}
.alert-success .alert-link {
  color: #265c25;
}

.alert-info {
  color: #004499;
  background-color: #cce3ff;
  border-color: #b3d5ff;
}
.alert-info .alert-link {
  color: #00367a;
}

.alert-warning {
  color: #977723;
  background-color: #fef4d8;
  border-color: #feeec4;
}
.alert-warning .alert-link {
  color: #795f1c;
}

.alert-danger {
  color: #91373d;
  background-color: #fcdee0;
  border-color: #fbced1;
}
.alert-danger .alert-link {
  color: #742c31;
}

.alert-light {
  color: #959798;
  background-color: #fefeff;
  border-color: #fdfeff;
}
.alert-light .alert-link {
  color: #77797a;
}

.alert-white {
  color: #999999;
  background-color: white;
  border-color: white;
}
.alert-white .alert-link {
  color: #7a7a7a;
}

.alert-gray-200 {
  color: #909396;
  background-color: #fcfdfe;
  border-color: #fbfcfe;
}
.alert-gray-200 .alert-link {
  color: #737678;
}

.alert-gray-800 {
  color: #13161a;
  background-color: #d2d3d5;
  border-color: #bcbec0;
}
.alert-gray-800 .alert-link {
  color: #0f1215;
}

.alert-dark {
  color: #06080a;
  background-color: #cecfcf;
  border-color: #b6b6b7;
}
.alert-dark .alert-link {
  color: #050608;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #F0F5FA;
  border-radius: 0.375rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #389769;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.375rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #2B323A;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #2B323A;
  text-decoration: none;
  background-color: #F8FBFE;
}
.list-group-item-action:active {
  color: #14191E;
  background-color: #F0F5FA;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #14191E;
  background-color: #fff;
  border: 1px solid rgba(10, 13, 16, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #39414A;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #389769;
  border-color: #389769;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.375rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.375rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.375rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.375rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.375rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.375rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.375rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.375rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.375rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.375rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.375rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.375rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #225b3f;
  background-color: #d7eae1;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #225b3f;
  background-color: #c2d3cb;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #225b3f;
  border-color: #225b3f;
}

.list-group-item-secondary {
  color: #1a1e23;
  background-color: #d5d6d8;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #1a1e23;
  background-color: #c0c1c2;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #1a1e23;
  border-color: #1a1e23;
}

.list-group-item-success {
  color: #2f732e;
  background-color: #dcf2db;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #2f732e;
  background-color: #c6dac5;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #2f732e;
  border-color: #2f732e;
}

.list-group-item-info {
  color: #004499;
  background-color: #cce3ff;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #004499;
  background-color: #b8cce6;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #004499;
  border-color: #004499;
}

.list-group-item-warning {
  color: #977723;
  background-color: #fef4d8;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #977723;
  background-color: #e5dcc2;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #977723;
  border-color: #977723;
}

.list-group-item-danger {
  color: #91373d;
  background-color: #fcdee0;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #91373d;
  background-color: #e3c8ca;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #91373d;
  border-color: #91373d;
}

.list-group-item-light {
  color: #959798;
  background-color: #fefeff;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #959798;
  background-color: #e5e5e6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #959798;
  border-color: #959798;
}

.list-group-item-white {
  color: #999999;
  background-color: white;
}
.list-group-item-white.list-group-item-action:hover, .list-group-item-white.list-group-item-action:focus {
  color: #999999;
  background-color: #e6e6e6;
}
.list-group-item-white.list-group-item-action.active {
  color: #fff;
  background-color: #999999;
  border-color: #999999;
}

.list-group-item-gray-200 {
  color: #909396;
  background-color: #fcfdfe;
}
.list-group-item-gray-200.list-group-item-action:hover, .list-group-item-gray-200.list-group-item-action:focus {
  color: #909396;
  background-color: #e3e4e5;
}
.list-group-item-gray-200.list-group-item-action.active {
  color: #fff;
  background-color: #909396;
  border-color: #909396;
}

.list-group-item-gray-800 {
  color: #13161a;
  background-color: #d2d3d5;
}
.list-group-item-gray-800.list-group-item-action:hover, .list-group-item-gray-800.list-group-item-action:focus {
  color: #13161a;
  background-color: #bdbec0;
}
.list-group-item-gray-800.list-group-item-action.active {
  color: #fff;
  background-color: #13161a;
  border-color: #13161a;
}

.list-group-item-dark {
  color: #06080a;
  background-color: #cecfcf;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #06080a;
  background-color: #b9baba;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #06080a;
  border-color: #06080a;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #0A0D10;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230A0D10'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #0A0D10;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(56, 151, 105, 0.1);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(10, 13, 16, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(10, 13, 16, 0.1);
  border-radius: 0.375rem;
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #39414A;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(10, 13, 16, 0.05);
  border-top-left-radius: calc(0.375rem - 1px);
  border-top-right-radius: calc(0.375rem - 1px);
}
.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(10, 13, 16, 0.2);
  border-radius: 0.575rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #0A0D10;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #F0F5FA;
  border-top-left-radius: calc(0.575rem - 1px);
  border-top-right-radius: calc(0.575rem - 1px);
}
.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #F0F5FA;
  border-bottom-right-radius: calc(0.575rem - 1px);
  border-bottom-left-radius: calc(0.575rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #0A0D10;
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #0A0D10;
}

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #0A0D10;
}

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #0A0D10;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #0A0D10;
  border-radius: 0.375rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(10, 13, 16, 0.2);
  border-radius: 0.575rem;
}
.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(10, 13, 16, 0.25);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(10, 13, 16, 0.25);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(10, 13, 16, 0.25);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(10, 13, 16, 0.25);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(10, 13, 16, 0.2);
  border-top-left-radius: calc(0.575rem - 1px);
  border-top-right-radius: calc(0.575rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #14191E;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #0A0D10;
}
.carousel-dark .carousel-caption {
  color: #0A0D10;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #0A0D10;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
}
.offcanvas-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin-top: -0.5rem;
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(10, 13, 16, 0.2);
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(10, 13, 16, 0.2);
  transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(10, 13, 16, 0.2);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(10, 13, 16, 0.2);
  transform: translateY(100%);
}

.offcanvas.show {
  transform: none;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentColor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #0A0D10 55%, rgba(0, 0, 0, 0.8) 75%, #0A0D10 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #389769;
}
.link-primary:hover, .link-primary:focus {
  color: #2d7954;
}

.link-secondary {
  color: #2B323A;
}
.link-secondary:hover, .link-secondary:focus {
  color: #22282e;
}

.link-success {
  color: #4FC04D;
}
.link-success:hover, .link-success:focus {
  color: #3f9a3e;
}

.link-info {
  color: #0072ff;
}
.link-info:hover, .link-info:focus {
  color: #005bcc;
}

.link-warning {
  color: #FCC63B;
}
.link-warning:hover, .link-warning:focus {
  color: #fdd162;
}

.link-danger {
  color: #F15B66;
}
.link-danger:hover, .link-danger:focus {
  color: #c14952;
}

.link-light {
  color: #F8FBFE;
}
.link-light:hover, .link-light:focus {
  color: #f9fcfe;
}

.link-white {
  color: #fff;
}
.link-white:hover, .link-white:focus {
  color: white;
}

.link-gray-200 {
  color: #F0F5FA;
}
.link-gray-200:hover, .link-gray-200:focus {
  color: #f3f7fb;
}

.link-gray-800 {
  color: #1F252C;
}
.link-gray-800:hover, .link-gray-800:focus {
  color: #191e23;
}

.link-dark {
  color: #0A0D10;
}
.link-dark:hover, .link-dark:focus {
  color: #080a0d;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.1;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(10, 13, 16, 0.1) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(10, 13, 16, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(10, 13, 16, 0.125) !important;
}

.shadow-xl {
  box-shadow: 0 2rem 4rem rgba(10, 13, 16, 0.175) !important;
}

.shadow-3d {
  box-shadow: 5px 5px 0px 0px #0a0d10 !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #F0F5FA !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #F0F5FA !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #F0F5FA !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #F0F5FA !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #F0F5FA !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #389769 !important;
}

.border-secondary {
  border-color: #2B323A !important;
}

.border-success {
  border-color: #4FC04D !important;
}

.border-info {
  border-color: #0072ff !important;
}

.border-warning {
  border-color: #FCC63B !important;
}

.border-danger {
  border-color: #F15B66 !important;
}

.border-light {
  border-color: #F8FBFE !important;
}

.border-white {
  border-color: #fff !important;
}

.border-gray-200 {
  border-color: #F0F5FA !important;
}

.border-gray-800 {
  border-color: #1F252C !important;
}

.border-dark {
  border-color: #0A0D10 !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-0 {
  width: 0% !important;
}

.w-10 {
  width: 10% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-70 {
  width: 70% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-0 {
  height: 0% !important;
}

.h-10 {
  height: 10% !important;
}

.h-20 {
  height: 20% !important;
}

.h-25 {
  height: 25% !important;
}

.h-30 {
  height: 30% !important;
}

.h-40 {
  height: 40% !important;
}

.h-50 {
  height: 50% !important;
}

.h-60 {
  height: 60% !important;
}

.h-70 {
  height: 70% !important;
}

.h-75 {
  height: 75% !important;
}

.h-80 {
  height: 80% !important;
}

.h-90 {
  height: 90% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-0 {
  height: 0 !important;
}

.vh-25 {
  height: 25vh !important;
}

.vh-50 {
  height: 50vh !important;
}

.vh-75 {
  height: 75vh !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-0 {
  min-height: 0 !important;
}

.min-vh-25 {
  min-height: 25vh !important;
}

.min-vh-50 {
  min-height: 50vh !important;
}

.min-vh-75 {
  min-height: 75vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 2rem !important;
}

.gap-6 {
  gap: 2.5rem !important;
}

.gap-7 {
  gap: 3rem !important;
}

.gap-8 {
  gap: 3.5rem !important;
}

.gap-9 {
  gap: 4rem !important;
}

.gap-10 {
  gap: 4.5rem !important;
}

.gap-11 {
  gap: 5rem !important;
}

.gap-12 {
  gap: 5.5rem !important;
}

.gap-13 {
  gap: 6rem !important;
}

.gap-14 {
  gap: 6.5rem !important;
}

.gap-15 {
  gap: 7rem !important;
}

.gap-16 {
  gap: 8rem !important;
}

.gap-17 {
  gap: 9rem !important;
}

.gap-18 {
  gap: 10rem !important;
}

.gap-19 {
  gap: 11rem !important;
}

.gap-20 {
  gap: 12rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 2rem !important;
}

.m-6 {
  margin: 2.5rem !important;
}

.m-7 {
  margin: 3rem !important;
}

.m-8 {
  margin: 3.5rem !important;
}

.m-9 {
  margin: 4rem !important;
}

.m-10 {
  margin: 4.5rem !important;
}

.m-11 {
  margin: 5rem !important;
}

.m-12 {
  margin: 5.5rem !important;
}

.m-13 {
  margin: 6rem !important;
}

.m-14 {
  margin: 6.5rem !important;
}

.m-15 {
  margin: 7rem !important;
}

.m-16 {
  margin: 8rem !important;
}

.m-17 {
  margin: 9rem !important;
}

.m-18 {
  margin: 10rem !important;
}

.m-19 {
  margin: 11rem !important;
}

.m-20 {
  margin: 12rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.mx-6 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important;
}

.mx-7 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-8 {
  margin-right: 3.5rem !important;
  margin-left: 3.5rem !important;
}

.mx-9 {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-10 {
  margin-right: 4.5rem !important;
  margin-left: 4.5rem !important;
}

.mx-11 {
  margin-right: 5rem !important;
  margin-left: 5rem !important;
}

.mx-12 {
  margin-right: 5.5rem !important;
  margin-left: 5.5rem !important;
}

.mx-13 {
  margin-right: 6rem !important;
  margin-left: 6rem !important;
}

.mx-14 {
  margin-right: 6.5rem !important;
  margin-left: 6.5rem !important;
}

.mx-15 {
  margin-right: 7rem !important;
  margin-left: 7rem !important;
}

.mx-16 {
  margin-right: 8rem !important;
  margin-left: 8rem !important;
}

.mx-17 {
  margin-right: 9rem !important;
  margin-left: 9rem !important;
}

.mx-18 {
  margin-right: 10rem !important;
  margin-left: 10rem !important;
}

.mx-19 {
  margin-right: 11rem !important;
  margin-left: 11rem !important;
}

.mx-20 {
  margin-right: 12rem !important;
  margin-left: 12rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-6 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-7 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-8 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.my-9 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-10 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}

.my-11 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.my-12 {
  margin-top: 5.5rem !important;
  margin-bottom: 5.5rem !important;
}

.my-13 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.my-14 {
  margin-top: 6.5rem !important;
  margin-bottom: 6.5rem !important;
}

.my-15 {
  margin-top: 7rem !important;
  margin-bottom: 7rem !important;
}

.my-16 {
  margin-top: 8rem !important;
  margin-bottom: 8rem !important;
}

.my-17 {
  margin-top: 9rem !important;
  margin-bottom: 9rem !important;
}

.my-18 {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important;
}

.my-19 {
  margin-top: 11rem !important;
  margin-bottom: 11rem !important;
}

.my-20 {
  margin-top: 12rem !important;
  margin-bottom: 12rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 2rem !important;
}

.mt-6 {
  margin-top: 2.5rem !important;
}

.mt-7 {
  margin-top: 3rem !important;
}

.mt-8 {
  margin-top: 3.5rem !important;
}

.mt-9 {
  margin-top: 4rem !important;
}

.mt-10 {
  margin-top: 4.5rem !important;
}

.mt-11 {
  margin-top: 5rem !important;
}

.mt-12 {
  margin-top: 5.5rem !important;
}

.mt-13 {
  margin-top: 6rem !important;
}

.mt-14 {
  margin-top: 6.5rem !important;
}

.mt-15 {
  margin-top: 7rem !important;
}

.mt-16 {
  margin-top: 8rem !important;
}

.mt-17 {
  margin-top: 9rem !important;
}

.mt-18 {
  margin-top: 10rem !important;
}

.mt-19 {
  margin-top: 11rem !important;
}

.mt-20 {
  margin-top: 12rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 2rem !important;
}

.me-6 {
  margin-right: 2.5rem !important;
}

.me-7 {
  margin-right: 3rem !important;
}

.me-8 {
  margin-right: 3.5rem !important;
}

.me-9 {
  margin-right: 4rem !important;
}

.me-10 {
  margin-right: 4.5rem !important;
}

.me-11 {
  margin-right: 5rem !important;
}

.me-12 {
  margin-right: 5.5rem !important;
}

.me-13 {
  margin-right: 6rem !important;
}

.me-14 {
  margin-right: 6.5rem !important;
}

.me-15 {
  margin-right: 7rem !important;
}

.me-16 {
  margin-right: 8rem !important;
}

.me-17 {
  margin-right: 9rem !important;
}

.me-18 {
  margin-right: 10rem !important;
}

.me-19 {
  margin-right: 11rem !important;
}

.me-20 {
  margin-right: 12rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 2rem !important;
}

.mb-6 {
  margin-bottom: 2.5rem !important;
}

.mb-7 {
  margin-bottom: 3rem !important;
}

.mb-8 {
  margin-bottom: 3.5rem !important;
}

.mb-9 {
  margin-bottom: 4rem !important;
}

.mb-10 {
  margin-bottom: 4.5rem !important;
}

.mb-11 {
  margin-bottom: 5rem !important;
}

.mb-12 {
  margin-bottom: 5.5rem !important;
}

.mb-13 {
  margin-bottom: 6rem !important;
}

.mb-14 {
  margin-bottom: 6.5rem !important;
}

.mb-15 {
  margin-bottom: 7rem !important;
}

.mb-16 {
  margin-bottom: 8rem !important;
}

.mb-17 {
  margin-bottom: 9rem !important;
}

.mb-18 {
  margin-bottom: 10rem !important;
}

.mb-19 {
  margin-bottom: 11rem !important;
}

.mb-20 {
  margin-bottom: 12rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 2rem !important;
}

.ms-6 {
  margin-left: 2.5rem !important;
}

.ms-7 {
  margin-left: 3rem !important;
}

.ms-8 {
  margin-left: 3.5rem !important;
}

.ms-9 {
  margin-left: 4rem !important;
}

.ms-10 {
  margin-left: 4.5rem !important;
}

.ms-11 {
  margin-left: 5rem !important;
}

.ms-12 {
  margin-left: 5.5rem !important;
}

.ms-13 {
  margin-left: 6rem !important;
}

.ms-14 {
  margin-left: 6.5rem !important;
}

.ms-15 {
  margin-left: 7rem !important;
}

.ms-16 {
  margin-left: 8rem !important;
}

.ms-17 {
  margin-left: 9rem !important;
}

.ms-18 {
  margin-left: 10rem !important;
}

.ms-19 {
  margin-left: 11rem !important;
}

.ms-20 {
  margin-left: 12rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -2rem !important;
}

.m-n6 {
  margin: -2.5rem !important;
}

.m-n7 {
  margin: -3rem !important;
}

.m-n8 {
  margin: -3.5rem !important;
}

.m-n9 {
  margin: -4rem !important;
}

.m-n10 {
  margin: -4.5rem !important;
}

.m-n11 {
  margin: -5rem !important;
}

.m-n12 {
  margin: -5.5rem !important;
}

.m-n13 {
  margin: -6rem !important;
}

.m-n14 {
  margin: -6.5rem !important;
}

.m-n15 {
  margin: -7rem !important;
}

.m-n16 {
  margin: -8rem !important;
}

.m-n17 {
  margin: -9rem !important;
}

.m-n18 {
  margin: -10rem !important;
}

.m-n19 {
  margin: -11rem !important;
}

.m-n20 {
  margin: -12rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n5 {
  margin-right: -2rem !important;
  margin-left: -2rem !important;
}

.mx-n6 {
  margin-right: -2.5rem !important;
  margin-left: -2.5rem !important;
}

.mx-n7 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.mx-n8 {
  margin-right: -3.5rem !important;
  margin-left: -3.5rem !important;
}

.mx-n9 {
  margin-right: -4rem !important;
  margin-left: -4rem !important;
}

.mx-n10 {
  margin-right: -4.5rem !important;
  margin-left: -4.5rem !important;
}

.mx-n11 {
  margin-right: -5rem !important;
  margin-left: -5rem !important;
}

.mx-n12 {
  margin-right: -5.5rem !important;
  margin-left: -5.5rem !important;
}

.mx-n13 {
  margin-right: -6rem !important;
  margin-left: -6rem !important;
}

.mx-n14 {
  margin-right: -6.5rem !important;
  margin-left: -6.5rem !important;
}

.mx-n15 {
  margin-right: -7rem !important;
  margin-left: -7rem !important;
}

.mx-n16 {
  margin-right: -8rem !important;
  margin-left: -8rem !important;
}

.mx-n17 {
  margin-right: -9rem !important;
  margin-left: -9rem !important;
}

.mx-n18 {
  margin-right: -10rem !important;
  margin-left: -10rem !important;
}

.mx-n19 {
  margin-right: -11rem !important;
  margin-left: -11rem !important;
}

.mx-n20 {
  margin-right: -12rem !important;
  margin-left: -12rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.my-n6 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.my-n7 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.my-n8 {
  margin-top: -3.5rem !important;
  margin-bottom: -3.5rem !important;
}

.my-n9 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.my-n10 {
  margin-top: -4.5rem !important;
  margin-bottom: -4.5rem !important;
}

.my-n11 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}

.my-n12 {
  margin-top: -5.5rem !important;
  margin-bottom: -5.5rem !important;
}

.my-n13 {
  margin-top: -6rem !important;
  margin-bottom: -6rem !important;
}

.my-n14 {
  margin-top: -6.5rem !important;
  margin-bottom: -6.5rem !important;
}

.my-n15 {
  margin-top: -7rem !important;
  margin-bottom: -7rem !important;
}

.my-n16 {
  margin-top: -8rem !important;
  margin-bottom: -8rem !important;
}

.my-n17 {
  margin-top: -9rem !important;
  margin-bottom: -9rem !important;
}

.my-n18 {
  margin-top: -10rem !important;
  margin-bottom: -10rem !important;
}

.my-n19 {
  margin-top: -11rem !important;
  margin-bottom: -11rem !important;
}

.my-n20 {
  margin-top: -12rem !important;
  margin-bottom: -12rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -2rem !important;
}

.mt-n6 {
  margin-top: -2.5rem !important;
}

.mt-n7 {
  margin-top: -3rem !important;
}

.mt-n8 {
  margin-top: -3.5rem !important;
}

.mt-n9 {
  margin-top: -4rem !important;
}

.mt-n10 {
  margin-top: -4.5rem !important;
}

.mt-n11 {
  margin-top: -5rem !important;
}

.mt-n12 {
  margin-top: -5.5rem !important;
}

.mt-n13 {
  margin-top: -6rem !important;
}

.mt-n14 {
  margin-top: -6.5rem !important;
}

.mt-n15 {
  margin-top: -7rem !important;
}

.mt-n16 {
  margin-top: -8rem !important;
}

.mt-n17 {
  margin-top: -9rem !important;
}

.mt-n18 {
  margin-top: -10rem !important;
}

.mt-n19 {
  margin-top: -11rem !important;
}

.mt-n20 {
  margin-top: -12rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -2rem !important;
}

.me-n6 {
  margin-right: -2.5rem !important;
}

.me-n7 {
  margin-right: -3rem !important;
}

.me-n8 {
  margin-right: -3.5rem !important;
}

.me-n9 {
  margin-right: -4rem !important;
}

.me-n10 {
  margin-right: -4.5rem !important;
}

.me-n11 {
  margin-right: -5rem !important;
}

.me-n12 {
  margin-right: -5.5rem !important;
}

.me-n13 {
  margin-right: -6rem !important;
}

.me-n14 {
  margin-right: -6.5rem !important;
}

.me-n15 {
  margin-right: -7rem !important;
}

.me-n16 {
  margin-right: -8rem !important;
}

.me-n17 {
  margin-right: -9rem !important;
}

.me-n18 {
  margin-right: -10rem !important;
}

.me-n19 {
  margin-right: -11rem !important;
}

.me-n20 {
  margin-right: -12rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -2rem !important;
}

.mb-n6 {
  margin-bottom: -2.5rem !important;
}

.mb-n7 {
  margin-bottom: -3rem !important;
}

.mb-n8 {
  margin-bottom: -3.5rem !important;
}

.mb-n9 {
  margin-bottom: -4rem !important;
}

.mb-n10 {
  margin-bottom: -4.5rem !important;
}

.mb-n11 {
  margin-bottom: -5rem !important;
}

.mb-n12 {
  margin-bottom: -5.5rem !important;
}

.mb-n13 {
  margin-bottom: -6rem !important;
}

.mb-n14 {
  margin-bottom: -6.5rem !important;
}

.mb-n15 {
  margin-bottom: -7rem !important;
}

.mb-n16 {
  margin-bottom: -8rem !important;
}

.mb-n17 {
  margin-bottom: -9rem !important;
}

.mb-n18 {
  margin-bottom: -10rem !important;
}

.mb-n19 {
  margin-bottom: -11rem !important;
}

.mb-n20 {
  margin-bottom: -12rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -2rem !important;
}

.ms-n6 {
  margin-left: -2.5rem !important;
}

.ms-n7 {
  margin-left: -3rem !important;
}

.ms-n8 {
  margin-left: -3.5rem !important;
}

.ms-n9 {
  margin-left: -4rem !important;
}

.ms-n10 {
  margin-left: -4.5rem !important;
}

.ms-n11 {
  margin-left: -5rem !important;
}

.ms-n12 {
  margin-left: -5.5rem !important;
}

.ms-n13 {
  margin-left: -6rem !important;
}

.ms-n14 {
  margin-left: -6.5rem !important;
}

.ms-n15 {
  margin-left: -7rem !important;
}

.ms-n16 {
  margin-left: -8rem !important;
}

.ms-n17 {
  margin-left: -9rem !important;
}

.ms-n18 {
  margin-left: -10rem !important;
}

.ms-n19 {
  margin-left: -11rem !important;
}

.ms-n20 {
  margin-left: -12rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 2rem !important;
}

.p-6 {
  padding: 2.5rem !important;
}

.p-7 {
  padding: 3rem !important;
}

.p-8 {
  padding: 3.5rem !important;
}

.p-9 {
  padding: 4rem !important;
}

.p-10 {
  padding: 4.5rem !important;
}

.p-11 {
  padding: 5rem !important;
}

.p-12 {
  padding: 5.5rem !important;
}

.p-13 {
  padding: 6rem !important;
}

.p-14 {
  padding: 6.5rem !important;
}

.p-15 {
  padding: 7rem !important;
}

.p-16 {
  padding: 8rem !important;
}

.p-17 {
  padding: 9rem !important;
}

.p-18 {
  padding: 10rem !important;
}

.p-19 {
  padding: 11rem !important;
}

.p-20 {
  padding: 12rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.px-6 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}

.px-7 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-8 {
  padding-right: 3.5rem !important;
  padding-left: 3.5rem !important;
}

.px-9 {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.px-10 {
  padding-right: 4.5rem !important;
  padding-left: 4.5rem !important;
}

.px-11 {
  padding-right: 5rem !important;
  padding-left: 5rem !important;
}

.px-12 {
  padding-right: 5.5rem !important;
  padding-left: 5.5rem !important;
}

.px-13 {
  padding-right: 6rem !important;
  padding-left: 6rem !important;
}

.px-14 {
  padding-right: 6.5rem !important;
  padding-left: 6.5rem !important;
}

.px-15 {
  padding-right: 7rem !important;
  padding-left: 7rem !important;
}

.px-16 {
  padding-right: 8rem !important;
  padding-left: 8rem !important;
}

.px-17 {
  padding-right: 9rem !important;
  padding-left: 9rem !important;
}

.px-18 {
  padding-right: 10rem !important;
  padding-left: 10rem !important;
}

.px-19 {
  padding-right: 11rem !important;
  padding-left: 11rem !important;
}

.px-20 {
  padding-right: 12rem !important;
  padding-left: 12rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-6 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-7 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-8 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.py-9 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-10 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

.py-11 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.py-12 {
  padding-top: 5.5rem !important;
  padding-bottom: 5.5rem !important;
}

.py-13 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.py-14 {
  padding-top: 6.5rem !important;
  padding-bottom: 6.5rem !important;
}

.py-15 {
  padding-top: 7rem !important;
  padding-bottom: 7rem !important;
}

.py-16 {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important;
}

.py-17 {
  padding-top: 9rem !important;
  padding-bottom: 9rem !important;
}

.py-18 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.py-19 {
  padding-top: 11rem !important;
  padding-bottom: 11rem !important;
}

.py-20 {
  padding-top: 12rem !important;
  padding-bottom: 12rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 2rem !important;
}

.pt-6 {
  padding-top: 2.5rem !important;
}

.pt-7 {
  padding-top: 3rem !important;
}

.pt-8 {
  padding-top: 3.5rem !important;
}

.pt-9 {
  padding-top: 4rem !important;
}

.pt-10 {
  padding-top: 4.5rem !important;
}

.pt-11 {
  padding-top: 5rem !important;
}

.pt-12 {
  padding-top: 5.5rem !important;
}

.pt-13 {
  padding-top: 6rem !important;
}

.pt-14 {
  padding-top: 6.5rem !important;
}

.pt-15 {
  padding-top: 7rem !important;
}

.pt-16 {
  padding-top: 8rem !important;
}

.pt-17 {
  padding-top: 9rem !important;
}

.pt-18 {
  padding-top: 10rem !important;
}

.pt-19 {
  padding-top: 11rem !important;
}

.pt-20 {
  padding-top: 12rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 2rem !important;
}

.pe-6 {
  padding-right: 2.5rem !important;
}

.pe-7 {
  padding-right: 3rem !important;
}

.pe-8 {
  padding-right: 3.5rem !important;
}

.pe-9 {
  padding-right: 4rem !important;
}

.pe-10 {
  padding-right: 4.5rem !important;
}

.pe-11 {
  padding-right: 5rem !important;
}

.pe-12 {
  padding-right: 5.5rem !important;
}

.pe-13 {
  padding-right: 6rem !important;
}

.pe-14 {
  padding-right: 6.5rem !important;
}

.pe-15 {
  padding-right: 7rem !important;
}

.pe-16 {
  padding-right: 8rem !important;
}

.pe-17 {
  padding-right: 9rem !important;
}

.pe-18 {
  padding-right: 10rem !important;
}

.pe-19 {
  padding-right: 11rem !important;
}

.pe-20 {
  padding-right: 12rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 2rem !important;
}

.pb-6 {
  padding-bottom: 2.5rem !important;
}

.pb-7 {
  padding-bottom: 3rem !important;
}

.pb-8 {
  padding-bottom: 3.5rem !important;
}

.pb-9 {
  padding-bottom: 4rem !important;
}

.pb-10 {
  padding-bottom: 4.5rem !important;
}

.pb-11 {
  padding-bottom: 5rem !important;
}

.pb-12 {
  padding-bottom: 5.5rem !important;
}

.pb-13 {
  padding-bottom: 6rem !important;
}

.pb-14 {
  padding-bottom: 6.5rem !important;
}

.pb-15 {
  padding-bottom: 7rem !important;
}

.pb-16 {
  padding-bottom: 8rem !important;
}

.pb-17 {
  padding-bottom: 9rem !important;
}

.pb-18 {
  padding-bottom: 10rem !important;
}

.pb-19 {
  padding-bottom: 11rem !important;
}

.pb-20 {
  padding-bottom: 12rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 2rem !important;
}

.ps-6 {
  padding-left: 2.5rem !important;
}

.ps-7 {
  padding-left: 3rem !important;
}

.ps-8 {
  padding-left: 3.5rem !important;
}

.ps-9 {
  padding-left: 4rem !important;
}

.ps-10 {
  padding-left: 4.5rem !important;
}

.ps-11 {
  padding-left: 5rem !important;
}

.ps-12 {
  padding-left: 5.5rem !important;
}

.ps-13 {
  padding-left: 6rem !important;
}

.ps-14 {
  padding-left: 6.5rem !important;
}

.ps-15 {
  padding-left: 7rem !important;
}

.ps-16 {
  padding-left: 8rem !important;
}

.ps-17 {
  padding-left: 9rem !important;
}

.ps-18 {
  padding-left: 10rem !important;
}

.ps-19 {
  padding-left: 11rem !important;
}

.ps-20 {
  padding-left: 12rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: 200 !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-semibold {
  font-weight: 500 !important;
}

.fw-bold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: 700 !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-200 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-200-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-800 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-800-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #39414A !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(10, 13, 16, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-200-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-800-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded {
  border-radius: 0.375rem !important;
}

.rounded-1 {
  border-radius: 0.25rem !important;
}

.rounded-2 {
  border-radius: 0.375rem !important;
}

.rounded-3 {
  border-radius: 0.575rem !important;
}

.rounded-4 {
  border-radius: 1.5em !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-block {
  border-radius: 3rem !important;
}

.rounded-blob {
  border-radius: 30% 70% 70% 30%/30% 30% 70% 70% !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top {
  border-top-left-radius: 0.375rem !important;
  border-top-right-radius: 0.375rem !important;
}

.rounded-top-1 {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-top-2 {
  border-top-left-radius: 0.375rem !important;
  border-top-right-radius: 0.375rem !important;
}

.rounded-top-3 {
  border-top-left-radius: 0.575rem !important;
  border-top-right-radius: 0.575rem !important;
}

.rounded-top-4 {
  border-top-left-radius: 1.5em !important;
  border-top-right-radius: 1.5em !important;
}

.rounded-top-pill {
  border-top-left-radius: 50rem !important;
  border-top-right-radius: 50rem !important;
}

.rounded-top-block {
  border-top-left-radius: 3rem !important;
  border-top-right-radius: 3rem !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}

.rounded-end-1 {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-end-2 {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}

.rounded-end-3 {
  border-top-right-radius: 0.575rem !important;
  border-bottom-right-radius: 0.575rem !important;
}

.rounded-end-4 {
  border-top-right-radius: 1.5em !important;
  border-bottom-right-radius: 1.5em !important;
}

.rounded-end-pill {
  border-top-right-radius: 50rem !important;
  border-bottom-right-radius: 50rem !important;
}

.rounded-end-block {
  border-top-right-radius: 3rem !important;
  border-bottom-right-radius: 3rem !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-bottom-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-bottom {
  border-bottom-left-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}

.rounded-bottom-1 {
  border-bottom-left-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom-2 {
  border-bottom-left-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}

.rounded-bottom-3 {
  border-bottom-left-radius: 0.575rem !important;
  border-bottom-right-radius: 0.575rem !important;
}

.rounded-bottom-4 {
  border-bottom-left-radius: 1.5em !important;
  border-bottom-right-radius: 1.5em !important;
}

.rounded-bottom-pill {
  border-bottom-left-radius: 50rem !important;
  border-bottom-right-radius: 50rem !important;
}

.rounded-bottom-block {
  border-bottom-left-radius: 3rem !important;
  border-bottom-right-radius: 3rem !important;
}

.rounded-bottom-circle {
  border-bottom-left-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-start-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-start {
  border-top-left-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}

.rounded-start-1 {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-start-2 {
  border-top-left-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}

.rounded-start-3 {
  border-top-left-radius: 0.575rem !important;
  border-bottom-left-radius: 0.575rem !important;
}

.rounded-start-4 {
  border-top-left-radius: 1.5em !important;
  border-bottom-left-radius: 1.5em !important;
}

.rounded-start-pill {
  border-top-left-radius: 50rem !important;
  border-bottom-left-radius: 50rem !important;
}

.rounded-start-block {
  border-top-left-radius: 3rem !important;
  border-bottom-left-radius: 3rem !important;
}

.rounded-start-circle {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.rounded-top-start-0 {
  border-top-left-radius: 0 !important;
}

.rounded-top-start {
  border-top-left-radius: 0.375rem !important;
}

.rounded-top-start-1 {
  border-top-left-radius: 0.25rem !important;
}

.rounded-top-start-2 {
  border-top-left-radius: 0.375rem !important;
}

.rounded-top-start-3 {
  border-top-left-radius: 0.575rem !important;
}

.rounded-top-start-4 {
  border-top-left-radius: 1.5em !important;
}

.rounded-top-start-pill {
  border-top-left-radius: 50rem !important;
}

.rounded-top-start-block {
  border-top-left-radius: 3rem !important;
}

.rounded-top-start-circle {
  border-top-left-radius: 50% !important;
}

.rounded-top-end-0 {
  border-top-right-radius: 0 !important;
}

.rounded-top-end {
  border-top-right-radius: 0.375rem !important;
}

.rounded-top-end-1 {
  border-top-right-radius: 0.25rem !important;
}

.rounded-top-end-2 {
  border-top-right-radius: 0.375rem !important;
}

.rounded-top-end-3 {
  border-top-right-radius: 0.575rem !important;
}

.rounded-top-end-4 {
  border-top-right-radius: 1.5em !important;
}

.rounded-top-end-pill {
  border-top-right-radius: 50rem !important;
}

.rounded-top-end-block {
  border-top-right-radius: 3rem !important;
}

.rounded-top-end-circle {
  border-top-right-radius: 50% !important;
}

.rounded-bottom-start-0 {
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-start {
  border-bottom-left-radius: 0.375rem !important;
}

.rounded-bottom-start-1 {
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-bottom-start-2 {
  border-bottom-left-radius: 0.375rem !important;
}

.rounded-bottom-start-3 {
  border-bottom-left-radius: 0.575rem !important;
}

.rounded-bottom-start-4 {
  border-bottom-left-radius: 1.5em !important;
}

.rounded-bottom-start-pill {
  border-bottom-left-radius: 50rem !important;
}

.rounded-bottom-start-block {
  border-bottom-left-radius: 3rem !important;
}

.rounded-bottom-start-circle {
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-end-0 {
  border-bottom-right-radius: 0 !important;
}

.rounded-bottom-end {
  border-bottom-right-radius: 0.375rem !important;
}

.rounded-bottom-end-1 {
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom-end-2 {
  border-bottom-right-radius: 0.375rem !important;
}

.rounded-bottom-end-3 {
  border-bottom-right-radius: 0.575rem !important;
}

.rounded-bottom-end-4 {
  border-bottom-right-radius: 1.5em !important;
}

.rounded-bottom-end-pill {
  border-bottom-right-radius: 50rem !important;
}

.rounded-bottom-end-block {
  border-bottom-right-radius: 3rem !important;
}

.rounded-bottom-end-circle {
  border-bottom-right-radius: 50% !important;
}

.rotate-1 {
  transform: rotate(1deg) !important;
}

.rotate-2 {
  transform: rotate(2deg) !important;
}

.rotate-3 {
  transform: rotate(3deg) !important;
}

.rotate-4 {
  transform: rotate(4deg) !important;
}

.rotate-5 {
  transform: rotate(5deg) !important;
}

.rotate-45 {
  transform: rotate(45deg) !important;
}

.rotate-n45 {
  transform: rotate(-45deg) !important;
}

.rotate-90 {
  transform: rotate(90deg) !important;
}

.rotate--90 {
  transform: rotate(-90deg) !important;
}

.rotate-180 {
  transform: rotate(180deg) !important;
}

.rotate--180 {
  transform: rotate(-180deg) !important;
}

.rotate-n1 {
  transform: rotate(-1deg) !important;
}

.rotate-n2 {
  transform: rotate(-2deg) !important;
}

.rotate-n3 {
  transform: rotate(-3deg) !important;
}

.rotate-n4 {
  transform: rotate(-4deg) !important;
}

.rotate-n5 {
  transform: rotate(-5deg) !important;
}

.rotate-none {
  transform: none !important;
}

.flip-y {
  transform: rotateZ(180deg) !important;
}

.flip-x {
  transform: rotateY(180deg) !important;
}

.z-index-n1 {
  z-index: -1 !important;
}

.z-index-n2 {
  z-index: -2 !important;
}

.z-index-0 {
  z-index: 0 !important;
}

.z-index-1 {
  z-index: 1 !important;
}

.z-index-2 {
  z-index: 2 !important;
}

.z-index-3 {
  z-index: 3 !important;
}

.z-index-4 {
  z-index: 4 !important;
}

.z-index-5 {
  z-index: 5 !important;
}

.z-index-fixed {
  z-index: 1030 !important;
}

.flex-center {
  align-items: center !important;
}

.flex-center {
  justify-content: center !important;
}

.bg-center {
  background-position: center center !important;
}

.bg-top-left {
  background-position: top left !important;
}

.bg-top-right {
  background-position: top right !important;
}

.bg-bottom-left {
  background-position: bottom left !important;
}

.bg-bottom-right {
  background-position: bottom right !important;
}

.bg-cover {
  background-size: cover !important;
}

.bg-contain {
  background-size: contain !important;
}

.bg-100 {
  background-size: 100% !important;
}

.bg-repeat {
  background-repeat: repeat !important;
}

.bg-no-repeat {
  background-repeat: no-repeat !important;
}

.bg-repeat-x {
  background-repeat: repeat-x !important;
}

.bg-repeat-y {
  background-repeat: repeat-y !important;
}

.width-1x {
  width: 1rem !important;
}

.width-2x {
  width: 1.5rem !important;
}

.width-3x {
  width: 2rem !important;
}

.width-4x {
  width: 2.5rem !important;
}

.width-5x {
  width: 3rem !important;
}

.width-6x {
  width: 3.5rem !important;
}

.width-7x {
  width: 4rem !important;
}

.width-8x {
  width: 5rem !important;
}

.width-9x {
  width: 5.5rem !important;
}

.width-10x {
  width: 6rem !important;
}

.width-11x {
  width: 7rem !important;
}

.width-12x {
  width: 8rem !important;
}

.width-13x {
  width: 9rem !important;
}

.width-14x {
  width: 9.5rem !important;
}

.width-15x {
  width: 10rem !important;
}

.width-16x {
  width: 11rem !important;
}

.width-17x {
  width: 12rem !important;
}

.width-18x {
  width: 13rem !important;
}

.width-19x {
  width: 14rem !important;
}

.width-20x {
  width: 15rem !important;
}

.width-auto {
  width: auto !important;
}

.height-1x {
  height: 1rem !important;
}

.height-2x {
  height: 1.5rem !important;
}

.height-3x {
  height: 2rem !important;
}

.height-4x {
  height: 2.5rem !important;
}

.height-5x {
  height: 3rem !important;
}

.height-6x {
  height: 3.5rem !important;
}

.height-7x {
  height: 4rem !important;
}

.height-8x {
  height: 5rem !important;
}

.height-9x {
  height: 5.5rem !important;
}

.height-10x {
  height: 6rem !important;
}

.height-11x {
  height: 7rem !important;
}

.height-12x {
  height: 8rem !important;
}

.height-13x {
  height: 9rem !important;
}

.height-14x {
  height: 9.5rem !important;
}

.height-15x {
  height: 10rem !important;
}

.height-16x {
  height: 11rem !important;
}

.height-17x {
  height: 12rem !important;
}

.height-18x {
  height: 13rem !important;
}

.height-19x {
  height: 14rem !important;
}

.height-20x {
  height: 15rem !important;
}

.height-auto {
  height: auto !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .position-sm-static {
    position: static !important;
  }

  .position-sm-relative {
    position: relative !important;
  }

  .position-sm-absolute {
    position: absolute !important;
  }

  .position-sm-fixed {
    position: fixed !important;
  }

  .position-sm-sticky {
    position: sticky !important;
  }

  .border-sm {
    border: 1px solid #F0F5FA !important;
  }

  .border-sm-0 {
    border: 0 !important;
  }

  .border-top-sm {
    border-top: 1px solid #F0F5FA !important;
  }

  .border-top-sm-0 {
    border-top: 0 !important;
  }

  .border-end-sm {
    border-right: 1px solid #F0F5FA !important;
  }

  .border-end-sm-0 {
    border-right: 0 !important;
  }

  .border-start-sm {
    border-left: 1px solid #F0F5FA !important;
  }

  .border-start-sm-0 {
    border-left: 0 !important;
  }

  .w-sm-0 {
    width: 0% !important;
  }

  .w-sm-10 {
    width: 10% !important;
  }

  .w-sm-20 {
    width: 20% !important;
  }

  .w-sm-25 {
    width: 25% !important;
  }

  .w-sm-30 {
    width: 30% !important;
  }

  .w-sm-40 {
    width: 40% !important;
  }

  .w-sm-50 {
    width: 50% !important;
  }

  .w-sm-60 {
    width: 60% !important;
  }

  .w-sm-70 {
    width: 70% !important;
  }

  .w-sm-75 {
    width: 75% !important;
  }

  .w-sm-80 {
    width: 80% !important;
  }

  .w-sm-90 {
    width: 90% !important;
  }

  .w-sm-100 {
    width: 100% !important;
  }

  .w-sm-auto {
    width: auto !important;
  }

  .h-sm-0 {
    height: 0% !important;
  }

  .h-sm-10 {
    height: 10% !important;
  }

  .h-sm-20 {
    height: 20% !important;
  }

  .h-sm-25 {
    height: 25% !important;
  }

  .h-sm-30 {
    height: 30% !important;
  }

  .h-sm-40 {
    height: 40% !important;
  }

  .h-sm-50 {
    height: 50% !important;
  }

  .h-sm-60 {
    height: 60% !important;
  }

  .h-sm-70 {
    height: 70% !important;
  }

  .h-sm-75 {
    height: 75% !important;
  }

  .h-sm-80 {
    height: 80% !important;
  }

  .h-sm-90 {
    height: 90% !important;
  }

  .h-sm-100 {
    height: 100% !important;
  }

  .h-sm-auto {
    height: auto !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 2rem !important;
  }

  .gap-sm-6 {
    gap: 2.5rem !important;
  }

  .gap-sm-7 {
    gap: 3rem !important;
  }

  .gap-sm-8 {
    gap: 3.5rem !important;
  }

  .gap-sm-9 {
    gap: 4rem !important;
  }

  .gap-sm-10 {
    gap: 4.5rem !important;
  }

  .gap-sm-11 {
    gap: 5rem !important;
  }

  .gap-sm-12 {
    gap: 5.5rem !important;
  }

  .gap-sm-13 {
    gap: 6rem !important;
  }

  .gap-sm-14 {
    gap: 6.5rem !important;
  }

  .gap-sm-15 {
    gap: 7rem !important;
  }

  .gap-sm-16 {
    gap: 8rem !important;
  }

  .gap-sm-17 {
    gap: 9rem !important;
  }

  .gap-sm-18 {
    gap: 10rem !important;
  }

  .gap-sm-19 {
    gap: 11rem !important;
  }

  .gap-sm-20 {
    gap: 12rem !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 2rem !important;
  }

  .m-sm-6 {
    margin: 2.5rem !important;
  }

  .m-sm-7 {
    margin: 3rem !important;
  }

  .m-sm-8 {
    margin: 3.5rem !important;
  }

  .m-sm-9 {
    margin: 4rem !important;
  }

  .m-sm-10 {
    margin: 4.5rem !important;
  }

  .m-sm-11 {
    margin: 5rem !important;
  }

  .m-sm-12 {
    margin: 5.5rem !important;
  }

  .m-sm-13 {
    margin: 6rem !important;
  }

  .m-sm-14 {
    margin: 6.5rem !important;
  }

  .m-sm-15 {
    margin: 7rem !important;
  }

  .m-sm-16 {
    margin: 8rem !important;
  }

  .m-sm-17 {
    margin: 9rem !important;
  }

  .m-sm-18 {
    margin: 10rem !important;
  }

  .m-sm-19 {
    margin: 11rem !important;
  }

  .m-sm-20 {
    margin: 12rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-sm-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-sm-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-sm-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-sm-10 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-sm-11 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-sm-12 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important;
  }

  .mx-sm-13 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-sm-14 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important;
  }

  .mx-sm-15 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }

  .mx-sm-16 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-sm-17 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }

  .mx-sm-18 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .mx-sm-19 {
    margin-right: 11rem !important;
    margin-left: 11rem !important;
  }

  .mx-sm-20 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-sm-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-sm-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-sm-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-sm-10 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-sm-11 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-sm-12 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }

  .my-sm-13 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-sm-14 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }

  .my-sm-15 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .my-sm-16 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-sm-17 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .my-sm-18 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-sm-19 {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important;
  }

  .my-sm-20 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 2rem !important;
  }

  .mt-sm-6 {
    margin-top: 2.5rem !important;
  }

  .mt-sm-7 {
    margin-top: 3rem !important;
  }

  .mt-sm-8 {
    margin-top: 3.5rem !important;
  }

  .mt-sm-9 {
    margin-top: 4rem !important;
  }

  .mt-sm-10 {
    margin-top: 4.5rem !important;
  }

  .mt-sm-11 {
    margin-top: 5rem !important;
  }

  .mt-sm-12 {
    margin-top: 5.5rem !important;
  }

  .mt-sm-13 {
    margin-top: 6rem !important;
  }

  .mt-sm-14 {
    margin-top: 6.5rem !important;
  }

  .mt-sm-15 {
    margin-top: 7rem !important;
  }

  .mt-sm-16 {
    margin-top: 8rem !important;
  }

  .mt-sm-17 {
    margin-top: 9rem !important;
  }

  .mt-sm-18 {
    margin-top: 10rem !important;
  }

  .mt-sm-19 {
    margin-top: 11rem !important;
  }

  .mt-sm-20 {
    margin-top: 12rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 2rem !important;
  }

  .me-sm-6 {
    margin-right: 2.5rem !important;
  }

  .me-sm-7 {
    margin-right: 3rem !important;
  }

  .me-sm-8 {
    margin-right: 3.5rem !important;
  }

  .me-sm-9 {
    margin-right: 4rem !important;
  }

  .me-sm-10 {
    margin-right: 4.5rem !important;
  }

  .me-sm-11 {
    margin-right: 5rem !important;
  }

  .me-sm-12 {
    margin-right: 5.5rem !important;
  }

  .me-sm-13 {
    margin-right: 6rem !important;
  }

  .me-sm-14 {
    margin-right: 6.5rem !important;
  }

  .me-sm-15 {
    margin-right: 7rem !important;
  }

  .me-sm-16 {
    margin-right: 8rem !important;
  }

  .me-sm-17 {
    margin-right: 9rem !important;
  }

  .me-sm-18 {
    margin-right: 10rem !important;
  }

  .me-sm-19 {
    margin-right: 11rem !important;
  }

  .me-sm-20 {
    margin-right: 12rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 2rem !important;
  }

  .mb-sm-6 {
    margin-bottom: 2.5rem !important;
  }

  .mb-sm-7 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-8 {
    margin-bottom: 3.5rem !important;
  }

  .mb-sm-9 {
    margin-bottom: 4rem !important;
  }

  .mb-sm-10 {
    margin-bottom: 4.5rem !important;
  }

  .mb-sm-11 {
    margin-bottom: 5rem !important;
  }

  .mb-sm-12 {
    margin-bottom: 5.5rem !important;
  }

  .mb-sm-13 {
    margin-bottom: 6rem !important;
  }

  .mb-sm-14 {
    margin-bottom: 6.5rem !important;
  }

  .mb-sm-15 {
    margin-bottom: 7rem !important;
  }

  .mb-sm-16 {
    margin-bottom: 8rem !important;
  }

  .mb-sm-17 {
    margin-bottom: 9rem !important;
  }

  .mb-sm-18 {
    margin-bottom: 10rem !important;
  }

  .mb-sm-19 {
    margin-bottom: 11rem !important;
  }

  .mb-sm-20 {
    margin-bottom: 12rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 2rem !important;
  }

  .ms-sm-6 {
    margin-left: 2.5rem !important;
  }

  .ms-sm-7 {
    margin-left: 3rem !important;
  }

  .ms-sm-8 {
    margin-left: 3.5rem !important;
  }

  .ms-sm-9 {
    margin-left: 4rem !important;
  }

  .ms-sm-10 {
    margin-left: 4.5rem !important;
  }

  .ms-sm-11 {
    margin-left: 5rem !important;
  }

  .ms-sm-12 {
    margin-left: 5.5rem !important;
  }

  .ms-sm-13 {
    margin-left: 6rem !important;
  }

  .ms-sm-14 {
    margin-left: 6.5rem !important;
  }

  .ms-sm-15 {
    margin-left: 7rem !important;
  }

  .ms-sm-16 {
    margin-left: 8rem !important;
  }

  .ms-sm-17 {
    margin-left: 9rem !important;
  }

  .ms-sm-18 {
    margin-left: 10rem !important;
  }

  .ms-sm-19 {
    margin-left: 11rem !important;
  }

  .ms-sm-20 {
    margin-left: 12rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -2rem !important;
  }

  .m-sm-n6 {
    margin: -2.5rem !important;
  }

  .m-sm-n7 {
    margin: -3rem !important;
  }

  .m-sm-n8 {
    margin: -3.5rem !important;
  }

  .m-sm-n9 {
    margin: -4rem !important;
  }

  .m-sm-n10 {
    margin: -4.5rem !important;
  }

  .m-sm-n11 {
    margin: -5rem !important;
  }

  .m-sm-n12 {
    margin: -5.5rem !important;
  }

  .m-sm-n13 {
    margin: -6rem !important;
  }

  .m-sm-n14 {
    margin: -6.5rem !important;
  }

  .m-sm-n15 {
    margin: -7rem !important;
  }

  .m-sm-n16 {
    margin: -8rem !important;
  }

  .m-sm-n17 {
    margin: -9rem !important;
  }

  .m-sm-n18 {
    margin: -10rem !important;
  }

  .m-sm-n19 {
    margin: -11rem !important;
  }

  .m-sm-n20 {
    margin: -12rem !important;
  }

  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-sm-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-sm-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-sm-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-sm-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-sm-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-sm-n10 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .mx-sm-n11 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .mx-sm-n12 {
    margin-right: -5.5rem !important;
    margin-left: -5.5rem !important;
  }

  .mx-sm-n13 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }

  .mx-sm-n14 {
    margin-right: -6.5rem !important;
    margin-left: -6.5rem !important;
  }

  .mx-sm-n15 {
    margin-right: -7rem !important;
    margin-left: -7rem !important;
  }

  .mx-sm-n16 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }

  .mx-sm-n17 {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }

  .mx-sm-n18 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }

  .mx-sm-n19 {
    margin-right: -11rem !important;
    margin-left: -11rem !important;
  }

  .mx-sm-n20 {
    margin-right: -12rem !important;
    margin-left: -12rem !important;
  }

  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-sm-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-sm-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-sm-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-sm-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-sm-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-sm-n10 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-sm-n11 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .my-sm-n12 {
    margin-top: -5.5rem !important;
    margin-bottom: -5.5rem !important;
  }

  .my-sm-n13 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-sm-n14 {
    margin-top: -6.5rem !important;
    margin-bottom: -6.5rem !important;
  }

  .my-sm-n15 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }

  .my-sm-n16 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-sm-n17 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .my-sm-n18 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .my-sm-n19 {
    margin-top: -11rem !important;
    margin-bottom: -11rem !important;
  }

  .my-sm-n20 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-sm-n3 {
    margin-top: -1rem !important;
  }

  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-sm-n5 {
    margin-top: -2rem !important;
  }

  .mt-sm-n6 {
    margin-top: -2.5rem !important;
  }

  .mt-sm-n7 {
    margin-top: -3rem !important;
  }

  .mt-sm-n8 {
    margin-top: -3.5rem !important;
  }

  .mt-sm-n9 {
    margin-top: -4rem !important;
  }

  .mt-sm-n10 {
    margin-top: -4.5rem !important;
  }

  .mt-sm-n11 {
    margin-top: -5rem !important;
  }

  .mt-sm-n12 {
    margin-top: -5.5rem !important;
  }

  .mt-sm-n13 {
    margin-top: -6rem !important;
  }

  .mt-sm-n14 {
    margin-top: -6.5rem !important;
  }

  .mt-sm-n15 {
    margin-top: -7rem !important;
  }

  .mt-sm-n16 {
    margin-top: -8rem !important;
  }

  .mt-sm-n17 {
    margin-top: -9rem !important;
  }

  .mt-sm-n18 {
    margin-top: -10rem !important;
  }

  .mt-sm-n19 {
    margin-top: -11rem !important;
  }

  .mt-sm-n20 {
    margin-top: -12rem !important;
  }

  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .me-sm-n3 {
    margin-right: -1rem !important;
  }

  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .me-sm-n5 {
    margin-right: -2rem !important;
  }

  .me-sm-n6 {
    margin-right: -2.5rem !important;
  }

  .me-sm-n7 {
    margin-right: -3rem !important;
  }

  .me-sm-n8 {
    margin-right: -3.5rem !important;
  }

  .me-sm-n9 {
    margin-right: -4rem !important;
  }

  .me-sm-n10 {
    margin-right: -4.5rem !important;
  }

  .me-sm-n11 {
    margin-right: -5rem !important;
  }

  .me-sm-n12 {
    margin-right: -5.5rem !important;
  }

  .me-sm-n13 {
    margin-right: -6rem !important;
  }

  .me-sm-n14 {
    margin-right: -6.5rem !important;
  }

  .me-sm-n15 {
    margin-right: -7rem !important;
  }

  .me-sm-n16 {
    margin-right: -8rem !important;
  }

  .me-sm-n17 {
    margin-right: -9rem !important;
  }

  .me-sm-n18 {
    margin-right: -10rem !important;
  }

  .me-sm-n19 {
    margin-right: -11rem !important;
  }

  .me-sm-n20 {
    margin-right: -12rem !important;
  }

  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-sm-n5 {
    margin-bottom: -2rem !important;
  }

  .mb-sm-n6 {
    margin-bottom: -2.5rem !important;
  }

  .mb-sm-n7 {
    margin-bottom: -3rem !important;
  }

  .mb-sm-n8 {
    margin-bottom: -3.5rem !important;
  }

  .mb-sm-n9 {
    margin-bottom: -4rem !important;
  }

  .mb-sm-n10 {
    margin-bottom: -4.5rem !important;
  }

  .mb-sm-n11 {
    margin-bottom: -5rem !important;
  }

  .mb-sm-n12 {
    margin-bottom: -5.5rem !important;
  }

  .mb-sm-n13 {
    margin-bottom: -6rem !important;
  }

  .mb-sm-n14 {
    margin-bottom: -6.5rem !important;
  }

  .mb-sm-n15 {
    margin-bottom: -7rem !important;
  }

  .mb-sm-n16 {
    margin-bottom: -8rem !important;
  }

  .mb-sm-n17 {
    margin-bottom: -9rem !important;
  }

  .mb-sm-n18 {
    margin-bottom: -10rem !important;
  }

  .mb-sm-n19 {
    margin-bottom: -11rem !important;
  }

  .mb-sm-n20 {
    margin-bottom: -12rem !important;
  }

  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-sm-n3 {
    margin-left: -1rem !important;
  }

  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-sm-n5 {
    margin-left: -2rem !important;
  }

  .ms-sm-n6 {
    margin-left: -2.5rem !important;
  }

  .ms-sm-n7 {
    margin-left: -3rem !important;
  }

  .ms-sm-n8 {
    margin-left: -3.5rem !important;
  }

  .ms-sm-n9 {
    margin-left: -4rem !important;
  }

  .ms-sm-n10 {
    margin-left: -4.5rem !important;
  }

  .ms-sm-n11 {
    margin-left: -5rem !important;
  }

  .ms-sm-n12 {
    margin-left: -5.5rem !important;
  }

  .ms-sm-n13 {
    margin-left: -6rem !important;
  }

  .ms-sm-n14 {
    margin-left: -6.5rem !important;
  }

  .ms-sm-n15 {
    margin-left: -7rem !important;
  }

  .ms-sm-n16 {
    margin-left: -8rem !important;
  }

  .ms-sm-n17 {
    margin-left: -9rem !important;
  }

  .ms-sm-n18 {
    margin-left: -10rem !important;
  }

  .ms-sm-n19 {
    margin-left: -11rem !important;
  }

  .ms-sm-n20 {
    margin-left: -12rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 2rem !important;
  }

  .p-sm-6 {
    padding: 2.5rem !important;
  }

  .p-sm-7 {
    padding: 3rem !important;
  }

  .p-sm-8 {
    padding: 3.5rem !important;
  }

  .p-sm-9 {
    padding: 4rem !important;
  }

  .p-sm-10 {
    padding: 4.5rem !important;
  }

  .p-sm-11 {
    padding: 5rem !important;
  }

  .p-sm-12 {
    padding: 5.5rem !important;
  }

  .p-sm-13 {
    padding: 6rem !important;
  }

  .p-sm-14 {
    padding: 6.5rem !important;
  }

  .p-sm-15 {
    padding: 7rem !important;
  }

  .p-sm-16 {
    padding: 8rem !important;
  }

  .p-sm-17 {
    padding: 9rem !important;
  }

  .p-sm-18 {
    padding: 10rem !important;
  }

  .p-sm-19 {
    padding: 11rem !important;
  }

  .p-sm-20 {
    padding: 12rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-sm-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-sm-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-sm-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-sm-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-sm-10 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-sm-11 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .px-sm-12 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important;
  }

  .px-sm-13 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-sm-14 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important;
  }

  .px-sm-15 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }

  .px-sm-16 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .px-sm-17 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }

  .px-sm-18 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .px-sm-19 {
    padding-right: 11rem !important;
    padding-left: 11rem !important;
  }

  .px-sm-20 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-sm-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-sm-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-sm-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-sm-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-sm-10 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-sm-11 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-sm-12 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }

  .py-sm-13 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-sm-14 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }

  .py-sm-15 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .py-sm-16 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-sm-17 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .py-sm-18 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .py-sm-19 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }

  .py-sm-20 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 2rem !important;
  }

  .pt-sm-6 {
    padding-top: 2.5rem !important;
  }

  .pt-sm-7 {
    padding-top: 3rem !important;
  }

  .pt-sm-8 {
    padding-top: 3.5rem !important;
  }

  .pt-sm-9 {
    padding-top: 4rem !important;
  }

  .pt-sm-10 {
    padding-top: 4.5rem !important;
  }

  .pt-sm-11 {
    padding-top: 5rem !important;
  }

  .pt-sm-12 {
    padding-top: 5.5rem !important;
  }

  .pt-sm-13 {
    padding-top: 6rem !important;
  }

  .pt-sm-14 {
    padding-top: 6.5rem !important;
  }

  .pt-sm-15 {
    padding-top: 7rem !important;
  }

  .pt-sm-16 {
    padding-top: 8rem !important;
  }

  .pt-sm-17 {
    padding-top: 9rem !important;
  }

  .pt-sm-18 {
    padding-top: 10rem !important;
  }

  .pt-sm-19 {
    padding-top: 11rem !important;
  }

  .pt-sm-20 {
    padding-top: 12rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 2rem !important;
  }

  .pe-sm-6 {
    padding-right: 2.5rem !important;
  }

  .pe-sm-7 {
    padding-right: 3rem !important;
  }

  .pe-sm-8 {
    padding-right: 3.5rem !important;
  }

  .pe-sm-9 {
    padding-right: 4rem !important;
  }

  .pe-sm-10 {
    padding-right: 4.5rem !important;
  }

  .pe-sm-11 {
    padding-right: 5rem !important;
  }

  .pe-sm-12 {
    padding-right: 5.5rem !important;
  }

  .pe-sm-13 {
    padding-right: 6rem !important;
  }

  .pe-sm-14 {
    padding-right: 6.5rem !important;
  }

  .pe-sm-15 {
    padding-right: 7rem !important;
  }

  .pe-sm-16 {
    padding-right: 8rem !important;
  }

  .pe-sm-17 {
    padding-right: 9rem !important;
  }

  .pe-sm-18 {
    padding-right: 10rem !important;
  }

  .pe-sm-19 {
    padding-right: 11rem !important;
  }

  .pe-sm-20 {
    padding-right: 12rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 2rem !important;
  }

  .pb-sm-6 {
    padding-bottom: 2.5rem !important;
  }

  .pb-sm-7 {
    padding-bottom: 3rem !important;
  }

  .pb-sm-8 {
    padding-bottom: 3.5rem !important;
  }

  .pb-sm-9 {
    padding-bottom: 4rem !important;
  }

  .pb-sm-10 {
    padding-bottom: 4.5rem !important;
  }

  .pb-sm-11 {
    padding-bottom: 5rem !important;
  }

  .pb-sm-12 {
    padding-bottom: 5.5rem !important;
  }

  .pb-sm-13 {
    padding-bottom: 6rem !important;
  }

  .pb-sm-14 {
    padding-bottom: 6.5rem !important;
  }

  .pb-sm-15 {
    padding-bottom: 7rem !important;
  }

  .pb-sm-16 {
    padding-bottom: 8rem !important;
  }

  .pb-sm-17 {
    padding-bottom: 9rem !important;
  }

  .pb-sm-18 {
    padding-bottom: 10rem !important;
  }

  .pb-sm-19 {
    padding-bottom: 11rem !important;
  }

  .pb-sm-20 {
    padding-bottom: 12rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 2rem !important;
  }

  .ps-sm-6 {
    padding-left: 2.5rem !important;
  }

  .ps-sm-7 {
    padding-left: 3rem !important;
  }

  .ps-sm-8 {
    padding-left: 3.5rem !important;
  }

  .ps-sm-9 {
    padding-left: 4rem !important;
  }

  .ps-sm-10 {
    padding-left: 4.5rem !important;
  }

  .ps-sm-11 {
    padding-left: 5rem !important;
  }

  .ps-sm-12 {
    padding-left: 5.5rem !important;
  }

  .ps-sm-13 {
    padding-left: 6rem !important;
  }

  .ps-sm-14 {
    padding-left: 6.5rem !important;
  }

  .ps-sm-15 {
    padding-left: 7rem !important;
  }

  .ps-sm-16 {
    padding-left: 8rem !important;
  }

  .ps-sm-17 {
    padding-left: 9rem !important;
  }

  .ps-sm-18 {
    padding-left: 10rem !important;
  }

  .ps-sm-19 {
    padding-left: 11rem !important;
  }

  .ps-sm-20 {
    padding-left: 12rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }

  .rounded-sm-0 {
    border-radius: 0 !important;
  }

  .rounded-sm {
    border-radius: 0.375rem !important;
  }

  .rounded-sm-1 {
    border-radius: 0.25rem !important;
  }

  .rounded-sm-2 {
    border-radius: 0.375rem !important;
  }

  .rounded-sm-3 {
    border-radius: 0.575rem !important;
  }

  .rounded-sm-4 {
    border-radius: 1.5em !important;
  }

  .rounded-sm-pill {
    border-radius: 50rem !important;
  }

  .rounded-sm-block {
    border-radius: 3rem !important;
  }

  .rounded-sm-blob {
    border-radius: 30% 70% 70% 30%/30% 30% 70% 70% !important;
  }

  .rounded-sm-circle {
    border-radius: 50% !important;
  }

  .rounded-top-sm-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .rounded-top-sm {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }

  .rounded-top-sm-1 {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }

  .rounded-top-sm-2 {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }

  .rounded-top-sm-3 {
    border-top-left-radius: 0.575rem !important;
    border-top-right-radius: 0.575rem !important;
  }

  .rounded-top-sm-4 {
    border-top-left-radius: 1.5em !important;
    border-top-right-radius: 1.5em !important;
  }

  .rounded-top-sm-pill {
    border-top-left-radius: 50rem !important;
    border-top-right-radius: 50rem !important;
  }

  .rounded-top-sm-block {
    border-top-left-radius: 3rem !important;
    border-top-right-radius: 3rem !important;
  }

  .rounded-top-sm-circle {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }

  .rounded-end-sm-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .rounded-end-sm {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .rounded-end-sm-1 {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  .rounded-end-sm-2 {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .rounded-end-sm-3 {
    border-top-right-radius: 0.575rem !important;
    border-bottom-right-radius: 0.575rem !important;
  }

  .rounded-end-sm-4 {
    border-top-right-radius: 1.5em !important;
    border-bottom-right-radius: 1.5em !important;
  }

  .rounded-end-sm-pill {
    border-top-right-radius: 50rem !important;
    border-bottom-right-radius: 50rem !important;
  }

  .rounded-end-sm-block {
    border-top-right-radius: 3rem !important;
    border-bottom-right-radius: 3rem !important;
  }

  .rounded-end-sm-circle {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }

  .rounded-bottom-sm-0 {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .rounded-bottom-sm {
    border-bottom-left-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .rounded-bottom-sm-1 {
    border-bottom-left-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  .rounded-bottom-sm-2 {
    border-bottom-left-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .rounded-bottom-sm-3 {
    border-bottom-left-radius: 0.575rem !important;
    border-bottom-right-radius: 0.575rem !important;
  }

  .rounded-bottom-sm-4 {
    border-bottom-left-radius: 1.5em !important;
    border-bottom-right-radius: 1.5em !important;
  }

  .rounded-bottom-sm-pill {
    border-bottom-left-radius: 50rem !important;
    border-bottom-right-radius: 50rem !important;
  }

  .rounded-bottom-sm-block {
    border-bottom-left-radius: 3rem !important;
    border-bottom-right-radius: 3rem !important;
  }

  .rounded-bottom-sm-circle {
    border-bottom-left-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }

  .rounded-start-sm-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .rounded-start-sm {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .rounded-start-sm-1 {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .rounded-start-sm-2 {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .rounded-start-sm-3 {
    border-top-left-radius: 0.575rem !important;
    border-bottom-left-radius: 0.575rem !important;
  }

  .rounded-start-sm-4 {
    border-top-left-radius: 1.5em !important;
    border-bottom-left-radius: 1.5em !important;
  }

  .rounded-start-sm-pill {
    border-top-left-radius: 50rem !important;
    border-bottom-left-radius: 50rem !important;
  }

  .rounded-start-sm-block {
    border-top-left-radius: 3rem !important;
    border-bottom-left-radius: 3rem !important;
  }

  .rounded-start-sm-circle {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .position-md-static {
    position: static !important;
  }

  .position-md-relative {
    position: relative !important;
  }

  .position-md-absolute {
    position: absolute !important;
  }

  .position-md-fixed {
    position: fixed !important;
  }

  .position-md-sticky {
    position: sticky !important;
  }

  .border-md {
    border: 1px solid #F0F5FA !important;
  }

  .border-md-0 {
    border: 0 !important;
  }

  .border-top-md {
    border-top: 1px solid #F0F5FA !important;
  }

  .border-top-md-0 {
    border-top: 0 !important;
  }

  .border-end-md {
    border-right: 1px solid #F0F5FA !important;
  }

  .border-end-md-0 {
    border-right: 0 !important;
  }

  .border-start-md {
    border-left: 1px solid #F0F5FA !important;
  }

  .border-start-md-0 {
    border-left: 0 !important;
  }

  .w-md-0 {
    width: 0% !important;
  }

  .w-md-10 {
    width: 10% !important;
  }

  .w-md-20 {
    width: 20% !important;
  }

  .w-md-25 {
    width: 25% !important;
  }

  .w-md-30 {
    width: 30% !important;
  }

  .w-md-40 {
    width: 40% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }

  .w-md-60 {
    width: 60% !important;
  }

  .w-md-70 {
    width: 70% !important;
  }

  .w-md-75 {
    width: 75% !important;
  }

  .w-md-80 {
    width: 80% !important;
  }

  .w-md-90 {
    width: 90% !important;
  }

  .w-md-100 {
    width: 100% !important;
  }

  .w-md-auto {
    width: auto !important;
  }

  .h-md-0 {
    height: 0% !important;
  }

  .h-md-10 {
    height: 10% !important;
  }

  .h-md-20 {
    height: 20% !important;
  }

  .h-md-25 {
    height: 25% !important;
  }

  .h-md-30 {
    height: 30% !important;
  }

  .h-md-40 {
    height: 40% !important;
  }

  .h-md-50 {
    height: 50% !important;
  }

  .h-md-60 {
    height: 60% !important;
  }

  .h-md-70 {
    height: 70% !important;
  }

  .h-md-75 {
    height: 75% !important;
  }

  .h-md-80 {
    height: 80% !important;
  }

  .h-md-90 {
    height: 90% !important;
  }

  .h-md-100 {
    height: 100% !important;
  }

  .h-md-auto {
    height: auto !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 2rem !important;
  }

  .gap-md-6 {
    gap: 2.5rem !important;
  }

  .gap-md-7 {
    gap: 3rem !important;
  }

  .gap-md-8 {
    gap: 3.5rem !important;
  }

  .gap-md-9 {
    gap: 4rem !important;
  }

  .gap-md-10 {
    gap: 4.5rem !important;
  }

  .gap-md-11 {
    gap: 5rem !important;
  }

  .gap-md-12 {
    gap: 5.5rem !important;
  }

  .gap-md-13 {
    gap: 6rem !important;
  }

  .gap-md-14 {
    gap: 6.5rem !important;
  }

  .gap-md-15 {
    gap: 7rem !important;
  }

  .gap-md-16 {
    gap: 8rem !important;
  }

  .gap-md-17 {
    gap: 9rem !important;
  }

  .gap-md-18 {
    gap: 10rem !important;
  }

  .gap-md-19 {
    gap: 11rem !important;
  }

  .gap-md-20 {
    gap: 12rem !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 2rem !important;
  }

  .m-md-6 {
    margin: 2.5rem !important;
  }

  .m-md-7 {
    margin: 3rem !important;
  }

  .m-md-8 {
    margin: 3.5rem !important;
  }

  .m-md-9 {
    margin: 4rem !important;
  }

  .m-md-10 {
    margin: 4.5rem !important;
  }

  .m-md-11 {
    margin: 5rem !important;
  }

  .m-md-12 {
    margin: 5.5rem !important;
  }

  .m-md-13 {
    margin: 6rem !important;
  }

  .m-md-14 {
    margin: 6.5rem !important;
  }

  .m-md-15 {
    margin: 7rem !important;
  }

  .m-md-16 {
    margin: 8rem !important;
  }

  .m-md-17 {
    margin: 9rem !important;
  }

  .m-md-18 {
    margin: 10rem !important;
  }

  .m-md-19 {
    margin: 11rem !important;
  }

  .m-md-20 {
    margin: 12rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-md-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-md-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-md-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-md-10 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-md-11 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-md-12 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important;
  }

  .mx-md-13 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-md-14 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important;
  }

  .mx-md-15 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }

  .mx-md-16 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-md-17 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }

  .mx-md-18 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .mx-md-19 {
    margin-right: 11rem !important;
    margin-left: 11rem !important;
  }

  .mx-md-20 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-md-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-md-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-md-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-md-10 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-md-11 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-md-12 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }

  .my-md-13 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-md-14 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }

  .my-md-15 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .my-md-16 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-md-17 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .my-md-18 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-md-19 {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important;
  }

  .my-md-20 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 2rem !important;
  }

  .mt-md-6 {
    margin-top: 2.5rem !important;
  }

  .mt-md-7 {
    margin-top: 3rem !important;
  }

  .mt-md-8 {
    margin-top: 3.5rem !important;
  }

  .mt-md-9 {
    margin-top: 4rem !important;
  }

  .mt-md-10 {
    margin-top: 4.5rem !important;
  }

  .mt-md-11 {
    margin-top: 5rem !important;
  }

  .mt-md-12 {
    margin-top: 5.5rem !important;
  }

  .mt-md-13 {
    margin-top: 6rem !important;
  }

  .mt-md-14 {
    margin-top: 6.5rem !important;
  }

  .mt-md-15 {
    margin-top: 7rem !important;
  }

  .mt-md-16 {
    margin-top: 8rem !important;
  }

  .mt-md-17 {
    margin-top: 9rem !important;
  }

  .mt-md-18 {
    margin-top: 10rem !important;
  }

  .mt-md-19 {
    margin-top: 11rem !important;
  }

  .mt-md-20 {
    margin-top: 12rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 2rem !important;
  }

  .me-md-6 {
    margin-right: 2.5rem !important;
  }

  .me-md-7 {
    margin-right: 3rem !important;
  }

  .me-md-8 {
    margin-right: 3.5rem !important;
  }

  .me-md-9 {
    margin-right: 4rem !important;
  }

  .me-md-10 {
    margin-right: 4.5rem !important;
  }

  .me-md-11 {
    margin-right: 5rem !important;
  }

  .me-md-12 {
    margin-right: 5.5rem !important;
  }

  .me-md-13 {
    margin-right: 6rem !important;
  }

  .me-md-14 {
    margin-right: 6.5rem !important;
  }

  .me-md-15 {
    margin-right: 7rem !important;
  }

  .me-md-16 {
    margin-right: 8rem !important;
  }

  .me-md-17 {
    margin-right: 9rem !important;
  }

  .me-md-18 {
    margin-right: 10rem !important;
  }

  .me-md-19 {
    margin-right: 11rem !important;
  }

  .me-md-20 {
    margin-right: 12rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 2rem !important;
  }

  .mb-md-6 {
    margin-bottom: 2.5rem !important;
  }

  .mb-md-7 {
    margin-bottom: 3rem !important;
  }

  .mb-md-8 {
    margin-bottom: 3.5rem !important;
  }

  .mb-md-9 {
    margin-bottom: 4rem !important;
  }

  .mb-md-10 {
    margin-bottom: 4.5rem !important;
  }

  .mb-md-11 {
    margin-bottom: 5rem !important;
  }

  .mb-md-12 {
    margin-bottom: 5.5rem !important;
  }

  .mb-md-13 {
    margin-bottom: 6rem !important;
  }

  .mb-md-14 {
    margin-bottom: 6.5rem !important;
  }

  .mb-md-15 {
    margin-bottom: 7rem !important;
  }

  .mb-md-16 {
    margin-bottom: 8rem !important;
  }

  .mb-md-17 {
    margin-bottom: 9rem !important;
  }

  .mb-md-18 {
    margin-bottom: 10rem !important;
  }

  .mb-md-19 {
    margin-bottom: 11rem !important;
  }

  .mb-md-20 {
    margin-bottom: 12rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 2rem !important;
  }

  .ms-md-6 {
    margin-left: 2.5rem !important;
  }

  .ms-md-7 {
    margin-left: 3rem !important;
  }

  .ms-md-8 {
    margin-left: 3.5rem !important;
  }

  .ms-md-9 {
    margin-left: 4rem !important;
  }

  .ms-md-10 {
    margin-left: 4.5rem !important;
  }

  .ms-md-11 {
    margin-left: 5rem !important;
  }

  .ms-md-12 {
    margin-left: 5.5rem !important;
  }

  .ms-md-13 {
    margin-left: 6rem !important;
  }

  .ms-md-14 {
    margin-left: 6.5rem !important;
  }

  .ms-md-15 {
    margin-left: 7rem !important;
  }

  .ms-md-16 {
    margin-left: 8rem !important;
  }

  .ms-md-17 {
    margin-left: 9rem !important;
  }

  .ms-md-18 {
    margin-left: 10rem !important;
  }

  .ms-md-19 {
    margin-left: 11rem !important;
  }

  .ms-md-20 {
    margin-left: 12rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -2rem !important;
  }

  .m-md-n6 {
    margin: -2.5rem !important;
  }

  .m-md-n7 {
    margin: -3rem !important;
  }

  .m-md-n8 {
    margin: -3.5rem !important;
  }

  .m-md-n9 {
    margin: -4rem !important;
  }

  .m-md-n10 {
    margin: -4.5rem !important;
  }

  .m-md-n11 {
    margin: -5rem !important;
  }

  .m-md-n12 {
    margin: -5.5rem !important;
  }

  .m-md-n13 {
    margin: -6rem !important;
  }

  .m-md-n14 {
    margin: -6.5rem !important;
  }

  .m-md-n15 {
    margin: -7rem !important;
  }

  .m-md-n16 {
    margin: -8rem !important;
  }

  .m-md-n17 {
    margin: -9rem !important;
  }

  .m-md-n18 {
    margin: -10rem !important;
  }

  .m-md-n19 {
    margin: -11rem !important;
  }

  .m-md-n20 {
    margin: -12rem !important;
  }

  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-md-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-md-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-md-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-md-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-md-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-md-n10 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .mx-md-n11 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .mx-md-n12 {
    margin-right: -5.5rem !important;
    margin-left: -5.5rem !important;
  }

  .mx-md-n13 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }

  .mx-md-n14 {
    margin-right: -6.5rem !important;
    margin-left: -6.5rem !important;
  }

  .mx-md-n15 {
    margin-right: -7rem !important;
    margin-left: -7rem !important;
  }

  .mx-md-n16 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }

  .mx-md-n17 {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }

  .mx-md-n18 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }

  .mx-md-n19 {
    margin-right: -11rem !important;
    margin-left: -11rem !important;
  }

  .mx-md-n20 {
    margin-right: -12rem !important;
    margin-left: -12rem !important;
  }

  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-md-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-md-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-md-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-md-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-md-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-md-n10 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-md-n11 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .my-md-n12 {
    margin-top: -5.5rem !important;
    margin-bottom: -5.5rem !important;
  }

  .my-md-n13 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-md-n14 {
    margin-top: -6.5rem !important;
    margin-bottom: -6.5rem !important;
  }

  .my-md-n15 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }

  .my-md-n16 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-md-n17 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .my-md-n18 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .my-md-n19 {
    margin-top: -11rem !important;
    margin-bottom: -11rem !important;
  }

  .my-md-n20 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-md-n3 {
    margin-top: -1rem !important;
  }

  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-md-n5 {
    margin-top: -2rem !important;
  }

  .mt-md-n6 {
    margin-top: -2.5rem !important;
  }

  .mt-md-n7 {
    margin-top: -3rem !important;
  }

  .mt-md-n8 {
    margin-top: -3.5rem !important;
  }

  .mt-md-n9 {
    margin-top: -4rem !important;
  }

  .mt-md-n10 {
    margin-top: -4.5rem !important;
  }

  .mt-md-n11 {
    margin-top: -5rem !important;
  }

  .mt-md-n12 {
    margin-top: -5.5rem !important;
  }

  .mt-md-n13 {
    margin-top: -6rem !important;
  }

  .mt-md-n14 {
    margin-top: -6.5rem !important;
  }

  .mt-md-n15 {
    margin-top: -7rem !important;
  }

  .mt-md-n16 {
    margin-top: -8rem !important;
  }

  .mt-md-n17 {
    margin-top: -9rem !important;
  }

  .mt-md-n18 {
    margin-top: -10rem !important;
  }

  .mt-md-n19 {
    margin-top: -11rem !important;
  }

  .mt-md-n20 {
    margin-top: -12rem !important;
  }

  .me-md-n1 {
    margin-right: -0.25rem !important;
  }

  .me-md-n2 {
    margin-right: -0.5rem !important;
  }

  .me-md-n3 {
    margin-right: -1rem !important;
  }

  .me-md-n4 {
    margin-right: -1.5rem !important;
  }

  .me-md-n5 {
    margin-right: -2rem !important;
  }

  .me-md-n6 {
    margin-right: -2.5rem !important;
  }

  .me-md-n7 {
    margin-right: -3rem !important;
  }

  .me-md-n8 {
    margin-right: -3.5rem !important;
  }

  .me-md-n9 {
    margin-right: -4rem !important;
  }

  .me-md-n10 {
    margin-right: -4.5rem !important;
  }

  .me-md-n11 {
    margin-right: -5rem !important;
  }

  .me-md-n12 {
    margin-right: -5.5rem !important;
  }

  .me-md-n13 {
    margin-right: -6rem !important;
  }

  .me-md-n14 {
    margin-right: -6.5rem !important;
  }

  .me-md-n15 {
    margin-right: -7rem !important;
  }

  .me-md-n16 {
    margin-right: -8rem !important;
  }

  .me-md-n17 {
    margin-right: -9rem !important;
  }

  .me-md-n18 {
    margin-right: -10rem !important;
  }

  .me-md-n19 {
    margin-right: -11rem !important;
  }

  .me-md-n20 {
    margin-right: -12rem !important;
  }

  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-md-n5 {
    margin-bottom: -2rem !important;
  }

  .mb-md-n6 {
    margin-bottom: -2.5rem !important;
  }

  .mb-md-n7 {
    margin-bottom: -3rem !important;
  }

  .mb-md-n8 {
    margin-bottom: -3.5rem !important;
  }

  .mb-md-n9 {
    margin-bottom: -4rem !important;
  }

  .mb-md-n10 {
    margin-bottom: -4.5rem !important;
  }

  .mb-md-n11 {
    margin-bottom: -5rem !important;
  }

  .mb-md-n12 {
    margin-bottom: -5.5rem !important;
  }

  .mb-md-n13 {
    margin-bottom: -6rem !important;
  }

  .mb-md-n14 {
    margin-bottom: -6.5rem !important;
  }

  .mb-md-n15 {
    margin-bottom: -7rem !important;
  }

  .mb-md-n16 {
    margin-bottom: -8rem !important;
  }

  .mb-md-n17 {
    margin-bottom: -9rem !important;
  }

  .mb-md-n18 {
    margin-bottom: -10rem !important;
  }

  .mb-md-n19 {
    margin-bottom: -11rem !important;
  }

  .mb-md-n20 {
    margin-bottom: -12rem !important;
  }

  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-md-n3 {
    margin-left: -1rem !important;
  }

  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-md-n5 {
    margin-left: -2rem !important;
  }

  .ms-md-n6 {
    margin-left: -2.5rem !important;
  }

  .ms-md-n7 {
    margin-left: -3rem !important;
  }

  .ms-md-n8 {
    margin-left: -3.5rem !important;
  }

  .ms-md-n9 {
    margin-left: -4rem !important;
  }

  .ms-md-n10 {
    margin-left: -4.5rem !important;
  }

  .ms-md-n11 {
    margin-left: -5rem !important;
  }

  .ms-md-n12 {
    margin-left: -5.5rem !important;
  }

  .ms-md-n13 {
    margin-left: -6rem !important;
  }

  .ms-md-n14 {
    margin-left: -6.5rem !important;
  }

  .ms-md-n15 {
    margin-left: -7rem !important;
  }

  .ms-md-n16 {
    margin-left: -8rem !important;
  }

  .ms-md-n17 {
    margin-left: -9rem !important;
  }

  .ms-md-n18 {
    margin-left: -10rem !important;
  }

  .ms-md-n19 {
    margin-left: -11rem !important;
  }

  .ms-md-n20 {
    margin-left: -12rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 2rem !important;
  }

  .p-md-6 {
    padding: 2.5rem !important;
  }

  .p-md-7 {
    padding: 3rem !important;
  }

  .p-md-8 {
    padding: 3.5rem !important;
  }

  .p-md-9 {
    padding: 4rem !important;
  }

  .p-md-10 {
    padding: 4.5rem !important;
  }

  .p-md-11 {
    padding: 5rem !important;
  }

  .p-md-12 {
    padding: 5.5rem !important;
  }

  .p-md-13 {
    padding: 6rem !important;
  }

  .p-md-14 {
    padding: 6.5rem !important;
  }

  .p-md-15 {
    padding: 7rem !important;
  }

  .p-md-16 {
    padding: 8rem !important;
  }

  .p-md-17 {
    padding: 9rem !important;
  }

  .p-md-18 {
    padding: 10rem !important;
  }

  .p-md-19 {
    padding: 11rem !important;
  }

  .p-md-20 {
    padding: 12rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-md-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-md-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-md-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-md-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-md-10 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-md-11 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .px-md-12 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important;
  }

  .px-md-13 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-md-14 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important;
  }

  .px-md-15 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }

  .px-md-16 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .px-md-17 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }

  .px-md-18 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .px-md-19 {
    padding-right: 11rem !important;
    padding-left: 11rem !important;
  }

  .px-md-20 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-md-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-md-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-md-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-md-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-md-10 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-md-11 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-md-12 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }

  .py-md-13 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-md-14 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }

  .py-md-15 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .py-md-16 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-md-17 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .py-md-18 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .py-md-19 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }

  .py-md-20 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 2rem !important;
  }

  .pt-md-6 {
    padding-top: 2.5rem !important;
  }

  .pt-md-7 {
    padding-top: 3rem !important;
  }

  .pt-md-8 {
    padding-top: 3.5rem !important;
  }

  .pt-md-9 {
    padding-top: 4rem !important;
  }

  .pt-md-10 {
    padding-top: 4.5rem !important;
  }

  .pt-md-11 {
    padding-top: 5rem !important;
  }

  .pt-md-12 {
    padding-top: 5.5rem !important;
  }

  .pt-md-13 {
    padding-top: 6rem !important;
  }

  .pt-md-14 {
    padding-top: 6.5rem !important;
  }

  .pt-md-15 {
    padding-top: 7rem !important;
  }

  .pt-md-16 {
    padding-top: 8rem !important;
  }

  .pt-md-17 {
    padding-top: 9rem !important;
  }

  .pt-md-18 {
    padding-top: 10rem !important;
  }

  .pt-md-19 {
    padding-top: 11rem !important;
  }

  .pt-md-20 {
    padding-top: 12rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 2rem !important;
  }

  .pe-md-6 {
    padding-right: 2.5rem !important;
  }

  .pe-md-7 {
    padding-right: 3rem !important;
  }

  .pe-md-8 {
    padding-right: 3.5rem !important;
  }

  .pe-md-9 {
    padding-right: 4rem !important;
  }

  .pe-md-10 {
    padding-right: 4.5rem !important;
  }

  .pe-md-11 {
    padding-right: 5rem !important;
  }

  .pe-md-12 {
    padding-right: 5.5rem !important;
  }

  .pe-md-13 {
    padding-right: 6rem !important;
  }

  .pe-md-14 {
    padding-right: 6.5rem !important;
  }

  .pe-md-15 {
    padding-right: 7rem !important;
  }

  .pe-md-16 {
    padding-right: 8rem !important;
  }

  .pe-md-17 {
    padding-right: 9rem !important;
  }

  .pe-md-18 {
    padding-right: 10rem !important;
  }

  .pe-md-19 {
    padding-right: 11rem !important;
  }

  .pe-md-20 {
    padding-right: 12rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 2rem !important;
  }

  .pb-md-6 {
    padding-bottom: 2.5rem !important;
  }

  .pb-md-7 {
    padding-bottom: 3rem !important;
  }

  .pb-md-8 {
    padding-bottom: 3.5rem !important;
  }

  .pb-md-9 {
    padding-bottom: 4rem !important;
  }

  .pb-md-10 {
    padding-bottom: 4.5rem !important;
  }

  .pb-md-11 {
    padding-bottom: 5rem !important;
  }

  .pb-md-12 {
    padding-bottom: 5.5rem !important;
  }

  .pb-md-13 {
    padding-bottom: 6rem !important;
  }

  .pb-md-14 {
    padding-bottom: 6.5rem !important;
  }

  .pb-md-15 {
    padding-bottom: 7rem !important;
  }

  .pb-md-16 {
    padding-bottom: 8rem !important;
  }

  .pb-md-17 {
    padding-bottom: 9rem !important;
  }

  .pb-md-18 {
    padding-bottom: 10rem !important;
  }

  .pb-md-19 {
    padding-bottom: 11rem !important;
  }

  .pb-md-20 {
    padding-bottom: 12rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 2rem !important;
  }

  .ps-md-6 {
    padding-left: 2.5rem !important;
  }

  .ps-md-7 {
    padding-left: 3rem !important;
  }

  .ps-md-8 {
    padding-left: 3.5rem !important;
  }

  .ps-md-9 {
    padding-left: 4rem !important;
  }

  .ps-md-10 {
    padding-left: 4.5rem !important;
  }

  .ps-md-11 {
    padding-left: 5rem !important;
  }

  .ps-md-12 {
    padding-left: 5.5rem !important;
  }

  .ps-md-13 {
    padding-left: 6rem !important;
  }

  .ps-md-14 {
    padding-left: 6.5rem !important;
  }

  .ps-md-15 {
    padding-left: 7rem !important;
  }

  .ps-md-16 {
    padding-left: 8rem !important;
  }

  .ps-md-17 {
    padding-left: 9rem !important;
  }

  .ps-md-18 {
    padding-left: 10rem !important;
  }

  .ps-md-19 {
    padding-left: 11rem !important;
  }

  .ps-md-20 {
    padding-left: 12rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }

  .rounded-md-0 {
    border-radius: 0 !important;
  }

  .rounded-md {
    border-radius: 0.375rem !important;
  }

  .rounded-md-1 {
    border-radius: 0.25rem !important;
  }

  .rounded-md-2 {
    border-radius: 0.375rem !important;
  }

  .rounded-md-3 {
    border-radius: 0.575rem !important;
  }

  .rounded-md-4 {
    border-radius: 1.5em !important;
  }

  .rounded-md-pill {
    border-radius: 50rem !important;
  }

  .rounded-md-block {
    border-radius: 3rem !important;
  }

  .rounded-md-blob {
    border-radius: 30% 70% 70% 30%/30% 30% 70% 70% !important;
  }

  .rounded-md-circle {
    border-radius: 50% !important;
  }

  .rounded-top-md-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .rounded-top-md {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }

  .rounded-top-md-1 {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }

  .rounded-top-md-2 {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }

  .rounded-top-md-3 {
    border-top-left-radius: 0.575rem !important;
    border-top-right-radius: 0.575rem !important;
  }

  .rounded-top-md-4 {
    border-top-left-radius: 1.5em !important;
    border-top-right-radius: 1.5em !important;
  }

  .rounded-top-md-pill {
    border-top-left-radius: 50rem !important;
    border-top-right-radius: 50rem !important;
  }

  .rounded-top-md-block {
    border-top-left-radius: 3rem !important;
    border-top-right-radius: 3rem !important;
  }

  .rounded-top-md-circle {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }

  .rounded-end-md-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .rounded-end-md {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .rounded-end-md-1 {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  .rounded-end-md-2 {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .rounded-end-md-3 {
    border-top-right-radius: 0.575rem !important;
    border-bottom-right-radius: 0.575rem !important;
  }

  .rounded-end-md-4 {
    border-top-right-radius: 1.5em !important;
    border-bottom-right-radius: 1.5em !important;
  }

  .rounded-end-md-pill {
    border-top-right-radius: 50rem !important;
    border-bottom-right-radius: 50rem !important;
  }

  .rounded-end-md-block {
    border-top-right-radius: 3rem !important;
    border-bottom-right-radius: 3rem !important;
  }

  .rounded-end-md-circle {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }

  .rounded-bottom-md-0 {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .rounded-bottom-md {
    border-bottom-left-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .rounded-bottom-md-1 {
    border-bottom-left-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  .rounded-bottom-md-2 {
    border-bottom-left-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .rounded-bottom-md-3 {
    border-bottom-left-radius: 0.575rem !important;
    border-bottom-right-radius: 0.575rem !important;
  }

  .rounded-bottom-md-4 {
    border-bottom-left-radius: 1.5em !important;
    border-bottom-right-radius: 1.5em !important;
  }

  .rounded-bottom-md-pill {
    border-bottom-left-radius: 50rem !important;
    border-bottom-right-radius: 50rem !important;
  }

  .rounded-bottom-md-block {
    border-bottom-left-radius: 3rem !important;
    border-bottom-right-radius: 3rem !important;
  }

  .rounded-bottom-md-circle {
    border-bottom-left-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }

  .rounded-start-md-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .rounded-start-md {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .rounded-start-md-1 {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .rounded-start-md-2 {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .rounded-start-md-3 {
    border-top-left-radius: 0.575rem !important;
    border-bottom-left-radius: 0.575rem !important;
  }

  .rounded-start-md-4 {
    border-top-left-radius: 1.5em !important;
    border-bottom-left-radius: 1.5em !important;
  }

  .rounded-start-md-pill {
    border-top-left-radius: 50rem !important;
    border-bottom-left-radius: 50rem !important;
  }

  .rounded-start-md-block {
    border-top-left-radius: 3rem !important;
    border-bottom-left-radius: 3rem !important;
  }

  .rounded-start-md-circle {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .position-lg-static {
    position: static !important;
  }

  .position-lg-relative {
    position: relative !important;
  }

  .position-lg-absolute {
    position: absolute !important;
  }

  .position-lg-fixed {
    position: fixed !important;
  }

  .position-lg-sticky {
    position: sticky !important;
  }

  .border-lg {
    border: 1px solid #F0F5FA !important;
  }

  .border-lg-0 {
    border: 0 !important;
  }

  .border-top-lg {
    border-top: 1px solid #F0F5FA !important;
  }

  .border-top-lg-0 {
    border-top: 0 !important;
  }

  .border-end-lg {
    border-right: 1px solid #F0F5FA !important;
  }

  .border-end-lg-0 {
    border-right: 0 !important;
  }

  .border-start-lg {
    border-left: 1px solid #F0F5FA !important;
  }

  .border-start-lg-0 {
    border-left: 0 !important;
  }

  .w-lg-0 {
    width: 0% !important;
  }

  .w-lg-10 {
    width: 10% !important;
  }

  .w-lg-20 {
    width: 20% !important;
  }

  .w-lg-25 {
    width: 25% !important;
  }

  .w-lg-30 {
    width: 30% !important;
  }

  .w-lg-40 {
    width: 40% !important;
  }

  .w-lg-50 {
    width: 50% !important;
  }

  .w-lg-60 {
    width: 60% !important;
  }

  .w-lg-70 {
    width: 70% !important;
  }

  .w-lg-75 {
    width: 75% !important;
  }

  .w-lg-80 {
    width: 80% !important;
  }

  .w-lg-90 {
    width: 90% !important;
  }

  .w-lg-100 {
    width: 100% !important;
  }

  .w-lg-auto {
    width: auto !important;
  }

  .h-lg-0 {
    height: 0% !important;
  }

  .h-lg-10 {
    height: 10% !important;
  }

  .h-lg-20 {
    height: 20% !important;
  }

  .h-lg-25 {
    height: 25% !important;
  }

  .h-lg-30 {
    height: 30% !important;
  }

  .h-lg-40 {
    height: 40% !important;
  }

  .h-lg-50 {
    height: 50% !important;
  }

  .h-lg-60 {
    height: 60% !important;
  }

  .h-lg-70 {
    height: 70% !important;
  }

  .h-lg-75 {
    height: 75% !important;
  }

  .h-lg-80 {
    height: 80% !important;
  }

  .h-lg-90 {
    height: 90% !important;
  }

  .h-lg-100 {
    height: 100% !important;
  }

  .h-lg-auto {
    height: auto !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 2rem !important;
  }

  .gap-lg-6 {
    gap: 2.5rem !important;
  }

  .gap-lg-7 {
    gap: 3rem !important;
  }

  .gap-lg-8 {
    gap: 3.5rem !important;
  }

  .gap-lg-9 {
    gap: 4rem !important;
  }

  .gap-lg-10 {
    gap: 4.5rem !important;
  }

  .gap-lg-11 {
    gap: 5rem !important;
  }

  .gap-lg-12 {
    gap: 5.5rem !important;
  }

  .gap-lg-13 {
    gap: 6rem !important;
  }

  .gap-lg-14 {
    gap: 6.5rem !important;
  }

  .gap-lg-15 {
    gap: 7rem !important;
  }

  .gap-lg-16 {
    gap: 8rem !important;
  }

  .gap-lg-17 {
    gap: 9rem !important;
  }

  .gap-lg-18 {
    gap: 10rem !important;
  }

  .gap-lg-19 {
    gap: 11rem !important;
  }

  .gap-lg-20 {
    gap: 12rem !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 2rem !important;
  }

  .m-lg-6 {
    margin: 2.5rem !important;
  }

  .m-lg-7 {
    margin: 3rem !important;
  }

  .m-lg-8 {
    margin: 3.5rem !important;
  }

  .m-lg-9 {
    margin: 4rem !important;
  }

  .m-lg-10 {
    margin: 4.5rem !important;
  }

  .m-lg-11 {
    margin: 5rem !important;
  }

  .m-lg-12 {
    margin: 5.5rem !important;
  }

  .m-lg-13 {
    margin: 6rem !important;
  }

  .m-lg-14 {
    margin: 6.5rem !important;
  }

  .m-lg-15 {
    margin: 7rem !important;
  }

  .m-lg-16 {
    margin: 8rem !important;
  }

  .m-lg-17 {
    margin: 9rem !important;
  }

  .m-lg-18 {
    margin: 10rem !important;
  }

  .m-lg-19 {
    margin: 11rem !important;
  }

  .m-lg-20 {
    margin: 12rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-lg-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-lg-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-lg-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-lg-10 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-lg-11 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-lg-12 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important;
  }

  .mx-lg-13 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-lg-14 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important;
  }

  .mx-lg-15 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }

  .mx-lg-16 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-lg-17 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }

  .mx-lg-18 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .mx-lg-19 {
    margin-right: 11rem !important;
    margin-left: 11rem !important;
  }

  .mx-lg-20 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-lg-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-lg-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-lg-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-lg-10 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-lg-11 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-lg-12 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }

  .my-lg-13 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-lg-14 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }

  .my-lg-15 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .my-lg-16 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-lg-17 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .my-lg-18 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-lg-19 {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important;
  }

  .my-lg-20 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 2rem !important;
  }

  .mt-lg-6 {
    margin-top: 2.5rem !important;
  }

  .mt-lg-7 {
    margin-top: 3rem !important;
  }

  .mt-lg-8 {
    margin-top: 3.5rem !important;
  }

  .mt-lg-9 {
    margin-top: 4rem !important;
  }

  .mt-lg-10 {
    margin-top: 4.5rem !important;
  }

  .mt-lg-11 {
    margin-top: 5rem !important;
  }

  .mt-lg-12 {
    margin-top: 5.5rem !important;
  }

  .mt-lg-13 {
    margin-top: 6rem !important;
  }

  .mt-lg-14 {
    margin-top: 6.5rem !important;
  }

  .mt-lg-15 {
    margin-top: 7rem !important;
  }

  .mt-lg-16 {
    margin-top: 8rem !important;
  }

  .mt-lg-17 {
    margin-top: 9rem !important;
  }

  .mt-lg-18 {
    margin-top: 10rem !important;
  }

  .mt-lg-19 {
    margin-top: 11rem !important;
  }

  .mt-lg-20 {
    margin-top: 12rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 2rem !important;
  }

  .me-lg-6 {
    margin-right: 2.5rem !important;
  }

  .me-lg-7 {
    margin-right: 3rem !important;
  }

  .me-lg-8 {
    margin-right: 3.5rem !important;
  }

  .me-lg-9 {
    margin-right: 4rem !important;
  }

  .me-lg-10 {
    margin-right: 4.5rem !important;
  }

  .me-lg-11 {
    margin-right: 5rem !important;
  }

  .me-lg-12 {
    margin-right: 5.5rem !important;
  }

  .me-lg-13 {
    margin-right: 6rem !important;
  }

  .me-lg-14 {
    margin-right: 6.5rem !important;
  }

  .me-lg-15 {
    margin-right: 7rem !important;
  }

  .me-lg-16 {
    margin-right: 8rem !important;
  }

  .me-lg-17 {
    margin-right: 9rem !important;
  }

  .me-lg-18 {
    margin-right: 10rem !important;
  }

  .me-lg-19 {
    margin-right: 11rem !important;
  }

  .me-lg-20 {
    margin-right: 12rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 2rem !important;
  }

  .mb-lg-6 {
    margin-bottom: 2.5rem !important;
  }

  .mb-lg-7 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-8 {
    margin-bottom: 3.5rem !important;
  }

  .mb-lg-9 {
    margin-bottom: 4rem !important;
  }

  .mb-lg-10 {
    margin-bottom: 4.5rem !important;
  }

  .mb-lg-11 {
    margin-bottom: 5rem !important;
  }

  .mb-lg-12 {
    margin-bottom: 5.5rem !important;
  }

  .mb-lg-13 {
    margin-bottom: 6rem !important;
  }

  .mb-lg-14 {
    margin-bottom: 6.5rem !important;
  }

  .mb-lg-15 {
    margin-bottom: 7rem !important;
  }

  .mb-lg-16 {
    margin-bottom: 8rem !important;
  }

  .mb-lg-17 {
    margin-bottom: 9rem !important;
  }

  .mb-lg-18 {
    margin-bottom: 10rem !important;
  }

  .mb-lg-19 {
    margin-bottom: 11rem !important;
  }

  .mb-lg-20 {
    margin-bottom: 12rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 2rem !important;
  }

  .ms-lg-6 {
    margin-left: 2.5rem !important;
  }

  .ms-lg-7 {
    margin-left: 3rem !important;
  }

  .ms-lg-8 {
    margin-left: 3.5rem !important;
  }

  .ms-lg-9 {
    margin-left: 4rem !important;
  }

  .ms-lg-10 {
    margin-left: 4.5rem !important;
  }

  .ms-lg-11 {
    margin-left: 5rem !important;
  }

  .ms-lg-12 {
    margin-left: 5.5rem !important;
  }

  .ms-lg-13 {
    margin-left: 6rem !important;
  }

  .ms-lg-14 {
    margin-left: 6.5rem !important;
  }

  .ms-lg-15 {
    margin-left: 7rem !important;
  }

  .ms-lg-16 {
    margin-left: 8rem !important;
  }

  .ms-lg-17 {
    margin-left: 9rem !important;
  }

  .ms-lg-18 {
    margin-left: 10rem !important;
  }

  .ms-lg-19 {
    margin-left: 11rem !important;
  }

  .ms-lg-20 {
    margin-left: 12rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -2rem !important;
  }

  .m-lg-n6 {
    margin: -2.5rem !important;
  }

  .m-lg-n7 {
    margin: -3rem !important;
  }

  .m-lg-n8 {
    margin: -3.5rem !important;
  }

  .m-lg-n9 {
    margin: -4rem !important;
  }

  .m-lg-n10 {
    margin: -4.5rem !important;
  }

  .m-lg-n11 {
    margin: -5rem !important;
  }

  .m-lg-n12 {
    margin: -5.5rem !important;
  }

  .m-lg-n13 {
    margin: -6rem !important;
  }

  .m-lg-n14 {
    margin: -6.5rem !important;
  }

  .m-lg-n15 {
    margin: -7rem !important;
  }

  .m-lg-n16 {
    margin: -8rem !important;
  }

  .m-lg-n17 {
    margin: -9rem !important;
  }

  .m-lg-n18 {
    margin: -10rem !important;
  }

  .m-lg-n19 {
    margin: -11rem !important;
  }

  .m-lg-n20 {
    margin: -12rem !important;
  }

  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-lg-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-lg-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-lg-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-lg-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-lg-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-lg-n10 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .mx-lg-n11 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .mx-lg-n12 {
    margin-right: -5.5rem !important;
    margin-left: -5.5rem !important;
  }

  .mx-lg-n13 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }

  .mx-lg-n14 {
    margin-right: -6.5rem !important;
    margin-left: -6.5rem !important;
  }

  .mx-lg-n15 {
    margin-right: -7rem !important;
    margin-left: -7rem !important;
  }

  .mx-lg-n16 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }

  .mx-lg-n17 {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }

  .mx-lg-n18 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }

  .mx-lg-n19 {
    margin-right: -11rem !important;
    margin-left: -11rem !important;
  }

  .mx-lg-n20 {
    margin-right: -12rem !important;
    margin-left: -12rem !important;
  }

  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-lg-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-lg-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-lg-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-lg-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-lg-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-lg-n10 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-lg-n11 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .my-lg-n12 {
    margin-top: -5.5rem !important;
    margin-bottom: -5.5rem !important;
  }

  .my-lg-n13 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-lg-n14 {
    margin-top: -6.5rem !important;
    margin-bottom: -6.5rem !important;
  }

  .my-lg-n15 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }

  .my-lg-n16 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-lg-n17 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .my-lg-n18 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .my-lg-n19 {
    margin-top: -11rem !important;
    margin-bottom: -11rem !important;
  }

  .my-lg-n20 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-lg-n3 {
    margin-top: -1rem !important;
  }

  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-lg-n5 {
    margin-top: -2rem !important;
  }

  .mt-lg-n6 {
    margin-top: -2.5rem !important;
  }

  .mt-lg-n7 {
    margin-top: -3rem !important;
  }

  .mt-lg-n8 {
    margin-top: -3.5rem !important;
  }

  .mt-lg-n9 {
    margin-top: -4rem !important;
  }

  .mt-lg-n10 {
    margin-top: -4.5rem !important;
  }

  .mt-lg-n11 {
    margin-top: -5rem !important;
  }

  .mt-lg-n12 {
    margin-top: -5.5rem !important;
  }

  .mt-lg-n13 {
    margin-top: -6rem !important;
  }

  .mt-lg-n14 {
    margin-top: -6.5rem !important;
  }

  .mt-lg-n15 {
    margin-top: -7rem !important;
  }

  .mt-lg-n16 {
    margin-top: -8rem !important;
  }

  .mt-lg-n17 {
    margin-top: -9rem !important;
  }

  .mt-lg-n18 {
    margin-top: -10rem !important;
  }

  .mt-lg-n19 {
    margin-top: -11rem !important;
  }

  .mt-lg-n20 {
    margin-top: -12rem !important;
  }

  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .me-lg-n3 {
    margin-right: -1rem !important;
  }

  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .me-lg-n5 {
    margin-right: -2rem !important;
  }

  .me-lg-n6 {
    margin-right: -2.5rem !important;
  }

  .me-lg-n7 {
    margin-right: -3rem !important;
  }

  .me-lg-n8 {
    margin-right: -3.5rem !important;
  }

  .me-lg-n9 {
    margin-right: -4rem !important;
  }

  .me-lg-n10 {
    margin-right: -4.5rem !important;
  }

  .me-lg-n11 {
    margin-right: -5rem !important;
  }

  .me-lg-n12 {
    margin-right: -5.5rem !important;
  }

  .me-lg-n13 {
    margin-right: -6rem !important;
  }

  .me-lg-n14 {
    margin-right: -6.5rem !important;
  }

  .me-lg-n15 {
    margin-right: -7rem !important;
  }

  .me-lg-n16 {
    margin-right: -8rem !important;
  }

  .me-lg-n17 {
    margin-right: -9rem !important;
  }

  .me-lg-n18 {
    margin-right: -10rem !important;
  }

  .me-lg-n19 {
    margin-right: -11rem !important;
  }

  .me-lg-n20 {
    margin-right: -12rem !important;
  }

  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-lg-n5 {
    margin-bottom: -2rem !important;
  }

  .mb-lg-n6 {
    margin-bottom: -2.5rem !important;
  }

  .mb-lg-n7 {
    margin-bottom: -3rem !important;
  }

  .mb-lg-n8 {
    margin-bottom: -3.5rem !important;
  }

  .mb-lg-n9 {
    margin-bottom: -4rem !important;
  }

  .mb-lg-n10 {
    margin-bottom: -4.5rem !important;
  }

  .mb-lg-n11 {
    margin-bottom: -5rem !important;
  }

  .mb-lg-n12 {
    margin-bottom: -5.5rem !important;
  }

  .mb-lg-n13 {
    margin-bottom: -6rem !important;
  }

  .mb-lg-n14 {
    margin-bottom: -6.5rem !important;
  }

  .mb-lg-n15 {
    margin-bottom: -7rem !important;
  }

  .mb-lg-n16 {
    margin-bottom: -8rem !important;
  }

  .mb-lg-n17 {
    margin-bottom: -9rem !important;
  }

  .mb-lg-n18 {
    margin-bottom: -10rem !important;
  }

  .mb-lg-n19 {
    margin-bottom: -11rem !important;
  }

  .mb-lg-n20 {
    margin-bottom: -12rem !important;
  }

  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-lg-n3 {
    margin-left: -1rem !important;
  }

  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-lg-n5 {
    margin-left: -2rem !important;
  }

  .ms-lg-n6 {
    margin-left: -2.5rem !important;
  }

  .ms-lg-n7 {
    margin-left: -3rem !important;
  }

  .ms-lg-n8 {
    margin-left: -3.5rem !important;
  }

  .ms-lg-n9 {
    margin-left: -4rem !important;
  }

  .ms-lg-n10 {
    margin-left: -4.5rem !important;
  }

  .ms-lg-n11 {
    margin-left: -5rem !important;
  }

  .ms-lg-n12 {
    margin-left: -5.5rem !important;
  }

  .ms-lg-n13 {
    margin-left: -6rem !important;
  }

  .ms-lg-n14 {
    margin-left: -6.5rem !important;
  }

  .ms-lg-n15 {
    margin-left: -7rem !important;
  }

  .ms-lg-n16 {
    margin-left: -8rem !important;
  }

  .ms-lg-n17 {
    margin-left: -9rem !important;
  }

  .ms-lg-n18 {
    margin-left: -10rem !important;
  }

  .ms-lg-n19 {
    margin-left: -11rem !important;
  }

  .ms-lg-n20 {
    margin-left: -12rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 2rem !important;
  }

  .p-lg-6 {
    padding: 2.5rem !important;
  }

  .p-lg-7 {
    padding: 3rem !important;
  }

  .p-lg-8 {
    padding: 3.5rem !important;
  }

  .p-lg-9 {
    padding: 4rem !important;
  }

  .p-lg-10 {
    padding: 4.5rem !important;
  }

  .p-lg-11 {
    padding: 5rem !important;
  }

  .p-lg-12 {
    padding: 5.5rem !important;
  }

  .p-lg-13 {
    padding: 6rem !important;
  }

  .p-lg-14 {
    padding: 6.5rem !important;
  }

  .p-lg-15 {
    padding: 7rem !important;
  }

  .p-lg-16 {
    padding: 8rem !important;
  }

  .p-lg-17 {
    padding: 9rem !important;
  }

  .p-lg-18 {
    padding: 10rem !important;
  }

  .p-lg-19 {
    padding: 11rem !important;
  }

  .p-lg-20 {
    padding: 12rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-lg-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-lg-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-lg-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-lg-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-lg-10 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-lg-11 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .px-lg-12 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important;
  }

  .px-lg-13 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-lg-14 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important;
  }

  .px-lg-15 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }

  .px-lg-16 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .px-lg-17 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }

  .px-lg-18 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .px-lg-19 {
    padding-right: 11rem !important;
    padding-left: 11rem !important;
  }

  .px-lg-20 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-lg-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-lg-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-lg-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-lg-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-lg-10 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-lg-11 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-lg-12 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }

  .py-lg-13 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-lg-14 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }

  .py-lg-15 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .py-lg-16 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-lg-17 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .py-lg-18 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .py-lg-19 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }

  .py-lg-20 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 2rem !important;
  }

  .pt-lg-6 {
    padding-top: 2.5rem !important;
  }

  .pt-lg-7 {
    padding-top: 3rem !important;
  }

  .pt-lg-8 {
    padding-top: 3.5rem !important;
  }

  .pt-lg-9 {
    padding-top: 4rem !important;
  }

  .pt-lg-10 {
    padding-top: 4.5rem !important;
  }

  .pt-lg-11 {
    padding-top: 5rem !important;
  }

  .pt-lg-12 {
    padding-top: 5.5rem !important;
  }

  .pt-lg-13 {
    padding-top: 6rem !important;
  }

  .pt-lg-14 {
    padding-top: 6.5rem !important;
  }

  .pt-lg-15 {
    padding-top: 7rem !important;
  }

  .pt-lg-16 {
    padding-top: 8rem !important;
  }

  .pt-lg-17 {
    padding-top: 9rem !important;
  }

  .pt-lg-18 {
    padding-top: 10rem !important;
  }

  .pt-lg-19 {
    padding-top: 11rem !important;
  }

  .pt-lg-20 {
    padding-top: 12rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 2rem !important;
  }

  .pe-lg-6 {
    padding-right: 2.5rem !important;
  }

  .pe-lg-7 {
    padding-right: 3rem !important;
  }

  .pe-lg-8 {
    padding-right: 3.5rem !important;
  }

  .pe-lg-9 {
    padding-right: 4rem !important;
  }

  .pe-lg-10 {
    padding-right: 4.5rem !important;
  }

  .pe-lg-11 {
    padding-right: 5rem !important;
  }

  .pe-lg-12 {
    padding-right: 5.5rem !important;
  }

  .pe-lg-13 {
    padding-right: 6rem !important;
  }

  .pe-lg-14 {
    padding-right: 6.5rem !important;
  }

  .pe-lg-15 {
    padding-right: 7rem !important;
  }

  .pe-lg-16 {
    padding-right: 8rem !important;
  }

  .pe-lg-17 {
    padding-right: 9rem !important;
  }

  .pe-lg-18 {
    padding-right: 10rem !important;
  }

  .pe-lg-19 {
    padding-right: 11rem !important;
  }

  .pe-lg-20 {
    padding-right: 12rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 2rem !important;
  }

  .pb-lg-6 {
    padding-bottom: 2.5rem !important;
  }

  .pb-lg-7 {
    padding-bottom: 3rem !important;
  }

  .pb-lg-8 {
    padding-bottom: 3.5rem !important;
  }

  .pb-lg-9 {
    padding-bottom: 4rem !important;
  }

  .pb-lg-10 {
    padding-bottom: 4.5rem !important;
  }

  .pb-lg-11 {
    padding-bottom: 5rem !important;
  }

  .pb-lg-12 {
    padding-bottom: 5.5rem !important;
  }

  .pb-lg-13 {
    padding-bottom: 6rem !important;
  }

  .pb-lg-14 {
    padding-bottom: 6.5rem !important;
  }

  .pb-lg-15 {
    padding-bottom: 7rem !important;
  }

  .pb-lg-16 {
    padding-bottom: 8rem !important;
  }

  .pb-lg-17 {
    padding-bottom: 9rem !important;
  }

  .pb-lg-18 {
    padding-bottom: 10rem !important;
  }

  .pb-lg-19 {
    padding-bottom: 11rem !important;
  }

  .pb-lg-20 {
    padding-bottom: 12rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 2rem !important;
  }

  .ps-lg-6 {
    padding-left: 2.5rem !important;
  }

  .ps-lg-7 {
    padding-left: 3rem !important;
  }

  .ps-lg-8 {
    padding-left: 3.5rem !important;
  }

  .ps-lg-9 {
    padding-left: 4rem !important;
  }

  .ps-lg-10 {
    padding-left: 4.5rem !important;
  }

  .ps-lg-11 {
    padding-left: 5rem !important;
  }

  .ps-lg-12 {
    padding-left: 5.5rem !important;
  }

  .ps-lg-13 {
    padding-left: 6rem !important;
  }

  .ps-lg-14 {
    padding-left: 6.5rem !important;
  }

  .ps-lg-15 {
    padding-left: 7rem !important;
  }

  .ps-lg-16 {
    padding-left: 8rem !important;
  }

  .ps-lg-17 {
    padding-left: 9rem !important;
  }

  .ps-lg-18 {
    padding-left: 10rem !important;
  }

  .ps-lg-19 {
    padding-left: 11rem !important;
  }

  .ps-lg-20 {
    padding-left: 12rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }

  .rounded-lg-0 {
    border-radius: 0 !important;
  }

  .rounded-lg {
    border-radius: 0.375rem !important;
  }

  .rounded-lg-1 {
    border-radius: 0.25rem !important;
  }

  .rounded-lg-2 {
    border-radius: 0.375rem !important;
  }

  .rounded-lg-3 {
    border-radius: 0.575rem !important;
  }

  .rounded-lg-4 {
    border-radius: 1.5em !important;
  }

  .rounded-lg-pill {
    border-radius: 50rem !important;
  }

  .rounded-lg-block {
    border-radius: 3rem !important;
  }

  .rounded-lg-blob {
    border-radius: 30% 70% 70% 30%/30% 30% 70% 70% !important;
  }

  .rounded-lg-circle {
    border-radius: 50% !important;
  }

  .rounded-top-lg-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .rounded-top-lg {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }

  .rounded-top-lg-1 {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }

  .rounded-top-lg-2 {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }

  .rounded-top-lg-3 {
    border-top-left-radius: 0.575rem !important;
    border-top-right-radius: 0.575rem !important;
  }

  .rounded-top-lg-4 {
    border-top-left-radius: 1.5em !important;
    border-top-right-radius: 1.5em !important;
  }

  .rounded-top-lg-pill {
    border-top-left-radius: 50rem !important;
    border-top-right-radius: 50rem !important;
  }

  .rounded-top-lg-block {
    border-top-left-radius: 3rem !important;
    border-top-right-radius: 3rem !important;
  }

  .rounded-top-lg-circle {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }

  .rounded-end-lg-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .rounded-end-lg {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .rounded-end-lg-1 {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  .rounded-end-lg-2 {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .rounded-end-lg-3 {
    border-top-right-radius: 0.575rem !important;
    border-bottom-right-radius: 0.575rem !important;
  }

  .rounded-end-lg-4 {
    border-top-right-radius: 1.5em !important;
    border-bottom-right-radius: 1.5em !important;
  }

  .rounded-end-lg-pill {
    border-top-right-radius: 50rem !important;
    border-bottom-right-radius: 50rem !important;
  }

  .rounded-end-lg-block {
    border-top-right-radius: 3rem !important;
    border-bottom-right-radius: 3rem !important;
  }

  .rounded-end-lg-circle {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }

  .rounded-bottom-lg-0 {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .rounded-bottom-lg {
    border-bottom-left-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .rounded-bottom-lg-1 {
    border-bottom-left-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  .rounded-bottom-lg-2 {
    border-bottom-left-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .rounded-bottom-lg-3 {
    border-bottom-left-radius: 0.575rem !important;
    border-bottom-right-radius: 0.575rem !important;
  }

  .rounded-bottom-lg-4 {
    border-bottom-left-radius: 1.5em !important;
    border-bottom-right-radius: 1.5em !important;
  }

  .rounded-bottom-lg-pill {
    border-bottom-left-radius: 50rem !important;
    border-bottom-right-radius: 50rem !important;
  }

  .rounded-bottom-lg-block {
    border-bottom-left-radius: 3rem !important;
    border-bottom-right-radius: 3rem !important;
  }

  .rounded-bottom-lg-circle {
    border-bottom-left-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }

  .rounded-start-lg-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .rounded-start-lg {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .rounded-start-lg-1 {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .rounded-start-lg-2 {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .rounded-start-lg-3 {
    border-top-left-radius: 0.575rem !important;
    border-bottom-left-radius: 0.575rem !important;
  }

  .rounded-start-lg-4 {
    border-top-left-radius: 1.5em !important;
    border-bottom-left-radius: 1.5em !important;
  }

  .rounded-start-lg-pill {
    border-top-left-radius: 50rem !important;
    border-bottom-left-radius: 50rem !important;
  }

  .rounded-start-lg-block {
    border-top-left-radius: 3rem !important;
    border-bottom-left-radius: 3rem !important;
  }

  .rounded-start-lg-circle {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .position-xl-static {
    position: static !important;
  }

  .position-xl-relative {
    position: relative !important;
  }

  .position-xl-absolute {
    position: absolute !important;
  }

  .position-xl-fixed {
    position: fixed !important;
  }

  .position-xl-sticky {
    position: sticky !important;
  }

  .border-xl {
    border: 1px solid #F0F5FA !important;
  }

  .border-xl-0 {
    border: 0 !important;
  }

  .border-top-xl {
    border-top: 1px solid #F0F5FA !important;
  }

  .border-top-xl-0 {
    border-top: 0 !important;
  }

  .border-end-xl {
    border-right: 1px solid #F0F5FA !important;
  }

  .border-end-xl-0 {
    border-right: 0 !important;
  }

  .border-start-xl {
    border-left: 1px solid #F0F5FA !important;
  }

  .border-start-xl-0 {
    border-left: 0 !important;
  }

  .w-xl-0 {
    width: 0% !important;
  }

  .w-xl-10 {
    width: 10% !important;
  }

  .w-xl-20 {
    width: 20% !important;
  }

  .w-xl-25 {
    width: 25% !important;
  }

  .w-xl-30 {
    width: 30% !important;
  }

  .w-xl-40 {
    width: 40% !important;
  }

  .w-xl-50 {
    width: 50% !important;
  }

  .w-xl-60 {
    width: 60% !important;
  }

  .w-xl-70 {
    width: 70% !important;
  }

  .w-xl-75 {
    width: 75% !important;
  }

  .w-xl-80 {
    width: 80% !important;
  }

  .w-xl-90 {
    width: 90% !important;
  }

  .w-xl-100 {
    width: 100% !important;
  }

  .w-xl-auto {
    width: auto !important;
  }

  .h-xl-0 {
    height: 0% !important;
  }

  .h-xl-10 {
    height: 10% !important;
  }

  .h-xl-20 {
    height: 20% !important;
  }

  .h-xl-25 {
    height: 25% !important;
  }

  .h-xl-30 {
    height: 30% !important;
  }

  .h-xl-40 {
    height: 40% !important;
  }

  .h-xl-50 {
    height: 50% !important;
  }

  .h-xl-60 {
    height: 60% !important;
  }

  .h-xl-70 {
    height: 70% !important;
  }

  .h-xl-75 {
    height: 75% !important;
  }

  .h-xl-80 {
    height: 80% !important;
  }

  .h-xl-90 {
    height: 90% !important;
  }

  .h-xl-100 {
    height: 100% !important;
  }

  .h-xl-auto {
    height: auto !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 2rem !important;
  }

  .gap-xl-6 {
    gap: 2.5rem !important;
  }

  .gap-xl-7 {
    gap: 3rem !important;
  }

  .gap-xl-8 {
    gap: 3.5rem !important;
  }

  .gap-xl-9 {
    gap: 4rem !important;
  }

  .gap-xl-10 {
    gap: 4.5rem !important;
  }

  .gap-xl-11 {
    gap: 5rem !important;
  }

  .gap-xl-12 {
    gap: 5.5rem !important;
  }

  .gap-xl-13 {
    gap: 6rem !important;
  }

  .gap-xl-14 {
    gap: 6.5rem !important;
  }

  .gap-xl-15 {
    gap: 7rem !important;
  }

  .gap-xl-16 {
    gap: 8rem !important;
  }

  .gap-xl-17 {
    gap: 9rem !important;
  }

  .gap-xl-18 {
    gap: 10rem !important;
  }

  .gap-xl-19 {
    gap: 11rem !important;
  }

  .gap-xl-20 {
    gap: 12rem !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 2rem !important;
  }

  .m-xl-6 {
    margin: 2.5rem !important;
  }

  .m-xl-7 {
    margin: 3rem !important;
  }

  .m-xl-8 {
    margin: 3.5rem !important;
  }

  .m-xl-9 {
    margin: 4rem !important;
  }

  .m-xl-10 {
    margin: 4.5rem !important;
  }

  .m-xl-11 {
    margin: 5rem !important;
  }

  .m-xl-12 {
    margin: 5.5rem !important;
  }

  .m-xl-13 {
    margin: 6rem !important;
  }

  .m-xl-14 {
    margin: 6.5rem !important;
  }

  .m-xl-15 {
    margin: 7rem !important;
  }

  .m-xl-16 {
    margin: 8rem !important;
  }

  .m-xl-17 {
    margin: 9rem !important;
  }

  .m-xl-18 {
    margin: 10rem !important;
  }

  .m-xl-19 {
    margin: 11rem !important;
  }

  .m-xl-20 {
    margin: 12rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-xl-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-xl-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-xl-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xl-10 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-xl-11 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-xl-12 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important;
  }

  .mx-xl-13 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-xl-14 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important;
  }

  .mx-xl-15 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }

  .mx-xl-16 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-xl-17 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }

  .mx-xl-18 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .mx-xl-19 {
    margin-right: 11rem !important;
    margin-left: 11rem !important;
  }

  .mx-xl-20 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xl-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xl-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-xl-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xl-10 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-xl-11 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-xl-12 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }

  .my-xl-13 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-xl-14 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }

  .my-xl-15 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .my-xl-16 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-xl-17 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .my-xl-18 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-xl-19 {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important;
  }

  .my-xl-20 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 2rem !important;
  }

  .mt-xl-6 {
    margin-top: 2.5rem !important;
  }

  .mt-xl-7 {
    margin-top: 3rem !important;
  }

  .mt-xl-8 {
    margin-top: 3.5rem !important;
  }

  .mt-xl-9 {
    margin-top: 4rem !important;
  }

  .mt-xl-10 {
    margin-top: 4.5rem !important;
  }

  .mt-xl-11 {
    margin-top: 5rem !important;
  }

  .mt-xl-12 {
    margin-top: 5.5rem !important;
  }

  .mt-xl-13 {
    margin-top: 6rem !important;
  }

  .mt-xl-14 {
    margin-top: 6.5rem !important;
  }

  .mt-xl-15 {
    margin-top: 7rem !important;
  }

  .mt-xl-16 {
    margin-top: 8rem !important;
  }

  .mt-xl-17 {
    margin-top: 9rem !important;
  }

  .mt-xl-18 {
    margin-top: 10rem !important;
  }

  .mt-xl-19 {
    margin-top: 11rem !important;
  }

  .mt-xl-20 {
    margin-top: 12rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 2rem !important;
  }

  .me-xl-6 {
    margin-right: 2.5rem !important;
  }

  .me-xl-7 {
    margin-right: 3rem !important;
  }

  .me-xl-8 {
    margin-right: 3.5rem !important;
  }

  .me-xl-9 {
    margin-right: 4rem !important;
  }

  .me-xl-10 {
    margin-right: 4.5rem !important;
  }

  .me-xl-11 {
    margin-right: 5rem !important;
  }

  .me-xl-12 {
    margin-right: 5.5rem !important;
  }

  .me-xl-13 {
    margin-right: 6rem !important;
  }

  .me-xl-14 {
    margin-right: 6.5rem !important;
  }

  .me-xl-15 {
    margin-right: 7rem !important;
  }

  .me-xl-16 {
    margin-right: 8rem !important;
  }

  .me-xl-17 {
    margin-right: 9rem !important;
  }

  .me-xl-18 {
    margin-right: 10rem !important;
  }

  .me-xl-19 {
    margin-right: 11rem !important;
  }

  .me-xl-20 {
    margin-right: 12rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 2rem !important;
  }

  .mb-xl-6 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xl-7 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-8 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xl-9 {
    margin-bottom: 4rem !important;
  }

  .mb-xl-10 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xl-11 {
    margin-bottom: 5rem !important;
  }

  .mb-xl-12 {
    margin-bottom: 5.5rem !important;
  }

  .mb-xl-13 {
    margin-bottom: 6rem !important;
  }

  .mb-xl-14 {
    margin-bottom: 6.5rem !important;
  }

  .mb-xl-15 {
    margin-bottom: 7rem !important;
  }

  .mb-xl-16 {
    margin-bottom: 8rem !important;
  }

  .mb-xl-17 {
    margin-bottom: 9rem !important;
  }

  .mb-xl-18 {
    margin-bottom: 10rem !important;
  }

  .mb-xl-19 {
    margin-bottom: 11rem !important;
  }

  .mb-xl-20 {
    margin-bottom: 12rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 2rem !important;
  }

  .ms-xl-6 {
    margin-left: 2.5rem !important;
  }

  .ms-xl-7 {
    margin-left: 3rem !important;
  }

  .ms-xl-8 {
    margin-left: 3.5rem !important;
  }

  .ms-xl-9 {
    margin-left: 4rem !important;
  }

  .ms-xl-10 {
    margin-left: 4.5rem !important;
  }

  .ms-xl-11 {
    margin-left: 5rem !important;
  }

  .ms-xl-12 {
    margin-left: 5.5rem !important;
  }

  .ms-xl-13 {
    margin-left: 6rem !important;
  }

  .ms-xl-14 {
    margin-left: 6.5rem !important;
  }

  .ms-xl-15 {
    margin-left: 7rem !important;
  }

  .ms-xl-16 {
    margin-left: 8rem !important;
  }

  .ms-xl-17 {
    margin-left: 9rem !important;
  }

  .ms-xl-18 {
    margin-left: 10rem !important;
  }

  .ms-xl-19 {
    margin-left: 11rem !important;
  }

  .ms-xl-20 {
    margin-left: 12rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -2rem !important;
  }

  .m-xl-n6 {
    margin: -2.5rem !important;
  }

  .m-xl-n7 {
    margin: -3rem !important;
  }

  .m-xl-n8 {
    margin: -3.5rem !important;
  }

  .m-xl-n9 {
    margin: -4rem !important;
  }

  .m-xl-n10 {
    margin: -4.5rem !important;
  }

  .m-xl-n11 {
    margin: -5rem !important;
  }

  .m-xl-n12 {
    margin: -5.5rem !important;
  }

  .m-xl-n13 {
    margin: -6rem !important;
  }

  .m-xl-n14 {
    margin: -6.5rem !important;
  }

  .m-xl-n15 {
    margin: -7rem !important;
  }

  .m-xl-n16 {
    margin: -8rem !important;
  }

  .m-xl-n17 {
    margin: -9rem !important;
  }

  .m-xl-n18 {
    margin: -10rem !important;
  }

  .m-xl-n19 {
    margin: -11rem !important;
  }

  .m-xl-n20 {
    margin: -12rem !important;
  }

  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xl-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-xl-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-xl-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-xl-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-xl-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-xl-n10 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .mx-xl-n11 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .mx-xl-n12 {
    margin-right: -5.5rem !important;
    margin-left: -5.5rem !important;
  }

  .mx-xl-n13 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }

  .mx-xl-n14 {
    margin-right: -6.5rem !important;
    margin-left: -6.5rem !important;
  }

  .mx-xl-n15 {
    margin-right: -7rem !important;
    margin-left: -7rem !important;
  }

  .mx-xl-n16 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }

  .mx-xl-n17 {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }

  .mx-xl-n18 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }

  .mx-xl-n19 {
    margin-right: -11rem !important;
    margin-left: -11rem !important;
  }

  .mx-xl-n20 {
    margin-right: -12rem !important;
    margin-left: -12rem !important;
  }

  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xl-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-xl-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-xl-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-xl-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-xl-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-xl-n10 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-xl-n11 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .my-xl-n12 {
    margin-top: -5.5rem !important;
    margin-bottom: -5.5rem !important;
  }

  .my-xl-n13 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-xl-n14 {
    margin-top: -6.5rem !important;
    margin-bottom: -6.5rem !important;
  }

  .my-xl-n15 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }

  .my-xl-n16 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-xl-n17 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .my-xl-n18 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .my-xl-n19 {
    margin-top: -11rem !important;
    margin-bottom: -11rem !important;
  }

  .my-xl-n20 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xl-n5 {
    margin-top: -2rem !important;
  }

  .mt-xl-n6 {
    margin-top: -2.5rem !important;
  }

  .mt-xl-n7 {
    margin-top: -3rem !important;
  }

  .mt-xl-n8 {
    margin-top: -3.5rem !important;
  }

  .mt-xl-n9 {
    margin-top: -4rem !important;
  }

  .mt-xl-n10 {
    margin-top: -4.5rem !important;
  }

  .mt-xl-n11 {
    margin-top: -5rem !important;
  }

  .mt-xl-n12 {
    margin-top: -5.5rem !important;
  }

  .mt-xl-n13 {
    margin-top: -6rem !important;
  }

  .mt-xl-n14 {
    margin-top: -6.5rem !important;
  }

  .mt-xl-n15 {
    margin-top: -7rem !important;
  }

  .mt-xl-n16 {
    margin-top: -8rem !important;
  }

  .mt-xl-n17 {
    margin-top: -9rem !important;
  }

  .mt-xl-n18 {
    margin-top: -10rem !important;
  }

  .mt-xl-n19 {
    margin-top: -11rem !important;
  }

  .mt-xl-n20 {
    margin-top: -12rem !important;
  }

  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-xl-n3 {
    margin-right: -1rem !important;
  }

  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xl-n5 {
    margin-right: -2rem !important;
  }

  .me-xl-n6 {
    margin-right: -2.5rem !important;
  }

  .me-xl-n7 {
    margin-right: -3rem !important;
  }

  .me-xl-n8 {
    margin-right: -3.5rem !important;
  }

  .me-xl-n9 {
    margin-right: -4rem !important;
  }

  .me-xl-n10 {
    margin-right: -4.5rem !important;
  }

  .me-xl-n11 {
    margin-right: -5rem !important;
  }

  .me-xl-n12 {
    margin-right: -5.5rem !important;
  }

  .me-xl-n13 {
    margin-right: -6rem !important;
  }

  .me-xl-n14 {
    margin-right: -6.5rem !important;
  }

  .me-xl-n15 {
    margin-right: -7rem !important;
  }

  .me-xl-n16 {
    margin-right: -8rem !important;
  }

  .me-xl-n17 {
    margin-right: -9rem !important;
  }

  .me-xl-n18 {
    margin-right: -10rem !important;
  }

  .me-xl-n19 {
    margin-right: -11rem !important;
  }

  .me-xl-n20 {
    margin-right: -12rem !important;
  }

  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xl-n5 {
    margin-bottom: -2rem !important;
  }

  .mb-xl-n6 {
    margin-bottom: -2.5rem !important;
  }

  .mb-xl-n7 {
    margin-bottom: -3rem !important;
  }

  .mb-xl-n8 {
    margin-bottom: -3.5rem !important;
  }

  .mb-xl-n9 {
    margin-bottom: -4rem !important;
  }

  .mb-xl-n10 {
    margin-bottom: -4.5rem !important;
  }

  .mb-xl-n11 {
    margin-bottom: -5rem !important;
  }

  .mb-xl-n12 {
    margin-bottom: -5.5rem !important;
  }

  .mb-xl-n13 {
    margin-bottom: -6rem !important;
  }

  .mb-xl-n14 {
    margin-bottom: -6.5rem !important;
  }

  .mb-xl-n15 {
    margin-bottom: -7rem !important;
  }

  .mb-xl-n16 {
    margin-bottom: -8rem !important;
  }

  .mb-xl-n17 {
    margin-bottom: -9rem !important;
  }

  .mb-xl-n18 {
    margin-bottom: -10rem !important;
  }

  .mb-xl-n19 {
    margin-bottom: -11rem !important;
  }

  .mb-xl-n20 {
    margin-bottom: -12rem !important;
  }

  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-xl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xl-n5 {
    margin-left: -2rem !important;
  }

  .ms-xl-n6 {
    margin-left: -2.5rem !important;
  }

  .ms-xl-n7 {
    margin-left: -3rem !important;
  }

  .ms-xl-n8 {
    margin-left: -3.5rem !important;
  }

  .ms-xl-n9 {
    margin-left: -4rem !important;
  }

  .ms-xl-n10 {
    margin-left: -4.5rem !important;
  }

  .ms-xl-n11 {
    margin-left: -5rem !important;
  }

  .ms-xl-n12 {
    margin-left: -5.5rem !important;
  }

  .ms-xl-n13 {
    margin-left: -6rem !important;
  }

  .ms-xl-n14 {
    margin-left: -6.5rem !important;
  }

  .ms-xl-n15 {
    margin-left: -7rem !important;
  }

  .ms-xl-n16 {
    margin-left: -8rem !important;
  }

  .ms-xl-n17 {
    margin-left: -9rem !important;
  }

  .ms-xl-n18 {
    margin-left: -10rem !important;
  }

  .ms-xl-n19 {
    margin-left: -11rem !important;
  }

  .ms-xl-n20 {
    margin-left: -12rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 2rem !important;
  }

  .p-xl-6 {
    padding: 2.5rem !important;
  }

  .p-xl-7 {
    padding: 3rem !important;
  }

  .p-xl-8 {
    padding: 3.5rem !important;
  }

  .p-xl-9 {
    padding: 4rem !important;
  }

  .p-xl-10 {
    padding: 4.5rem !important;
  }

  .p-xl-11 {
    padding: 5rem !important;
  }

  .p-xl-12 {
    padding: 5.5rem !important;
  }

  .p-xl-13 {
    padding: 6rem !important;
  }

  .p-xl-14 {
    padding: 6.5rem !important;
  }

  .p-xl-15 {
    padding: 7rem !important;
  }

  .p-xl-16 {
    padding: 8rem !important;
  }

  .p-xl-17 {
    padding: 9rem !important;
  }

  .p-xl-18 {
    padding: 10rem !important;
  }

  .p-xl-19 {
    padding: 11rem !important;
  }

  .p-xl-20 {
    padding: 12rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-xl-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-xl-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xl-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-xl-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-xl-10 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-xl-11 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .px-xl-12 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important;
  }

  .px-xl-13 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-xl-14 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important;
  }

  .px-xl-15 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }

  .px-xl-16 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .px-xl-17 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }

  .px-xl-18 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .px-xl-19 {
    padding-right: 11rem !important;
    padding-left: 11rem !important;
  }

  .px-xl-20 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xl-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-xl-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xl-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-xl-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xl-10 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-xl-11 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-xl-12 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }

  .py-xl-13 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-xl-14 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }

  .py-xl-15 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .py-xl-16 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-xl-17 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .py-xl-18 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .py-xl-19 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }

  .py-xl-20 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 2rem !important;
  }

  .pt-xl-6 {
    padding-top: 2.5rem !important;
  }

  .pt-xl-7 {
    padding-top: 3rem !important;
  }

  .pt-xl-8 {
    padding-top: 3.5rem !important;
  }

  .pt-xl-9 {
    padding-top: 4rem !important;
  }

  .pt-xl-10 {
    padding-top: 4.5rem !important;
  }

  .pt-xl-11 {
    padding-top: 5rem !important;
  }

  .pt-xl-12 {
    padding-top: 5.5rem !important;
  }

  .pt-xl-13 {
    padding-top: 6rem !important;
  }

  .pt-xl-14 {
    padding-top: 6.5rem !important;
  }

  .pt-xl-15 {
    padding-top: 7rem !important;
  }

  .pt-xl-16 {
    padding-top: 8rem !important;
  }

  .pt-xl-17 {
    padding-top: 9rem !important;
  }

  .pt-xl-18 {
    padding-top: 10rem !important;
  }

  .pt-xl-19 {
    padding-top: 11rem !important;
  }

  .pt-xl-20 {
    padding-top: 12rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 2rem !important;
  }

  .pe-xl-6 {
    padding-right: 2.5rem !important;
  }

  .pe-xl-7 {
    padding-right: 3rem !important;
  }

  .pe-xl-8 {
    padding-right: 3.5rem !important;
  }

  .pe-xl-9 {
    padding-right: 4rem !important;
  }

  .pe-xl-10 {
    padding-right: 4.5rem !important;
  }

  .pe-xl-11 {
    padding-right: 5rem !important;
  }

  .pe-xl-12 {
    padding-right: 5.5rem !important;
  }

  .pe-xl-13 {
    padding-right: 6rem !important;
  }

  .pe-xl-14 {
    padding-right: 6.5rem !important;
  }

  .pe-xl-15 {
    padding-right: 7rem !important;
  }

  .pe-xl-16 {
    padding-right: 8rem !important;
  }

  .pe-xl-17 {
    padding-right: 9rem !important;
  }

  .pe-xl-18 {
    padding-right: 10rem !important;
  }

  .pe-xl-19 {
    padding-right: 11rem !important;
  }

  .pe-xl-20 {
    padding-right: 12rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 2rem !important;
  }

  .pb-xl-6 {
    padding-bottom: 2.5rem !important;
  }

  .pb-xl-7 {
    padding-bottom: 3rem !important;
  }

  .pb-xl-8 {
    padding-bottom: 3.5rem !important;
  }

  .pb-xl-9 {
    padding-bottom: 4rem !important;
  }

  .pb-xl-10 {
    padding-bottom: 4.5rem !important;
  }

  .pb-xl-11 {
    padding-bottom: 5rem !important;
  }

  .pb-xl-12 {
    padding-bottom: 5.5rem !important;
  }

  .pb-xl-13 {
    padding-bottom: 6rem !important;
  }

  .pb-xl-14 {
    padding-bottom: 6.5rem !important;
  }

  .pb-xl-15 {
    padding-bottom: 7rem !important;
  }

  .pb-xl-16 {
    padding-bottom: 8rem !important;
  }

  .pb-xl-17 {
    padding-bottom: 9rem !important;
  }

  .pb-xl-18 {
    padding-bottom: 10rem !important;
  }

  .pb-xl-19 {
    padding-bottom: 11rem !important;
  }

  .pb-xl-20 {
    padding-bottom: 12rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 2rem !important;
  }

  .ps-xl-6 {
    padding-left: 2.5rem !important;
  }

  .ps-xl-7 {
    padding-left: 3rem !important;
  }

  .ps-xl-8 {
    padding-left: 3.5rem !important;
  }

  .ps-xl-9 {
    padding-left: 4rem !important;
  }

  .ps-xl-10 {
    padding-left: 4.5rem !important;
  }

  .ps-xl-11 {
    padding-left: 5rem !important;
  }

  .ps-xl-12 {
    padding-left: 5.5rem !important;
  }

  .ps-xl-13 {
    padding-left: 6rem !important;
  }

  .ps-xl-14 {
    padding-left: 6.5rem !important;
  }

  .ps-xl-15 {
    padding-left: 7rem !important;
  }

  .ps-xl-16 {
    padding-left: 8rem !important;
  }

  .ps-xl-17 {
    padding-left: 9rem !important;
  }

  .ps-xl-18 {
    padding-left: 10rem !important;
  }

  .ps-xl-19 {
    padding-left: 11rem !important;
  }

  .ps-xl-20 {
    padding-left: 12rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }

  .rounded-xl-0 {
    border-radius: 0 !important;
  }

  .rounded-xl {
    border-radius: 0.375rem !important;
  }

  .rounded-xl-1 {
    border-radius: 0.25rem !important;
  }

  .rounded-xl-2 {
    border-radius: 0.375rem !important;
  }

  .rounded-xl-3 {
    border-radius: 0.575rem !important;
  }

  .rounded-xl-4 {
    border-radius: 1.5em !important;
  }

  .rounded-xl-pill {
    border-radius: 50rem !important;
  }

  .rounded-xl-block {
    border-radius: 3rem !important;
  }

  .rounded-xl-blob {
    border-radius: 30% 70% 70% 30%/30% 30% 70% 70% !important;
  }

  .rounded-xl-circle {
    border-radius: 50% !important;
  }

  .rounded-top-xl-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .rounded-top-xl {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }

  .rounded-top-xl-1 {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }

  .rounded-top-xl-2 {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }

  .rounded-top-xl-3 {
    border-top-left-radius: 0.575rem !important;
    border-top-right-radius: 0.575rem !important;
  }

  .rounded-top-xl-4 {
    border-top-left-radius: 1.5em !important;
    border-top-right-radius: 1.5em !important;
  }

  .rounded-top-xl-pill {
    border-top-left-radius: 50rem !important;
    border-top-right-radius: 50rem !important;
  }

  .rounded-top-xl-block {
    border-top-left-radius: 3rem !important;
    border-top-right-radius: 3rem !important;
  }

  .rounded-top-xl-circle {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }

  .rounded-end-xl-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .rounded-end-xl {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .rounded-end-xl-1 {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  .rounded-end-xl-2 {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .rounded-end-xl-3 {
    border-top-right-radius: 0.575rem !important;
    border-bottom-right-radius: 0.575rem !important;
  }

  .rounded-end-xl-4 {
    border-top-right-radius: 1.5em !important;
    border-bottom-right-radius: 1.5em !important;
  }

  .rounded-end-xl-pill {
    border-top-right-radius: 50rem !important;
    border-bottom-right-radius: 50rem !important;
  }

  .rounded-end-xl-block {
    border-top-right-radius: 3rem !important;
    border-bottom-right-radius: 3rem !important;
  }

  .rounded-end-xl-circle {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }

  .rounded-bottom-xl-0 {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .rounded-bottom-xl {
    border-bottom-left-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .rounded-bottom-xl-1 {
    border-bottom-left-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  .rounded-bottom-xl-2 {
    border-bottom-left-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .rounded-bottom-xl-3 {
    border-bottom-left-radius: 0.575rem !important;
    border-bottom-right-radius: 0.575rem !important;
  }

  .rounded-bottom-xl-4 {
    border-bottom-left-radius: 1.5em !important;
    border-bottom-right-radius: 1.5em !important;
  }

  .rounded-bottom-xl-pill {
    border-bottom-left-radius: 50rem !important;
    border-bottom-right-radius: 50rem !important;
  }

  .rounded-bottom-xl-block {
    border-bottom-left-radius: 3rem !important;
    border-bottom-right-radius: 3rem !important;
  }

  .rounded-bottom-xl-circle {
    border-bottom-left-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }

  .rounded-start-xl-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .rounded-start-xl {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .rounded-start-xl-1 {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .rounded-start-xl-2 {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .rounded-start-xl-3 {
    border-top-left-radius: 0.575rem !important;
    border-bottom-left-radius: 0.575rem !important;
  }

  .rounded-start-xl-4 {
    border-top-left-radius: 1.5em !important;
    border-bottom-left-radius: 1.5em !important;
  }

  .rounded-start-xl-pill {
    border-top-left-radius: 50rem !important;
    border-bottom-left-radius: 50rem !important;
  }

  .rounded-start-xl-block {
    border-top-left-radius: 3rem !important;
    border-bottom-left-radius: 3rem !important;
  }

  .rounded-start-xl-circle {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .position-xxl-static {
    position: static !important;
  }

  .position-xxl-relative {
    position: relative !important;
  }

  .position-xxl-absolute {
    position: absolute !important;
  }

  .position-xxl-fixed {
    position: fixed !important;
  }

  .position-xxl-sticky {
    position: sticky !important;
  }

  .border-xxl {
    border: 1px solid #F0F5FA !important;
  }

  .border-xxl-0 {
    border: 0 !important;
  }

  .border-top-xxl {
    border-top: 1px solid #F0F5FA !important;
  }

  .border-top-xxl-0 {
    border-top: 0 !important;
  }

  .border-end-xxl {
    border-right: 1px solid #F0F5FA !important;
  }

  .border-end-xxl-0 {
    border-right: 0 !important;
  }

  .border-start-xxl {
    border-left: 1px solid #F0F5FA !important;
  }

  .border-start-xxl-0 {
    border-left: 0 !important;
  }

  .w-xxl-0 {
    width: 0% !important;
  }

  .w-xxl-10 {
    width: 10% !important;
  }

  .w-xxl-20 {
    width: 20% !important;
  }

  .w-xxl-25 {
    width: 25% !important;
  }

  .w-xxl-30 {
    width: 30% !important;
  }

  .w-xxl-40 {
    width: 40% !important;
  }

  .w-xxl-50 {
    width: 50% !important;
  }

  .w-xxl-60 {
    width: 60% !important;
  }

  .w-xxl-70 {
    width: 70% !important;
  }

  .w-xxl-75 {
    width: 75% !important;
  }

  .w-xxl-80 {
    width: 80% !important;
  }

  .w-xxl-90 {
    width: 90% !important;
  }

  .w-xxl-100 {
    width: 100% !important;
  }

  .w-xxl-auto {
    width: auto !important;
  }

  .h-xxl-0 {
    height: 0% !important;
  }

  .h-xxl-10 {
    height: 10% !important;
  }

  .h-xxl-20 {
    height: 20% !important;
  }

  .h-xxl-25 {
    height: 25% !important;
  }

  .h-xxl-30 {
    height: 30% !important;
  }

  .h-xxl-40 {
    height: 40% !important;
  }

  .h-xxl-50 {
    height: 50% !important;
  }

  .h-xxl-60 {
    height: 60% !important;
  }

  .h-xxl-70 {
    height: 70% !important;
  }

  .h-xxl-75 {
    height: 75% !important;
  }

  .h-xxl-80 {
    height: 80% !important;
  }

  .h-xxl-90 {
    height: 90% !important;
  }

  .h-xxl-100 {
    height: 100% !important;
  }

  .h-xxl-auto {
    height: auto !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 2rem !important;
  }

  .gap-xxl-6 {
    gap: 2.5rem !important;
  }

  .gap-xxl-7 {
    gap: 3rem !important;
  }

  .gap-xxl-8 {
    gap: 3.5rem !important;
  }

  .gap-xxl-9 {
    gap: 4rem !important;
  }

  .gap-xxl-10 {
    gap: 4.5rem !important;
  }

  .gap-xxl-11 {
    gap: 5rem !important;
  }

  .gap-xxl-12 {
    gap: 5.5rem !important;
  }

  .gap-xxl-13 {
    gap: 6rem !important;
  }

  .gap-xxl-14 {
    gap: 6.5rem !important;
  }

  .gap-xxl-15 {
    gap: 7rem !important;
  }

  .gap-xxl-16 {
    gap: 8rem !important;
  }

  .gap-xxl-17 {
    gap: 9rem !important;
  }

  .gap-xxl-18 {
    gap: 10rem !important;
  }

  .gap-xxl-19 {
    gap: 11rem !important;
  }

  .gap-xxl-20 {
    gap: 12rem !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 2rem !important;
  }

  .m-xxl-6 {
    margin: 2.5rem !important;
  }

  .m-xxl-7 {
    margin: 3rem !important;
  }

  .m-xxl-8 {
    margin: 3.5rem !important;
  }

  .m-xxl-9 {
    margin: 4rem !important;
  }

  .m-xxl-10 {
    margin: 4.5rem !important;
  }

  .m-xxl-11 {
    margin: 5rem !important;
  }

  .m-xxl-12 {
    margin: 5.5rem !important;
  }

  .m-xxl-13 {
    margin: 6rem !important;
  }

  .m-xxl-14 {
    margin: 6.5rem !important;
  }

  .m-xxl-15 {
    margin: 7rem !important;
  }

  .m-xxl-16 {
    margin: 8rem !important;
  }

  .m-xxl-17 {
    margin: 9rem !important;
  }

  .m-xxl-18 {
    margin: 10rem !important;
  }

  .m-xxl-19 {
    margin: 11rem !important;
  }

  .m-xxl-20 {
    margin: 12rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-xxl-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-xxl-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-xxl-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xxl-10 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-xxl-11 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-xxl-12 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important;
  }

  .mx-xxl-13 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-xxl-14 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important;
  }

  .mx-xxl-15 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }

  .mx-xxl-16 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-xxl-17 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }

  .mx-xxl-18 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .mx-xxl-19 {
    margin-right: 11rem !important;
    margin-left: 11rem !important;
  }

  .mx-xxl-20 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xxl-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xxl-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-xxl-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xxl-10 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-xxl-11 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-xxl-12 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }

  .my-xxl-13 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-xxl-14 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }

  .my-xxl-15 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .my-xxl-16 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-xxl-17 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .my-xxl-18 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-xxl-19 {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important;
  }

  .my-xxl-20 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 2rem !important;
  }

  .mt-xxl-6 {
    margin-top: 2.5rem !important;
  }

  .mt-xxl-7 {
    margin-top: 3rem !important;
  }

  .mt-xxl-8 {
    margin-top: 3.5rem !important;
  }

  .mt-xxl-9 {
    margin-top: 4rem !important;
  }

  .mt-xxl-10 {
    margin-top: 4.5rem !important;
  }

  .mt-xxl-11 {
    margin-top: 5rem !important;
  }

  .mt-xxl-12 {
    margin-top: 5.5rem !important;
  }

  .mt-xxl-13 {
    margin-top: 6rem !important;
  }

  .mt-xxl-14 {
    margin-top: 6.5rem !important;
  }

  .mt-xxl-15 {
    margin-top: 7rem !important;
  }

  .mt-xxl-16 {
    margin-top: 8rem !important;
  }

  .mt-xxl-17 {
    margin-top: 9rem !important;
  }

  .mt-xxl-18 {
    margin-top: 10rem !important;
  }

  .mt-xxl-19 {
    margin-top: 11rem !important;
  }

  .mt-xxl-20 {
    margin-top: 12rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 2rem !important;
  }

  .me-xxl-6 {
    margin-right: 2.5rem !important;
  }

  .me-xxl-7 {
    margin-right: 3rem !important;
  }

  .me-xxl-8 {
    margin-right: 3.5rem !important;
  }

  .me-xxl-9 {
    margin-right: 4rem !important;
  }

  .me-xxl-10 {
    margin-right: 4.5rem !important;
  }

  .me-xxl-11 {
    margin-right: 5rem !important;
  }

  .me-xxl-12 {
    margin-right: 5.5rem !important;
  }

  .me-xxl-13 {
    margin-right: 6rem !important;
  }

  .me-xxl-14 {
    margin-right: 6.5rem !important;
  }

  .me-xxl-15 {
    margin-right: 7rem !important;
  }

  .me-xxl-16 {
    margin-right: 8rem !important;
  }

  .me-xxl-17 {
    margin-right: 9rem !important;
  }

  .me-xxl-18 {
    margin-right: 10rem !important;
  }

  .me-xxl-19 {
    margin-right: 11rem !important;
  }

  .me-xxl-20 {
    margin-right: 12rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 2rem !important;
  }

  .mb-xxl-6 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xxl-7 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-8 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xxl-9 {
    margin-bottom: 4rem !important;
  }

  .mb-xxl-10 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xxl-11 {
    margin-bottom: 5rem !important;
  }

  .mb-xxl-12 {
    margin-bottom: 5.5rem !important;
  }

  .mb-xxl-13 {
    margin-bottom: 6rem !important;
  }

  .mb-xxl-14 {
    margin-bottom: 6.5rem !important;
  }

  .mb-xxl-15 {
    margin-bottom: 7rem !important;
  }

  .mb-xxl-16 {
    margin-bottom: 8rem !important;
  }

  .mb-xxl-17 {
    margin-bottom: 9rem !important;
  }

  .mb-xxl-18 {
    margin-bottom: 10rem !important;
  }

  .mb-xxl-19 {
    margin-bottom: 11rem !important;
  }

  .mb-xxl-20 {
    margin-bottom: 12rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 2rem !important;
  }

  .ms-xxl-6 {
    margin-left: 2.5rem !important;
  }

  .ms-xxl-7 {
    margin-left: 3rem !important;
  }

  .ms-xxl-8 {
    margin-left: 3.5rem !important;
  }

  .ms-xxl-9 {
    margin-left: 4rem !important;
  }

  .ms-xxl-10 {
    margin-left: 4.5rem !important;
  }

  .ms-xxl-11 {
    margin-left: 5rem !important;
  }

  .ms-xxl-12 {
    margin-left: 5.5rem !important;
  }

  .ms-xxl-13 {
    margin-left: 6rem !important;
  }

  .ms-xxl-14 {
    margin-left: 6.5rem !important;
  }

  .ms-xxl-15 {
    margin-left: 7rem !important;
  }

  .ms-xxl-16 {
    margin-left: 8rem !important;
  }

  .ms-xxl-17 {
    margin-left: 9rem !important;
  }

  .ms-xxl-18 {
    margin-left: 10rem !important;
  }

  .ms-xxl-19 {
    margin-left: 11rem !important;
  }

  .ms-xxl-20 {
    margin-left: 12rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .m-xxl-n1 {
    margin: -0.25rem !important;
  }

  .m-xxl-n2 {
    margin: -0.5rem !important;
  }

  .m-xxl-n3 {
    margin: -1rem !important;
  }

  .m-xxl-n4 {
    margin: -1.5rem !important;
  }

  .m-xxl-n5 {
    margin: -2rem !important;
  }

  .m-xxl-n6 {
    margin: -2.5rem !important;
  }

  .m-xxl-n7 {
    margin: -3rem !important;
  }

  .m-xxl-n8 {
    margin: -3.5rem !important;
  }

  .m-xxl-n9 {
    margin: -4rem !important;
  }

  .m-xxl-n10 {
    margin: -4.5rem !important;
  }

  .m-xxl-n11 {
    margin: -5rem !important;
  }

  .m-xxl-n12 {
    margin: -5.5rem !important;
  }

  .m-xxl-n13 {
    margin: -6rem !important;
  }

  .m-xxl-n14 {
    margin: -6.5rem !important;
  }

  .m-xxl-n15 {
    margin: -7rem !important;
  }

  .m-xxl-n16 {
    margin: -8rem !important;
  }

  .m-xxl-n17 {
    margin: -9rem !important;
  }

  .m-xxl-n18 {
    margin: -10rem !important;
  }

  .m-xxl-n19 {
    margin: -11rem !important;
  }

  .m-xxl-n20 {
    margin: -12rem !important;
  }

  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xxl-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-xxl-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-xxl-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-xxl-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-xxl-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-xxl-n10 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .mx-xxl-n11 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .mx-xxl-n12 {
    margin-right: -5.5rem !important;
    margin-left: -5.5rem !important;
  }

  .mx-xxl-n13 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }

  .mx-xxl-n14 {
    margin-right: -6.5rem !important;
    margin-left: -6.5rem !important;
  }

  .mx-xxl-n15 {
    margin-right: -7rem !important;
    margin-left: -7rem !important;
  }

  .mx-xxl-n16 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }

  .mx-xxl-n17 {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }

  .mx-xxl-n18 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }

  .mx-xxl-n19 {
    margin-right: -11rem !important;
    margin-left: -11rem !important;
  }

  .mx-xxl-n20 {
    margin-right: -12rem !important;
    margin-left: -12rem !important;
  }

  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xxl-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-xxl-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-xxl-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-xxl-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-xxl-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-xxl-n10 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-xxl-n11 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .my-xxl-n12 {
    margin-top: -5.5rem !important;
    margin-bottom: -5.5rem !important;
  }

  .my-xxl-n13 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-xxl-n14 {
    margin-top: -6.5rem !important;
    margin-bottom: -6.5rem !important;
  }

  .my-xxl-n15 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }

  .my-xxl-n16 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-xxl-n17 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .my-xxl-n18 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .my-xxl-n19 {
    margin-top: -11rem !important;
    margin-bottom: -11rem !important;
  }

  .my-xxl-n20 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xxl-n5 {
    margin-top: -2rem !important;
  }

  .mt-xxl-n6 {
    margin-top: -2.5rem !important;
  }

  .mt-xxl-n7 {
    margin-top: -3rem !important;
  }

  .mt-xxl-n8 {
    margin-top: -3.5rem !important;
  }

  .mt-xxl-n9 {
    margin-top: -4rem !important;
  }

  .mt-xxl-n10 {
    margin-top: -4.5rem !important;
  }

  .mt-xxl-n11 {
    margin-top: -5rem !important;
  }

  .mt-xxl-n12 {
    margin-top: -5.5rem !important;
  }

  .mt-xxl-n13 {
    margin-top: -6rem !important;
  }

  .mt-xxl-n14 {
    margin-top: -6.5rem !important;
  }

  .mt-xxl-n15 {
    margin-top: -7rem !important;
  }

  .mt-xxl-n16 {
    margin-top: -8rem !important;
  }

  .mt-xxl-n17 {
    margin-top: -9rem !important;
  }

  .mt-xxl-n18 {
    margin-top: -10rem !important;
  }

  .mt-xxl-n19 {
    margin-top: -11rem !important;
  }

  .mt-xxl-n20 {
    margin-top: -12rem !important;
  }

  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-xxl-n3 {
    margin-right: -1rem !important;
  }

  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xxl-n5 {
    margin-right: -2rem !important;
  }

  .me-xxl-n6 {
    margin-right: -2.5rem !important;
  }

  .me-xxl-n7 {
    margin-right: -3rem !important;
  }

  .me-xxl-n8 {
    margin-right: -3.5rem !important;
  }

  .me-xxl-n9 {
    margin-right: -4rem !important;
  }

  .me-xxl-n10 {
    margin-right: -4.5rem !important;
  }

  .me-xxl-n11 {
    margin-right: -5rem !important;
  }

  .me-xxl-n12 {
    margin-right: -5.5rem !important;
  }

  .me-xxl-n13 {
    margin-right: -6rem !important;
  }

  .me-xxl-n14 {
    margin-right: -6.5rem !important;
  }

  .me-xxl-n15 {
    margin-right: -7rem !important;
  }

  .me-xxl-n16 {
    margin-right: -8rem !important;
  }

  .me-xxl-n17 {
    margin-right: -9rem !important;
  }

  .me-xxl-n18 {
    margin-right: -10rem !important;
  }

  .me-xxl-n19 {
    margin-right: -11rem !important;
  }

  .me-xxl-n20 {
    margin-right: -12rem !important;
  }

  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xxl-n5 {
    margin-bottom: -2rem !important;
  }

  .mb-xxl-n6 {
    margin-bottom: -2.5rem !important;
  }

  .mb-xxl-n7 {
    margin-bottom: -3rem !important;
  }

  .mb-xxl-n8 {
    margin-bottom: -3.5rem !important;
  }

  .mb-xxl-n9 {
    margin-bottom: -4rem !important;
  }

  .mb-xxl-n10 {
    margin-bottom: -4.5rem !important;
  }

  .mb-xxl-n11 {
    margin-bottom: -5rem !important;
  }

  .mb-xxl-n12 {
    margin-bottom: -5.5rem !important;
  }

  .mb-xxl-n13 {
    margin-bottom: -6rem !important;
  }

  .mb-xxl-n14 {
    margin-bottom: -6.5rem !important;
  }

  .mb-xxl-n15 {
    margin-bottom: -7rem !important;
  }

  .mb-xxl-n16 {
    margin-bottom: -8rem !important;
  }

  .mb-xxl-n17 {
    margin-bottom: -9rem !important;
  }

  .mb-xxl-n18 {
    margin-bottom: -10rem !important;
  }

  .mb-xxl-n19 {
    margin-bottom: -11rem !important;
  }

  .mb-xxl-n20 {
    margin-bottom: -12rem !important;
  }

  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xxl-n5 {
    margin-left: -2rem !important;
  }

  .ms-xxl-n6 {
    margin-left: -2.5rem !important;
  }

  .ms-xxl-n7 {
    margin-left: -3rem !important;
  }

  .ms-xxl-n8 {
    margin-left: -3.5rem !important;
  }

  .ms-xxl-n9 {
    margin-left: -4rem !important;
  }

  .ms-xxl-n10 {
    margin-left: -4.5rem !important;
  }

  .ms-xxl-n11 {
    margin-left: -5rem !important;
  }

  .ms-xxl-n12 {
    margin-left: -5.5rem !important;
  }

  .ms-xxl-n13 {
    margin-left: -6rem !important;
  }

  .ms-xxl-n14 {
    margin-left: -6.5rem !important;
  }

  .ms-xxl-n15 {
    margin-left: -7rem !important;
  }

  .ms-xxl-n16 {
    margin-left: -8rem !important;
  }

  .ms-xxl-n17 {
    margin-left: -9rem !important;
  }

  .ms-xxl-n18 {
    margin-left: -10rem !important;
  }

  .ms-xxl-n19 {
    margin-left: -11rem !important;
  }

  .ms-xxl-n20 {
    margin-left: -12rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 2rem !important;
  }

  .p-xxl-6 {
    padding: 2.5rem !important;
  }

  .p-xxl-7 {
    padding: 3rem !important;
  }

  .p-xxl-8 {
    padding: 3.5rem !important;
  }

  .p-xxl-9 {
    padding: 4rem !important;
  }

  .p-xxl-10 {
    padding: 4.5rem !important;
  }

  .p-xxl-11 {
    padding: 5rem !important;
  }

  .p-xxl-12 {
    padding: 5.5rem !important;
  }

  .p-xxl-13 {
    padding: 6rem !important;
  }

  .p-xxl-14 {
    padding: 6.5rem !important;
  }

  .p-xxl-15 {
    padding: 7rem !important;
  }

  .p-xxl-16 {
    padding: 8rem !important;
  }

  .p-xxl-17 {
    padding: 9rem !important;
  }

  .p-xxl-18 {
    padding: 10rem !important;
  }

  .p-xxl-19 {
    padding: 11rem !important;
  }

  .p-xxl-20 {
    padding: 12rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-xxl-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-xxl-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xxl-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-xxl-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-xxl-10 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-xxl-11 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .px-xxl-12 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important;
  }

  .px-xxl-13 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-xxl-14 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important;
  }

  .px-xxl-15 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }

  .px-xxl-16 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .px-xxl-17 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }

  .px-xxl-18 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .px-xxl-19 {
    padding-right: 11rem !important;
    padding-left: 11rem !important;
  }

  .px-xxl-20 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xxl-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-xxl-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xxl-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-xxl-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xxl-10 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-xxl-11 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-xxl-12 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }

  .py-xxl-13 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-xxl-14 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }

  .py-xxl-15 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .py-xxl-16 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-xxl-17 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .py-xxl-18 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .py-xxl-19 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }

  .py-xxl-20 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 2rem !important;
  }

  .pt-xxl-6 {
    padding-top: 2.5rem !important;
  }

  .pt-xxl-7 {
    padding-top: 3rem !important;
  }

  .pt-xxl-8 {
    padding-top: 3.5rem !important;
  }

  .pt-xxl-9 {
    padding-top: 4rem !important;
  }

  .pt-xxl-10 {
    padding-top: 4.5rem !important;
  }

  .pt-xxl-11 {
    padding-top: 5rem !important;
  }

  .pt-xxl-12 {
    padding-top: 5.5rem !important;
  }

  .pt-xxl-13 {
    padding-top: 6rem !important;
  }

  .pt-xxl-14 {
    padding-top: 6.5rem !important;
  }

  .pt-xxl-15 {
    padding-top: 7rem !important;
  }

  .pt-xxl-16 {
    padding-top: 8rem !important;
  }

  .pt-xxl-17 {
    padding-top: 9rem !important;
  }

  .pt-xxl-18 {
    padding-top: 10rem !important;
  }

  .pt-xxl-19 {
    padding-top: 11rem !important;
  }

  .pt-xxl-20 {
    padding-top: 12rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 2rem !important;
  }

  .pe-xxl-6 {
    padding-right: 2.5rem !important;
  }

  .pe-xxl-7 {
    padding-right: 3rem !important;
  }

  .pe-xxl-8 {
    padding-right: 3.5rem !important;
  }

  .pe-xxl-9 {
    padding-right: 4rem !important;
  }

  .pe-xxl-10 {
    padding-right: 4.5rem !important;
  }

  .pe-xxl-11 {
    padding-right: 5rem !important;
  }

  .pe-xxl-12 {
    padding-right: 5.5rem !important;
  }

  .pe-xxl-13 {
    padding-right: 6rem !important;
  }

  .pe-xxl-14 {
    padding-right: 6.5rem !important;
  }

  .pe-xxl-15 {
    padding-right: 7rem !important;
  }

  .pe-xxl-16 {
    padding-right: 8rem !important;
  }

  .pe-xxl-17 {
    padding-right: 9rem !important;
  }

  .pe-xxl-18 {
    padding-right: 10rem !important;
  }

  .pe-xxl-19 {
    padding-right: 11rem !important;
  }

  .pe-xxl-20 {
    padding-right: 12rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 2rem !important;
  }

  .pb-xxl-6 {
    padding-bottom: 2.5rem !important;
  }

  .pb-xxl-7 {
    padding-bottom: 3rem !important;
  }

  .pb-xxl-8 {
    padding-bottom: 3.5rem !important;
  }

  .pb-xxl-9 {
    padding-bottom: 4rem !important;
  }

  .pb-xxl-10 {
    padding-bottom: 4.5rem !important;
  }

  .pb-xxl-11 {
    padding-bottom: 5rem !important;
  }

  .pb-xxl-12 {
    padding-bottom: 5.5rem !important;
  }

  .pb-xxl-13 {
    padding-bottom: 6rem !important;
  }

  .pb-xxl-14 {
    padding-bottom: 6.5rem !important;
  }

  .pb-xxl-15 {
    padding-bottom: 7rem !important;
  }

  .pb-xxl-16 {
    padding-bottom: 8rem !important;
  }

  .pb-xxl-17 {
    padding-bottom: 9rem !important;
  }

  .pb-xxl-18 {
    padding-bottom: 10rem !important;
  }

  .pb-xxl-19 {
    padding-bottom: 11rem !important;
  }

  .pb-xxl-20 {
    padding-bottom: 12rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 2rem !important;
  }

  .ps-xxl-6 {
    padding-left: 2.5rem !important;
  }

  .ps-xxl-7 {
    padding-left: 3rem !important;
  }

  .ps-xxl-8 {
    padding-left: 3.5rem !important;
  }

  .ps-xxl-9 {
    padding-left: 4rem !important;
  }

  .ps-xxl-10 {
    padding-left: 4.5rem !important;
  }

  .ps-xxl-11 {
    padding-left: 5rem !important;
  }

  .ps-xxl-12 {
    padding-left: 5.5rem !important;
  }

  .ps-xxl-13 {
    padding-left: 6rem !important;
  }

  .ps-xxl-14 {
    padding-left: 6.5rem !important;
  }

  .ps-xxl-15 {
    padding-left: 7rem !important;
  }

  .ps-xxl-16 {
    padding-left: 8rem !important;
  }

  .ps-xxl-17 {
    padding-left: 9rem !important;
  }

  .ps-xxl-18 {
    padding-left: 10rem !important;
  }

  .ps-xxl-19 {
    padding-left: 11rem !important;
  }

  .ps-xxl-20 {
    padding-left: 12rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }

  .rounded-xxl-0 {
    border-radius: 0 !important;
  }

  .rounded-xxl {
    border-radius: 0.375rem !important;
  }

  .rounded-xxl-1 {
    border-radius: 0.25rem !important;
  }

  .rounded-xxl-2 {
    border-radius: 0.375rem !important;
  }

  .rounded-xxl-3 {
    border-radius: 0.575rem !important;
  }

  .rounded-xxl-4 {
    border-radius: 1.5em !important;
  }

  .rounded-xxl-pill {
    border-radius: 50rem !important;
  }

  .rounded-xxl-block {
    border-radius: 3rem !important;
  }

  .rounded-xxl-blob {
    border-radius: 30% 70% 70% 30%/30% 30% 70% 70% !important;
  }

  .rounded-xxl-circle {
    border-radius: 50% !important;
  }

  .rounded-top-xxl-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .rounded-top-xxl {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }

  .rounded-top-xxl-1 {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }

  .rounded-top-xxl-2 {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }

  .rounded-top-xxl-3 {
    border-top-left-radius: 0.575rem !important;
    border-top-right-radius: 0.575rem !important;
  }

  .rounded-top-xxl-4 {
    border-top-left-radius: 1.5em !important;
    border-top-right-radius: 1.5em !important;
  }

  .rounded-top-xxl-pill {
    border-top-left-radius: 50rem !important;
    border-top-right-radius: 50rem !important;
  }

  .rounded-top-xxl-block {
    border-top-left-radius: 3rem !important;
    border-top-right-radius: 3rem !important;
  }

  .rounded-top-xxl-circle {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }

  .rounded-end-xxl-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .rounded-end-xxl {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .rounded-end-xxl-1 {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  .rounded-end-xxl-2 {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .rounded-end-xxl-3 {
    border-top-right-radius: 0.575rem !important;
    border-bottom-right-radius: 0.575rem !important;
  }

  .rounded-end-xxl-4 {
    border-top-right-radius: 1.5em !important;
    border-bottom-right-radius: 1.5em !important;
  }

  .rounded-end-xxl-pill {
    border-top-right-radius: 50rem !important;
    border-bottom-right-radius: 50rem !important;
  }

  .rounded-end-xxl-block {
    border-top-right-radius: 3rem !important;
    border-bottom-right-radius: 3rem !important;
  }

  .rounded-end-xxl-circle {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }

  .rounded-bottom-xxl-0 {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .rounded-bottom-xxl {
    border-bottom-left-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .rounded-bottom-xxl-1 {
    border-bottom-left-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  .rounded-bottom-xxl-2 {
    border-bottom-left-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .rounded-bottom-xxl-3 {
    border-bottom-left-radius: 0.575rem !important;
    border-bottom-right-radius: 0.575rem !important;
  }

  .rounded-bottom-xxl-4 {
    border-bottom-left-radius: 1.5em !important;
    border-bottom-right-radius: 1.5em !important;
  }

  .rounded-bottom-xxl-pill {
    border-bottom-left-radius: 50rem !important;
    border-bottom-right-radius: 50rem !important;
  }

  .rounded-bottom-xxl-block {
    border-bottom-left-radius: 3rem !important;
    border-bottom-right-radius: 3rem !important;
  }

  .rounded-bottom-xxl-circle {
    border-bottom-left-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }

  .rounded-start-xxl-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .rounded-start-xxl {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .rounded-start-xxl-1 {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .rounded-start-xxl-2 {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .rounded-start-xxl-3 {
    border-top-left-radius: 0.575rem !important;
    border-bottom-left-radius: 0.575rem !important;
  }

  .rounded-start-xxl-4 {
    border-top-left-radius: 1.5em !important;
    border-bottom-left-radius: 1.5em !important;
  }

  .rounded-start-xxl-pill {
    border-top-left-radius: 50rem !important;
    border-bottom-left-radius: 50rem !important;
  }

  .rounded-start-xxl-block {
    border-top-left-radius: 3rem !important;
    border-bottom-left-radius: 3rem !important;
  }

  .rounded-start-xxl-circle {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }

  .fs-2 {
    font-size: 2rem !important;
  }

  .fs-3 {
    font-size: 1.75rem !important;
  }

  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}
body[data-aos-duration="50"] [data-aos], [data-aos][data-aos][data-aos-duration="50"] {
  transition-duration: 50ms;
}
body[data-aos-delay="50"] [data-aos], [data-aos][data-aos][data-aos-delay="50"] {
  transition-delay: 0s;
}
body[data-aos-delay="50"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="50"].aos-animate {
  transition-delay: 50ms;
}
body[data-aos-duration="100"] [data-aos], [data-aos][data-aos][data-aos-duration="100"] {
  transition-duration: 100ms;
}
body[data-aos-delay="100"] [data-aos], [data-aos][data-aos][data-aos-delay="100"] {
  transition-delay: 0s;
}
body[data-aos-delay="100"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="100"].aos-animate {
  transition-delay: 100ms;
}
body[data-aos-duration="150"] [data-aos], [data-aos][data-aos][data-aos-duration="150"] {
  transition-duration: 150ms;
}
body[data-aos-delay="150"] [data-aos], [data-aos][data-aos][data-aos-delay="150"] {
  transition-delay: 0s;
}
body[data-aos-delay="150"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="150"].aos-animate {
  transition-delay: 150ms;
}
body[data-aos-duration="200"] [data-aos], [data-aos][data-aos][data-aos-duration="200"] {
  transition-duration: 200ms;
}
body[data-aos-delay="200"] [data-aos], [data-aos][data-aos][data-aos-delay="200"] {
  transition-delay: 0s;
}
body[data-aos-delay="200"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="200"].aos-animate {
  transition-delay: 200ms;
}
body[data-aos-duration="250"] [data-aos], [data-aos][data-aos][data-aos-duration="250"] {
  transition-duration: 250ms;
}
body[data-aos-delay="250"] [data-aos], [data-aos][data-aos][data-aos-delay="250"] {
  transition-delay: 0s;
}
body[data-aos-delay="250"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="250"].aos-animate {
  transition-delay: 250ms;
}
body[data-aos-duration="300"] [data-aos], [data-aos][data-aos][data-aos-duration="300"] {
  transition-duration: 300ms;
}
body[data-aos-delay="300"] [data-aos], [data-aos][data-aos][data-aos-delay="300"] {
  transition-delay: 0s;
}
body[data-aos-delay="300"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="300"].aos-animate {
  transition-delay: 300ms;
}
body[data-aos-duration="350"] [data-aos], [data-aos][data-aos][data-aos-duration="350"] {
  transition-duration: 350ms;
}
body[data-aos-delay="350"] [data-aos], [data-aos][data-aos][data-aos-delay="350"] {
  transition-delay: 0s;
}
body[data-aos-delay="350"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="350"].aos-animate {
  transition-delay: 350ms;
}
body[data-aos-duration="400"] [data-aos], [data-aos][data-aos][data-aos-duration="400"] {
  transition-duration: 400ms;
}
body[data-aos-delay="400"] [data-aos], [data-aos][data-aos][data-aos-delay="400"] {
  transition-delay: 0s;
}
body[data-aos-delay="400"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="400"].aos-animate {
  transition-delay: 400ms;
}
body[data-aos-duration="450"] [data-aos], [data-aos][data-aos][data-aos-duration="450"] {
  transition-duration: 450ms;
}
body[data-aos-delay="450"] [data-aos], [data-aos][data-aos][data-aos-delay="450"] {
  transition-delay: 0s;
}
body[data-aos-delay="450"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="450"].aos-animate {
  transition-delay: 450ms;
}
body[data-aos-duration="500"] [data-aos], [data-aos][data-aos][data-aos-duration="500"] {
  transition-duration: 500ms;
}
body[data-aos-delay="500"] [data-aos], [data-aos][data-aos][data-aos-delay="500"] {
  transition-delay: 0s;
}
body[data-aos-delay="500"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="500"].aos-animate {
  transition-delay: 500ms;
}
body[data-aos-duration="550"] [data-aos], [data-aos][data-aos][data-aos-duration="550"] {
  transition-duration: 550ms;
}
body[data-aos-delay="550"] [data-aos], [data-aos][data-aos][data-aos-delay="550"] {
  transition-delay: 0s;
}
body[data-aos-delay="550"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="550"].aos-animate {
  transition-delay: 550ms;
}
body[data-aos-duration="600"] [data-aos], [data-aos][data-aos][data-aos-duration="600"] {
  transition-duration: 600ms;
}
body[data-aos-delay="600"] [data-aos], [data-aos][data-aos][data-aos-delay="600"] {
  transition-delay: 0s;
}
body[data-aos-delay="600"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="600"].aos-animate {
  transition-delay: 600ms;
}
body[data-aos-duration="650"] [data-aos], [data-aos][data-aos][data-aos-duration="650"] {
  transition-duration: 650ms;
}
body[data-aos-delay="650"] [data-aos], [data-aos][data-aos][data-aos-delay="650"] {
  transition-delay: 0s;
}
body[data-aos-delay="650"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="650"].aos-animate {
  transition-delay: 650ms;
}
body[data-aos-duration="700"] [data-aos], [data-aos][data-aos][data-aos-duration="700"] {
  transition-duration: 700ms;
}
body[data-aos-delay="700"] [data-aos], [data-aos][data-aos][data-aos-delay="700"] {
  transition-delay: 0s;
}
body[data-aos-delay="700"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="700"].aos-animate {
  transition-delay: 700ms;
}
body[data-aos-duration="750"] [data-aos], [data-aos][data-aos][data-aos-duration="750"] {
  transition-duration: 750ms;
}
body[data-aos-delay="750"] [data-aos], [data-aos][data-aos][data-aos-delay="750"] {
  transition-delay: 0s;
}
body[data-aos-delay="750"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="750"].aos-animate {
  transition-delay: 750ms;
}
body[data-aos-duration="800"] [data-aos], [data-aos][data-aos][data-aos-duration="800"] {
  transition-duration: 800ms;
}
body[data-aos-delay="800"] [data-aos], [data-aos][data-aos][data-aos-delay="800"] {
  transition-delay: 0s;
}
body[data-aos-delay="800"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="800"].aos-animate {
  transition-delay: 800ms;
}
body[data-aos-duration="850"] [data-aos], [data-aos][data-aos][data-aos-duration="850"] {
  transition-duration: 850ms;
}
body[data-aos-delay="850"] [data-aos], [data-aos][data-aos][data-aos-delay="850"] {
  transition-delay: 0s;
}
body[data-aos-delay="850"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="850"].aos-animate {
  transition-delay: 850ms;
}
body[data-aos-duration="900"] [data-aos], [data-aos][data-aos][data-aos-duration="900"] {
  transition-duration: 900ms;
}
body[data-aos-delay="900"] [data-aos], [data-aos][data-aos][data-aos-delay="900"] {
  transition-delay: 0s;
}
body[data-aos-delay="900"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="900"].aos-animate {
  transition-delay: 900ms;
}
body[data-aos-duration="950"] [data-aos], [data-aos][data-aos][data-aos-duration="950"] {
  transition-duration: 950ms;
}
body[data-aos-delay="950"] [data-aos], [data-aos][data-aos][data-aos-delay="950"] {
  transition-delay: 0s;
}
body[data-aos-delay="950"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="950"].aos-animate {
  transition-delay: 950ms;
}
body[data-aos-duration="1000"] [data-aos], [data-aos][data-aos][data-aos-duration="1000"] {
  transition-duration: 1000ms;
}
body[data-aos-delay="1000"] [data-aos], [data-aos][data-aos][data-aos-delay="1000"] {
  transition-delay: 0s;
}
body[data-aos-delay="1000"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1000"].aos-animate {
  transition-delay: 1000ms;
}
body[data-aos-duration="1050"] [data-aos], [data-aos][data-aos][data-aos-duration="1050"] {
  transition-duration: 1050ms;
}
body[data-aos-delay="1050"] [data-aos], [data-aos][data-aos][data-aos-delay="1050"] {
  transition-delay: 0s;
}
body[data-aos-delay="1050"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1050"].aos-animate {
  transition-delay: 1050ms;
}
body[data-aos-duration="1100"] [data-aos], [data-aos][data-aos][data-aos-duration="1100"] {
  transition-duration: 1100ms;
}
body[data-aos-delay="1100"] [data-aos], [data-aos][data-aos][data-aos-delay="1100"] {
  transition-delay: 0s;
}
body[data-aos-delay="1100"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1100"].aos-animate {
  transition-delay: 1100ms;
}
body[data-aos-duration="1150"] [data-aos], [data-aos][data-aos][data-aos-duration="1150"] {
  transition-duration: 1150ms;
}
body[data-aos-delay="1150"] [data-aos], [data-aos][data-aos][data-aos-delay="1150"] {
  transition-delay: 0s;
}
body[data-aos-delay="1150"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1150"].aos-animate {
  transition-delay: 1150ms;
}
body[data-aos-duration="1200"] [data-aos], [data-aos][data-aos][data-aos-duration="1200"] {
  transition-duration: 1200ms;
}
body[data-aos-delay="1200"] [data-aos], [data-aos][data-aos][data-aos-delay="1200"] {
  transition-delay: 0s;
}
body[data-aos-delay="1200"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1200"].aos-animate {
  transition-delay: 1200ms;
}
body[data-aos-duration="1250"] [data-aos], [data-aos][data-aos][data-aos-duration="1250"] {
  transition-duration: 1250ms;
}
body[data-aos-delay="1250"] [data-aos], [data-aos][data-aos][data-aos-delay="1250"] {
  transition-delay: 0s;
}
body[data-aos-delay="1250"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1250"].aos-animate {
  transition-delay: 1250ms;
}
body[data-aos-duration="1300"] [data-aos], [data-aos][data-aos][data-aos-duration="1300"] {
  transition-duration: 1300ms;
}
body[data-aos-delay="1300"] [data-aos], [data-aos][data-aos][data-aos-delay="1300"] {
  transition-delay: 0s;
}
body[data-aos-delay="1300"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1300"].aos-animate {
  transition-delay: 1300ms;
}
body[data-aos-duration="1350"] [data-aos], [data-aos][data-aos][data-aos-duration="1350"] {
  transition-duration: 1350ms;
}
body[data-aos-delay="1350"] [data-aos], [data-aos][data-aos][data-aos-delay="1350"] {
  transition-delay: 0s;
}
body[data-aos-delay="1350"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1350"].aos-animate {
  transition-delay: 1350ms;
}
body[data-aos-duration="1400"] [data-aos], [data-aos][data-aos][data-aos-duration="1400"] {
  transition-duration: 1400ms;
}
body[data-aos-delay="1400"] [data-aos], [data-aos][data-aos][data-aos-delay="1400"] {
  transition-delay: 0s;
}
body[data-aos-delay="1400"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1400"].aos-animate {
  transition-delay: 1400ms;
}
body[data-aos-duration="1450"] [data-aos], [data-aos][data-aos][data-aos-duration="1450"] {
  transition-duration: 1450ms;
}
body[data-aos-delay="1450"] [data-aos], [data-aos][data-aos][data-aos-delay="1450"] {
  transition-delay: 0s;
}
body[data-aos-delay="1450"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1450"].aos-animate {
  transition-delay: 1450ms;
}
body[data-aos-duration="1500"] [data-aos], [data-aos][data-aos][data-aos-duration="1500"] {
  transition-duration: 1500ms;
}
body[data-aos-delay="1500"] [data-aos], [data-aos][data-aos][data-aos-delay="1500"] {
  transition-delay: 0s;
}
body[data-aos-delay="1500"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1500"].aos-animate {
  transition-delay: 1500ms;
}
body[data-aos-duration="1550"] [data-aos], [data-aos][data-aos][data-aos-duration="1550"] {
  transition-duration: 1550ms;
}
body[data-aos-delay="1550"] [data-aos], [data-aos][data-aos][data-aos-delay="1550"] {
  transition-delay: 0s;
}
body[data-aos-delay="1550"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1550"].aos-animate {
  transition-delay: 1550ms;
}
body[data-aos-duration="1600"] [data-aos], [data-aos][data-aos][data-aos-duration="1600"] {
  transition-duration: 1600ms;
}
body[data-aos-delay="1600"] [data-aos], [data-aos][data-aos][data-aos-delay="1600"] {
  transition-delay: 0s;
}
body[data-aos-delay="1600"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1600"].aos-animate {
  transition-delay: 1600ms;
}
body[data-aos-duration="1650"] [data-aos], [data-aos][data-aos][data-aos-duration="1650"] {
  transition-duration: 1650ms;
}
body[data-aos-delay="1650"] [data-aos], [data-aos][data-aos][data-aos-delay="1650"] {
  transition-delay: 0s;
}
body[data-aos-delay="1650"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1650"].aos-animate {
  transition-delay: 1650ms;
}
body[data-aos-duration="1700"] [data-aos], [data-aos][data-aos][data-aos-duration="1700"] {
  transition-duration: 1700ms;
}
body[data-aos-delay="1700"] [data-aos], [data-aos][data-aos][data-aos-delay="1700"] {
  transition-delay: 0s;
}
body[data-aos-delay="1700"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1700"].aos-animate {
  transition-delay: 1700ms;
}
body[data-aos-duration="1750"] [data-aos], [data-aos][data-aos][data-aos-duration="1750"] {
  transition-duration: 1750ms;
}
body[data-aos-delay="1750"] [data-aos], [data-aos][data-aos][data-aos-delay="1750"] {
  transition-delay: 0s;
}
body[data-aos-delay="1750"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1750"].aos-animate {
  transition-delay: 1750ms;
}
body[data-aos-duration="1800"] [data-aos], [data-aos][data-aos][data-aos-duration="1800"] {
  transition-duration: 1800ms;
}
body[data-aos-delay="1800"] [data-aos], [data-aos][data-aos][data-aos-delay="1800"] {
  transition-delay: 0s;
}
body[data-aos-delay="1800"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1800"].aos-animate {
  transition-delay: 1800ms;
}
body[data-aos-duration="1850"] [data-aos], [data-aos][data-aos][data-aos-duration="1850"] {
  transition-duration: 1850ms;
}
body[data-aos-delay="1850"] [data-aos], [data-aos][data-aos][data-aos-delay="1850"] {
  transition-delay: 0s;
}
body[data-aos-delay="1850"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1850"].aos-animate {
  transition-delay: 1850ms;
}
body[data-aos-duration="1900"] [data-aos], [data-aos][data-aos][data-aos-duration="1900"] {
  transition-duration: 1900ms;
}
body[data-aos-delay="1900"] [data-aos], [data-aos][data-aos][data-aos-delay="1900"] {
  transition-delay: 0s;
}
body[data-aos-delay="1900"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1900"].aos-animate {
  transition-delay: 1900ms;
}
body[data-aos-duration="1950"] [data-aos], [data-aos][data-aos][data-aos-duration="1950"] {
  transition-duration: 1950ms;
}
body[data-aos-delay="1950"] [data-aos], [data-aos][data-aos][data-aos-delay="1950"] {
  transition-delay: 0s;
}
body[data-aos-delay="1950"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1950"].aos-animate {
  transition-delay: 1950ms;
}
body[data-aos-duration="2000"] [data-aos], [data-aos][data-aos][data-aos-duration="2000"] {
  transition-duration: 2000ms;
}
body[data-aos-delay="2000"] [data-aos], [data-aos][data-aos][data-aos-delay="2000"] {
  transition-delay: 0s;
}
body[data-aos-delay="2000"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2000"].aos-animate {
  transition-delay: 2000ms;
}
body[data-aos-duration="2050"] [data-aos], [data-aos][data-aos][data-aos-duration="2050"] {
  transition-duration: 2050ms;
}
body[data-aos-delay="2050"] [data-aos], [data-aos][data-aos][data-aos-delay="2050"] {
  transition-delay: 0s;
}
body[data-aos-delay="2050"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2050"].aos-animate {
  transition-delay: 2050ms;
}
body[data-aos-duration="2100"] [data-aos], [data-aos][data-aos][data-aos-duration="2100"] {
  transition-duration: 2100ms;
}
body[data-aos-delay="2100"] [data-aos], [data-aos][data-aos][data-aos-delay="2100"] {
  transition-delay: 0s;
}
body[data-aos-delay="2100"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2100"].aos-animate {
  transition-delay: 2100ms;
}
body[data-aos-duration="2150"] [data-aos], [data-aos][data-aos][data-aos-duration="2150"] {
  transition-duration: 2150ms;
}
body[data-aos-delay="2150"] [data-aos], [data-aos][data-aos][data-aos-delay="2150"] {
  transition-delay: 0s;
}
body[data-aos-delay="2150"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2150"].aos-animate {
  transition-delay: 2150ms;
}
body[data-aos-duration="2200"] [data-aos], [data-aos][data-aos][data-aos-duration="2200"] {
  transition-duration: 2200ms;
}
body[data-aos-delay="2200"] [data-aos], [data-aos][data-aos][data-aos-delay="2200"] {
  transition-delay: 0s;
}
body[data-aos-delay="2200"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2200"].aos-animate {
  transition-delay: 2200ms;
}
body[data-aos-duration="2250"] [data-aos], [data-aos][data-aos][data-aos-duration="2250"] {
  transition-duration: 2250ms;
}
body[data-aos-delay="2250"] [data-aos], [data-aos][data-aos][data-aos-delay="2250"] {
  transition-delay: 0s;
}
body[data-aos-delay="2250"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2250"].aos-animate {
  transition-delay: 2250ms;
}
body[data-aos-duration="2300"] [data-aos], [data-aos][data-aos][data-aos-duration="2300"] {
  transition-duration: 2300ms;
}
body[data-aos-delay="2300"] [data-aos], [data-aos][data-aos][data-aos-delay="2300"] {
  transition-delay: 0s;
}
body[data-aos-delay="2300"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2300"].aos-animate {
  transition-delay: 2300ms;
}
body[data-aos-duration="2350"] [data-aos], [data-aos][data-aos][data-aos-duration="2350"] {
  transition-duration: 2350ms;
}
body[data-aos-delay="2350"] [data-aos], [data-aos][data-aos][data-aos-delay="2350"] {
  transition-delay: 0s;
}
body[data-aos-delay="2350"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2350"].aos-animate {
  transition-delay: 2350ms;
}
body[data-aos-duration="2400"] [data-aos], [data-aos][data-aos][data-aos-duration="2400"] {
  transition-duration: 2400ms;
}
body[data-aos-delay="2400"] [data-aos], [data-aos][data-aos][data-aos-delay="2400"] {
  transition-delay: 0s;
}
body[data-aos-delay="2400"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2400"].aos-animate {
  transition-delay: 2400ms;
}
body[data-aos-duration="2450"] [data-aos], [data-aos][data-aos][data-aos-duration="2450"] {
  transition-duration: 2450ms;
}
body[data-aos-delay="2450"] [data-aos], [data-aos][data-aos][data-aos-delay="2450"] {
  transition-delay: 0s;
}
body[data-aos-delay="2450"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2450"].aos-animate {
  transition-delay: 2450ms;
}
body[data-aos-duration="2500"] [data-aos], [data-aos][data-aos][data-aos-duration="2500"] {
  transition-duration: 2500ms;
}
body[data-aos-delay="2500"] [data-aos], [data-aos][data-aos][data-aos-delay="2500"] {
  transition-delay: 0s;
}
body[data-aos-delay="2500"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2500"].aos-animate {
  transition-delay: 2500ms;
}
body[data-aos-duration="2550"] [data-aos], [data-aos][data-aos][data-aos-duration="2550"] {
  transition-duration: 2550ms;
}
body[data-aos-delay="2550"] [data-aos], [data-aos][data-aos][data-aos-delay="2550"] {
  transition-delay: 0s;
}
body[data-aos-delay="2550"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2550"].aos-animate {
  transition-delay: 2550ms;
}
body[data-aos-duration="2600"] [data-aos], [data-aos][data-aos][data-aos-duration="2600"] {
  transition-duration: 2600ms;
}
body[data-aos-delay="2600"] [data-aos], [data-aos][data-aos][data-aos-delay="2600"] {
  transition-delay: 0s;
}
body[data-aos-delay="2600"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2600"].aos-animate {
  transition-delay: 2600ms;
}
body[data-aos-duration="2650"] [data-aos], [data-aos][data-aos][data-aos-duration="2650"] {
  transition-duration: 2650ms;
}
body[data-aos-delay="2650"] [data-aos], [data-aos][data-aos][data-aos-delay="2650"] {
  transition-delay: 0s;
}
body[data-aos-delay="2650"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2650"].aos-animate {
  transition-delay: 2650ms;
}
body[data-aos-duration="2700"] [data-aos], [data-aos][data-aos][data-aos-duration="2700"] {
  transition-duration: 2700ms;
}
body[data-aos-delay="2700"] [data-aos], [data-aos][data-aos][data-aos-delay="2700"] {
  transition-delay: 0s;
}
body[data-aos-delay="2700"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2700"].aos-animate {
  transition-delay: 2700ms;
}
body[data-aos-duration="2750"] [data-aos], [data-aos][data-aos][data-aos-duration="2750"] {
  transition-duration: 2750ms;
}
body[data-aos-delay="2750"] [data-aos], [data-aos][data-aos][data-aos-delay="2750"] {
  transition-delay: 0s;
}
body[data-aos-delay="2750"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2750"].aos-animate {
  transition-delay: 2750ms;
}
body[data-aos-duration="2800"] [data-aos], [data-aos][data-aos][data-aos-duration="2800"] {
  transition-duration: 2800ms;
}
body[data-aos-delay="2800"] [data-aos], [data-aos][data-aos][data-aos-delay="2800"] {
  transition-delay: 0s;
}
body[data-aos-delay="2800"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2800"].aos-animate {
  transition-delay: 2800ms;
}
body[data-aos-duration="2850"] [data-aos], [data-aos][data-aos][data-aos-duration="2850"] {
  transition-duration: 2850ms;
}
body[data-aos-delay="2850"] [data-aos], [data-aos][data-aos][data-aos-delay="2850"] {
  transition-delay: 0s;
}
body[data-aos-delay="2850"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2850"].aos-animate {
  transition-delay: 2850ms;
}
body[data-aos-duration="2900"] [data-aos], [data-aos][data-aos][data-aos-duration="2900"] {
  transition-duration: 2900ms;
}
body[data-aos-delay="2900"] [data-aos], [data-aos][data-aos][data-aos-delay="2900"] {
  transition-delay: 0s;
}
body[data-aos-delay="2900"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2900"].aos-animate {
  transition-delay: 2900ms;
}
body[data-aos-duration="2950"] [data-aos], [data-aos][data-aos][data-aos-duration="2950"] {
  transition-duration: 2950ms;
}
body[data-aos-delay="2950"] [data-aos], [data-aos][data-aos][data-aos-delay="2950"] {
  transition-delay: 0s;
}
body[data-aos-delay="2950"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2950"].aos-animate {
  transition-delay: 2950ms;
}
body[data-aos-duration="3000"] [data-aos], [data-aos][data-aos][data-aos-duration="3000"] {
  transition-duration: 3000ms;
}
body[data-aos-delay="3000"] [data-aos], [data-aos][data-aos][data-aos-delay="3000"] {
  transition-delay: 0s;
}
body[data-aos-delay="3000"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="3000"].aos-animate {
  transition-delay: 3000ms;
}

[data-aos] {
  pointer-events: none;
}
[data-aos].aos-animate {
  pointer-events: auto;
}

body[data-aos-easing=linear] [data-aos], [data-aos][data-aos][data-aos-easing=linear] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
}
body[data-aos-easing=ease] [data-aos], [data-aos][data-aos][data-aos-easing=ease] {
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}
body[data-aos-easing=ease-in] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in] {
  transition-timing-function: cubic-bezier(0.42, 0, 1, 1);
}
body[data-aos-easing=ease-out] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out] {
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1);
}
body[data-aos-easing=ease-in-out] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out] {
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}
body[data-aos-easing=ease-in-back] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-back] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
body[data-aos-easing=ease-out-back] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out-back] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
body[data-aos-easing=ease-in-out-back] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out-back] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
body[data-aos-easing=ease-in-sine] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-sine] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}
body[data-aos-easing=ease-out-sine] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out-sine] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}
body[data-aos-easing=ease-in-out-sine] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out-sine] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
body[data-aos-easing=ease-in-quad] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-quad] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
body[data-aos-easing=ease-out-quad] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out-quad] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
body[data-aos-easing=ease-in-out-quad] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out-quad] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
body[data-aos-easing=ease-in-cubic] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-cubic] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
body[data-aos-easing=ease-out-cubic] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out-cubic] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
body[data-aos-easing=ease-in-out-cubic] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out-cubic] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
body[data-aos-easing=ease-in-quart] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-quart] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
body[data-aos-easing=ease-out-quart] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out-quart] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
body[data-aos-easing=ease-in-out-quart] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out-quart] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

@media screen {
  html:not(.no-js) {
    /**
    * Fade animations:
    * fade
    * fade-up, fade-down, fade-left, fade-right
    * fade-up-right, fade-up-left, fade-down-right, fade-down-left
    */
    /**
    * Zoom animations:
    * zoom-in, zoom-in-up, zoom-in-down, zoom-in-left, zoom-in-right
    * zoom-out, zoom-out-up, zoom-out-down, zoom-out-left, zoom-out-right
    */
    /**
    * Slide animations
    */
    /**
    * Flip animations:
    * flip-left, flip-right, flip-up, flip-down
    */
  }
  html:not(.no-js) [data-aos^=fade][data-aos^=fade] {
    opacity: 0;
    transition-property: opacity, transform;
  }
  html:not(.no-js) [data-aos^=fade][data-aos^=fade].aos-animate {
    opacity: 1;
    transform: none;
  }
  html:not(.no-js) [data-aos=fade-up] {
    transform: translate3d(0, 100px, 0);
  }
  html:not(.no-js) [data-aos=fade-down] {
    transform: translate3d(0, -100px, 0);
  }
  html:not(.no-js) [data-aos=fade-right] {
    transform: translate3d(-100px, 0, 0);
  }
  html:not(.no-js) [data-aos=fade-left] {
    transform: translate3d(100px, 0, 0);
  }
  html:not(.no-js) [data-aos=fade-up-right] {
    transform: translate3d(-100px, 100px, 0);
  }
  html:not(.no-js) [data-aos=fade-up-left] {
    transform: translate3d(100px, 100px, 0);
  }
  html:not(.no-js) [data-aos=fade-down-right] {
    transform: translate3d(-100px, -100px, 0);
  }
  html:not(.no-js) [data-aos=fade-down-left] {
    transform: translate3d(100px, -100px, 0);
  }
  html:not(.no-js) [data-aos^=zoom][data-aos^=zoom] {
    opacity: 0;
    transition-property: opacity, transform;
  }
  html:not(.no-js) [data-aos^=zoom][data-aos^=zoom].aos-animate {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
  }
  html:not(.no-js) [data-aos=zoom-in] {
    transform: scale(0.6);
  }
  html:not(.no-js) [data-aos=zoom-in-up] {
    transform: translate3d(0, 100px, 0) scale(0.6);
  }
  html:not(.no-js) [data-aos=zoom-in-down] {
    transform: translate3d(0, -100px, 0) scale(0.6);
  }
  html:not(.no-js) [data-aos=zoom-in-right] {
    transform: translate3d(-100px, 0, 0) scale(0.6);
  }
  html:not(.no-js) [data-aos=zoom-in-left] {
    transform: translate3d(100px, 0, 0) scale(0.6);
  }
  html:not(.no-js) [data-aos=zoom-out] {
    transform: scale(1.2);
  }
  html:not(.no-js) [data-aos=zoom-out-up] {
    transform: translate3d(0, 100px, 0) scale(1.2);
  }
  html:not(.no-js) [data-aos=zoom-out-down] {
    transform: translate3d(0, -100px, 0) scale(1.2);
  }
  html:not(.no-js) [data-aos=zoom-out-right] {
    transform: translate3d(-100px, 0, 0) scale(1.2);
  }
  html:not(.no-js) [data-aos=zoom-out-left] {
    transform: translate3d(100px, 0, 0) scale(1.2);
  }
  html:not(.no-js) [data-aos^=slide][data-aos^=slide] {
    transition-property: transform;
    visibility: hidden;
  }
  html:not(.no-js) [data-aos^=slide][data-aos^=slide].aos-animate {
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }
  html:not(.no-js) [data-aos=slide-up] {
    transform: translate3d(0, 100%, 0);
  }
  html:not(.no-js) [data-aos=slide-down] {
    transform: translate3d(0, -100%, 0);
  }
  html:not(.no-js) [data-aos=slide-right] {
    transform: translate3d(-100%, 0, 0);
  }
  html:not(.no-js) [data-aos=slide-left] {
    transform: translate3d(100%, 0, 0);
  }
  html:not(.no-js) [data-aos^=flip][data-aos^=flip] {
    backface-visibility: hidden;
    transition-property: transform;
  }
  html:not(.no-js) [data-aos=flip-left] {
    transform: perspective(2500px) rotateY(-100deg);
  }
  html:not(.no-js) [data-aos=flip-left].aos-animate {
    transform: perspective(2500px) rotateY(0);
  }
  html:not(.no-js) [data-aos=flip-right] {
    transform: perspective(2500px) rotateY(100deg);
  }
  html:not(.no-js) [data-aos=flip-right].aos-animate {
    transform: perspective(2500px) rotateY(0);
  }
  html:not(.no-js) [data-aos=flip-up] {
    transform: perspective(2500px) rotateX(-100deg);
  }
  html:not(.no-js) [data-aos=flip-up].aos-animate {
    transform: perspective(2500px) rotateX(0);
  }
  html:not(.no-js) [data-aos=flip-down] {
    transform: perspective(2500px) rotateX(100deg);
  }
  html:not(.no-js) [data-aos=flip-down].aos-animate {
    transform: perspective(2500px) rotateX(0);
  }
}
/**
 * transition mixin
 * @type {map}
 */
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  letter-spacing: 0em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body:not(.loaded) {
  overflow-x: hidden;
}

@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}
html, body {
  min-height: 100%;
}

*:focus {
  outline: 0 !important;
}

a {
  transition: all 0.2s ease-in-out;
}

h1,
h2,
.h1,
.h2 {
  letter-spacing: -0.02em;
}

h3, h4,
h5,
h6,
.h3, .h4,
.h5,
.h6 {
  letter-spacing: -0.01em;
  font-weight: 500;
}

.ls-0 {
  letter-spacing: 0px;
}

.ls-1 {
  letter-spacing: 0.5px;
}

.ls-2 {
  letter-spacing: 1px;
}

.ls-3 {
  letter-spacing: 1.5px;
}

.ls-4 {
  letter-spacing: 2px;
}

.ls-5 {
  letter-spacing: 2.5px;
}

.ls-6 {
  letter-spacing: 3px;
}

.ls-7 {
  letter-spacing: 3.5px;
}

.ls-8 {
  letter-spacing: 4px;
}

.ls-9 {
  letter-spacing: 4.5px;
}

.text-strikethrough {
  text-decoration: line-through;
}

.font-serif {
  font-family: "Lora", serif !important;
  letter-spacing: 0;
  font-weight: 400;
}
.font-serif.display-6, .font-serif.display-5, .font-serif.display-4, .font-serif.display-3, .font-serif.display-2, .font-serif.display-1 {
  font-weight: 400;
}

.display-6, .display-5, .display-4, .display-3, .display-2, .display-1 {
  font-family: var(--bs-font-sans-serif);
}

.text-mono {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
  font-weight: 400;
}

.text-italic {
  font-style: italic;
}

sup, sub {
  letter-spacing: 0em;
}

.text-white h1,
.text-white .h1,
.text-white h2,
.text-white .h2,
.text-white h3,
.text-white .h3,
.text-white h4,
.text-white .h4,
.text-white h5,
.text-white .h5,
.text-white h6,
.text-white .h6,
.text-light h1,
.text-light .h1,
.text-light h2,
.text-light .h2,
.text-light h3,
.text-light .h3,
.text-light h4,
.text-light .h4,
.text-light h5,
.text-light .h5,
.text-light h6,
.text-light .h6 {
  color: #fff;
}
.text-white .text-muted,
.text-light .text-muted {
  color: rgba(255, 255, 255, 0.54) !important;
}
.text-white a:not(.btn):not(.dropdown-item):not([class^=text-]):not([class*=text-]):not(.nav-link),
.text-light a:not(.btn):not(.dropdown-item):not([class^=text-]):not([class*=text-]):not(.nav-link) {
  color: rgba(255, 255, 255, 0.7) !important;
  transition: all 0.2s ease-in-out;
}
.text-white a:not(.btn):not(.dropdown-item):not([class^=text-]):not([class*=text-]):not(.nav-link):hover:not(.active):not(.disabled):not(:disabled),
.text-light a:not(.btn):not(.dropdown-item):not([class^=text-]):not([class*=text-]):not(.nav-link):hover:not(.active):not(.disabled):not(:disabled) {
  color: white !important;
}
.text-white a.text-muted,
.text-light a.text-muted {
  color: rgba(255, 255, 255, 0.54) !important;
}
.text-white a.text-muted:hover:not(.active):not(.disabled):not(:disabled),
.text-light a.text-muted:hover:not(.active):not(.disabled):not(:disabled) {
  color: white !important;
}

footer:not(.text-white):not(.text-light) a:not(.btn):not(.dropdown-item):not(.text-muted) {
  color: #14191e !important;
  transition: all 0.2s ease-in-out;
}
footer:not(.text-white):not(.text-light) a:not(.btn):not(.dropdown-item):not(.text-muted):hover:not(.active):not(.disabled):not(:disabled) {
  color: #389769 !important;
}
footer:not(.text-white):not(.text-light) a.text-muted {
  color: #39414A !important;
}
footer:not(.text-white):not(.text-light) a.text-muted:hover:not(.active):not(.disabled):not(:disabled) {
  color: #0a0d10 !important;
}

a.text-dark:hover, a.text-muted:hover, a.text-secondary:hover, a.text-body:hover {
  color: #389769 !important;
}

.text-gradient {
  background: radial-gradient(circle at 70%, #4FC04D 5%, #389769 20%);
  background-size: 400% 400%;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  animation: textGradientAnimation 5s ease-in-out infinite;
  -webkit-animation: textGradientAnimation 5s ease-in-out infinite;
}

.text-gradient-light {
  background: radial-gradient(circle at 70%, #fff 5%, #9ccbb4 20%);
  background-size: 400% 400%;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  animation: textGradientAnimation 5s ease-in-out infinite;
  -webkit-animation: textGradientAnimation 5s ease-in-out infinite;
}

.dropcap::first-letter {
  float: left;
  line-height: 0.5;
  font-weight: 600;
  font-size: 2.5rem;
  margin: 0 6px 0 0;
  color: #389769;
}

.text-outline {
  -webkit-text-stroke-width: 2px;
  text-decoration: none !important;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  background: transparent;
  -webkit-text-fill-color: transparent;
}

.text-outline.text-primary {
  -webkit-text-stroke: 2px #389769;
  color: transparent !important;
}

.text-outline.text-secondary {
  -webkit-text-stroke: 2px #2B323A;
  color: transparent !important;
}

.text-outline.text-success {
  -webkit-text-stroke: 2px #4FC04D;
  color: transparent !important;
}

.text-outline.text-info {
  -webkit-text-stroke: 2px #0072ff;
  color: transparent !important;
}

.text-outline.text-warning {
  -webkit-text-stroke: 2px #FCC63B;
  color: transparent !important;
}

.text-outline.text-danger {
  -webkit-text-stroke: 2px #F15B66;
  color: transparent !important;
}

.text-outline.text-light {
  -webkit-text-stroke: 2px #F8FBFE;
  color: transparent !important;
}

.text-outline.text-white {
  -webkit-text-stroke: 2px #fff;
  color: transparent !important;
}

.text-outline.text-gray-200 {
  -webkit-text-stroke: 2px #F0F5FA;
  color: transparent !important;
}

.text-outline.text-gray-800 {
  -webkit-text-stroke: 2px #1F252C;
  color: transparent !important;
}

.text-outline.text-dark {
  -webkit-text-stroke: 2px #0A0D10;
  color: transparent !important;
}

b, strong {
  font-weight: 500;
}

.bx {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
}

@keyframes textGradientAnimation {
  0% {
    background-position: 50% 30%;
  }
  25% {
    background-position: 100% 10%;
  }
  50% {
    background-position: 100% 90%;
  }
  75% {
    background-position: 60% 90%;
  }
  100% {
    background-position: 50% 30%;
  }
}
@keyframes transform-x {
  0% {
    opacity: 0;
    transform: translateX(100%);
    animation-timing-function: ease;
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@keyframes transform-y {
  0% {
    opacity: 0;
    transform: translateY(100%);
    animation-timing-function: ease;
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes scaleIn {
  0% {
    transform: scaleX(0);
    transform-origin: left;
  }
  to {
    transform: scaleX(1);
    transform-origin: left;
  }
}
@keyframes scaleOut {
  0% {
    transform: scaleX(1);
    transform-origin: right;
  }
  to {
    transform: scaleX(0);
    transform-origin: right;
  }
}
@keyframes fadeDown {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    transform: none;
    opacity: 1;
  }
}
@keyframes underline-in {
  0% {
    transform: scaleX(0);
    transform-origin: left center;
  }
  to {
    transform: scaleX(1);
    transform-origin: left center;
  }
}
@keyframes underline-out {
  0% {
    transform: scaleX(1);
    transform-origin: right center;
  }
  to {
    transform: scaleX(0);
    transform-origin: right center;
  }
}
@keyframes underline-out-in {
  0% {
    transform: scaleX(1);
    transform-origin: right center;
  }
  50% {
    transform: scaleX(0);
    transform-origin: right center;
  }
  51% {
    transform: scaleX(0);
    transform-origin: left center;
  }
  to {
    transform: scaleX(1);
    transform-origin: left center;
  }
}
@keyframes underline-both {
  to {
    transform: translate3d(101%, 0, 0);
  }
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes connectLine {
  0% {
    transform-origin: 0% 0%;
    transform: scaleY(0);
  }
  50% {
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
  51% {
    transform-origin: 0 100%;
    transform: scaleY(1);
  }
  100% {
    transform-origin: 0 100%;
    transform: scaleY(0);
  }
}
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(1.5);
    opacity: 0;
  }
}
@keyframes slide-bg-2 {
  33.3% {
    transform-origin: left top;
    transform: scale(0.05, 1);
  }
  66.6% {
    transform-origin: left center;
    transform: scale(1, 1);
  }
  66.7% {
    transform-origin: right center;
    transform: scale(1, 1);
  }
  100% {
    transform-origin: right center;
    transform: scale(0, 1);
  }
}
@keyframes show-img-1 {
  0%, 66.6% {
    visibility: hidden;
  }
  100%, 66.7% {
    visibility: visible;
  }
}
@keyframes bounce {
  0% {
    transform: translateY(0rem);
  }
  50% {
    transform: translateY(-1rem);
  }
  100% {
    transform: translateY(0rem);
  }
}
@keyframes ripple {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
@keyframes ripple-2 {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.7);
    opacity: 0;
  }
}
.accordion-button {
  font-weight: 500;
}

.accordion.accordion-custom .accordion-item {
  border: 0px;
}
.accordion.accordion-custom .accordion-body {
  padding-top: 0;
}
.accordion.accordion-custom .accordion-button {
  box-shadow: 0 0.5rem 1rem rgba(10, 13, 16, 0.1);
  transition: all 0.25s;
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-color: #fff;
  border-radius: 0.375rem;
}
.accordion.accordion-custom .accordion-button::after {
  background-image: none;
  font-weight: 400;
  font-family: boxicons !important;
  transform: rotate(90deg);
  content: "";
  font-size: 0.875rem;
  color: currentColor;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1rem;
  height: 1rem;
  text-align: center;
  line-height: 1;
  box-sizing: content-box;
}
.accordion.accordion-custom .accordion-collapse, .accordion.accordion-custom .accordion-button:not(.collapsed) {
  background-color: #ebf5f0;
}
.accordion.accordion-custom .accordion-collapse {
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
.accordion.accordion-custom .accordion-button:not(.collapsed) {
  box-shadow: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.accordion.accordion-custom .accordion-button:not(.collapsed)::after {
  background-image: none;
  transform: rotate(0);
  color: #389769;
}

.avatar {
  width: 3rem;
  height: 3rem;
}
.avatar.xs {
  width: 1.75rem;
  height: 1.75rem;
}
.avatar.sm {
  width: 2rem;
  height: 2rem;
}
.avatar.lg {
  width: 4rem;
  height: 4rem;
}
.avatar.xl {
  width: 5rem;
  height: 5rem;
}

.avatar-status {
  position: relative;
  display: inline-block;
}
.avatar-status::after {
  content: "";
  position: absolute;
  bottom: 5%;
  right: 5%;
  width: 20%;
  height: 20%;
  border: 2px solid #fff;
  border-radius: 50%;
}
.avatar-status.off::after {
  background-color: #E5EBF1;
}
.avatar-status.on::after {
  background-color: #47ad45;
}
.avatar-status.away::after {
  background-color: #e3b235;
}
.avatar-status.dnd::after {
  background-color: #F93131;
}
.avatar-status:not(.rounded-pill):not(.rounded-circle)::after {
  margin-right: -0.4rem;
  margin-bottom: -0.4rem;
}
.avatar-status.rounded-pill > img {
  border-radius: 50rem;
}
.avatar-status.rounded-circle > img {
  border-radius: 100%;
}
.avatar-status.rounded-3 > img {
  border-radius: 0.575rem;
}
.avatar-status.rounded-2 > img {
  border-radius: 0.375rem;
}
.avatar-status.rounded-1 > img {
  border-radius: 0.25rem;
}

.avatar-group {
  display: flex;
  align-items: stretch;
}
.avatar-group .avatar-group-item > img {
  border-radius: 100%;
}
.avatar-group .avatar-group-item:not(:first-child) {
  position: relative;
  box-shadow: 0px 2px 4px rgba(10, 13, 16, 0.125);
}
.avatar-group .avatar-group-item.avatar.xs:not(:first-child) {
  margin-left: -0.5rem;
}
.avatar-group .avatar-group-item.avatar.sm:not(:first-child) {
  margin-left: -0.75rem;
}
.avatar-group .avatar-group-item.avatar:not(:first-child):not(.xs):not(.sm):not(.lg):not(.xl) {
  margin-left: -1rem;
}

.breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  font-family: boxicons !important;
  font-size: 10px;
  font-weight: normal;
  color: currentColor;
  opacity: 0.35;
  display: inline-block;
  vertical-align: middle;
  float: none;
}
.breadcrumb .breadcrumb-item.active {
  color: rgba(20, 25, 30, 0.6);
}

.text-white .breadcrumb-item, .text-light .breadcrumb-item {
  color: #fff;
}
.text-white .breadcrumb-item.active, .text-light .breadcrumb-item.active {
  color: rgba(255, 255, 255, 0.5);
}
.text-white .breadcrumb-item + .breadcrumb-item::before, .text-light .breadcrumb-item + .breadcrumb-item::before {
  color: rgba(255, 255, 255, 0.7);
}

.showMore-link {
  display: inline-block;
}
.showMore-link[aria-expanded=false]::after {
  content: "show more...";
}
.showMore-link[aria-expanded=true]::after {
  content: "show less...";
}

.collapse .search-collapse {
  background-color: white;
}

.collapse-link {
  position: relative;
  display: inline-block;
}
.collapse-link::before {
  content: "+";
  width: 0.5rem;
  font-weight: 600;
  text-align: center;
  display: inline-block;
}
.collapse-link[aria-expanded=true]::before {
  content: "-";
}

.collapse-group .nav-link[data-bs-toggle=collapse],
.collapse-group [data-bs-toggle=collapse] {
  position: relative;
  display: block;
  color: #14191E;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.collapse-group .nav-link[data-bs-toggle=collapse]::after,
.collapse-group [data-bs-toggle=collapse]::after {
  border: 0px;
  margin-top: 0px;
  content: "";
  position: absolute;
  top: 50%;
  right: 0.5rem;
  width: 0.75rem;
  height: 0.75rem;
  transform: translateY(-50%) rotate(180deg);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: boxicons !important;
  font-style: normal;
  font-weight: 400 !important;
  box-sizing: content-box;
  font-size: 0.875rem;
  transition: all 0.2s;
  will-change: transform;
}
.collapse-group .nav-link[data-bs-toggle=collapse][aria-expanded=true],
.collapse-group [data-bs-toggle=collapse][aria-expanded=true] {
  color: #389769;
}
.collapse-group .nav-link[data-bs-toggle=collapse][aria-expanded=true]::after,
.collapse-group [data-bs-toggle=collapse][aria-expanded=true]::after {
  transform: translateY(-50%) rotate(0deg);
}
.collapse-group .nav.nav-level-2.flex-column {
  padding-left: 0.5rem;
}
.collapse-group .nav.nav-level-3.flex-column {
  padding-left: 1rem;
}
.collapse-group .nav.nav-level-2.flex-column > .nav-link,
.collapse-group .nav.nav-level-3.flex-column > .nav-link {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

.text-white .collapse-group .nav-link[data-bs-toggle=collapse][aria-expanded=true],
.text-white .collapse-group [data-bs-toggle=collapse][aria-expanded=true],
.bg-dark .collapse-group .nav-link[data-bs-toggle=collapse][aria-expanded=true],
.bg-dark .collapse-group [data-bs-toggle=collapse][aria-expanded=true],
.text-light .collapse-group .nav-link[data-bs-toggle=collapse][aria-expanded=true],
.text-light .collapse-group [data-bs-toggle=collapse][aria-expanded=true] {
  color: rgba(255, 255, 255, 0.65);
}

.hover-lift, .hover-lift-lg {
  will-change: transform;
  transition: all 0.3s ease-in-out;
}

.hover-lift:hover {
  transform: translate(0, -5px);
}

.hover-lift-lg:hover {
  transform: translate(0, -20px);
}

.img-zoom, .img-zoom-out {
  transform: scale(1);
  transform-origin: center;
  transition: transform 1.8s cubic-bezier(0.2, 1, 0.2, 1);
}

.card-over, .card-hover {
  position: relative;
  z-index: 1;
  will-change: transform;
}
.card-over:not(.row):not([class~=col-]):not(.d-block):not(.card), .card-hover:not(.row):not([class~=col-]):not(.d-block):not(.card) {
  display: block;
  color: currentColor;
}

.card-overlay {
  will-change: transform;
  transition: opacity 1.1s cubic-bezier(0.5, 0, 0, 1) 0.3s, transform 1.1s cubic-bezier(0.5, 0, 0, 1) 0.3s;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
}
.card-overlay::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
  z-index: -1;
  filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  background: linear-gradient(0deg, rgba(10, 13, 16, 0.8) 0, rgba(10, 13, 16, 0.35) 25%, rgba(10, 13, 16, 0) 50%);
  transition: background-color, opacity 1.2s ease;
}
.card-overlay.overlay-center {
  align-items: center;
  justify-content: center;
  text-align: center;
}
.card-overlay.overlay-center::before {
  background: linear-gradient(-180deg, rgba(79, 192, 77, 0.75) 0, rgba(79, 192, 77, 0.8) 0, rgba(56, 151, 105, 0.875) 110%);
  transition: background-color, opacity 1.2s ease;
}
.card-overlay .overlay-items {
  position: relative;
  margin: 0;
  overflow: hidden;
  font-weight: 400;
  color: currentColor;
}
.card-overlay .overlay-items li {
  transition: opacity 0.25s ease 0.2s, visibility 0.25s ease 0.2s, transform 0.25s ease 0.2s;
  display: block;
  position: relative;
  opacity: 1;
  filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

.card-hover .card-overlay::before,
.card-split-hover .card-overlay::before {
  opacity: 0;
}
.card-hover .card-overlay .overlay-items,
.card-split-hover .card-overlay .overlay-items {
  position: relative;
}
.card-hover .card-overlay .overlay-items li,
.card-split-hover .card-overlay .overlay-items li {
  opacity: 0;
  transform: translate3d(0, 30px, 0);
}
.card-hover:hover .card-overlay::before,
.card-split-hover:hover .card-overlay::before {
  opacity: 1;
}
.card-hover:hover .overlay-items li,
.card-split-hover:hover .overlay-items li {
  opacity: 1;
  transform: translateZ(0);
  transition-duration: 0.6s;
}
.card-hover:hover .overlay-items li:nth-child(1),
.card-split-hover:hover .overlay-items li:nth-child(1) {
  transition-delay: 100ms;
}
.card-hover:hover .overlay-items li:nth-child(2),
.card-split-hover:hover .overlay-items li:nth-child(2) {
  transition-delay: 200ms;
}
.card-hover:hover .overlay-items li:nth-child(3),
.card-split-hover:hover .overlay-items li:nth-child(3) {
  transition-delay: 300ms;
}
.card-hover:hover .overlay-items li:nth-child(4),
.card-split-hover:hover .overlay-items li:nth-child(4) {
  transition-delay: 400ms;
}
.card-hover:hover .overlay-items li:nth-child(5),
.card-split-hover:hover .overlay-items li:nth-child(5) {
  transition-delay: 500ms;
}
.card-hover:hover .overlay-items li:nth-child(6),
.card-split-hover:hover .overlay-items li:nth-child(6) {
  transition-delay: 600ms;
}
.card-hover:hover .overlay-items li:nth-child(7),
.card-split-hover:hover .overlay-items li:nth-child(7) {
  transition-delay: 700ms;
}
.card-hover:hover .overlay-items li:nth-child(8),
.card-split-hover:hover .overlay-items li:nth-child(8) {
  transition-delay: 800ms;
}
.card-hover:hover .overlay-items li:nth-child(9),
.card-split-hover:hover .overlay-items li:nth-child(9) {
  transition-delay: 900ms;
}
.card-hover:hover .overlay-items li:nth-child(10),
.card-split-hover:hover .overlay-items li:nth-child(10) {
  transition-delay: 1000ms;
}
.card-hover:hover .overlay-items li:nth-child(11),
.card-split-hover:hover .overlay-items li:nth-child(11) {
  transition-delay: 1100ms;
}
.card-hover:hover .overlay-items li:nth-child(12),
.card-split-hover:hover .overlay-items li:nth-child(12) {
  transition-delay: 1200ms;
}
.card-hover:hover .overlay-items li:nth-child(13),
.card-split-hover:hover .overlay-items li:nth-child(13) {
  transition-delay: 1300ms;
}
.card-hover:hover .overlay-items li:nth-child(14),
.card-split-hover:hover .overlay-items li:nth-child(14) {
  transition-delay: 1400ms;
}
.card-hover:hover .overlay-items li:nth-child(15),
.card-split-hover:hover .overlay-items li:nth-child(15) {
  transition-delay: 1500ms;
}
.card-hover:hover .overlay-items li:nth-child(16),
.card-split-hover:hover .overlay-items li:nth-child(16) {
  transition-delay: 1600ms;
}
.card-hover:hover .overlay-items li:nth-child(17),
.card-split-hover:hover .overlay-items li:nth-child(17) {
  transition-delay: 1700ms;
}
.card-hover:hover .overlay-items li:nth-child(18),
.card-split-hover:hover .overlay-items li:nth-child(18) {
  transition-delay: 1800ms;
}
.card-hover:hover .overlay-items li:nth-child(19),
.card-split-hover:hover .overlay-items li:nth-child(19) {
  transition-delay: 1900ms;
}
.card-hover:hover .overlay-items li:nth-child(20),
.card-split-hover:hover .overlay-items li:nth-child(20) {
  transition-delay: 2000ms;
}
.card-hover:hover .overlay-items li:nth-child(21),
.card-split-hover:hover .overlay-items li:nth-child(21) {
  transition-delay: 2100ms;
}

.card-hover,
.card-over,
.card-hover-2 {
  will-change: transform;
}
.card-hover:hover .img-zoom,
.card-over:hover .img-zoom,
.card-hover-2:hover .img-zoom {
  transform: scale(1.085);
}
.card-hover:hover .img-zoom-out,
.card-over:hover .img-zoom-out,
.card-hover-2:hover .img-zoom-out {
  transform: scale(0.95);
}

.card-split-hover {
  will-change: transform;
}
.card-split-hover .card-overlay {
  opacity: 0;
  transition: all 0.15s;
}
.card-split-hover:hover .card-overlay {
  opacity: 1;
}

.hover-pulse {
  animation: pulse 1.5s infinite forwards;
}

.infinite {
  animation-iteration-count: infinite;
}

.card-hover:hover .link-multiline,
.card-hover-2:hover .link-multiline {
  background-size: 100% 1px;
}

.card-hover-2 {
  background-color: #0A0D10;
}

.card-hover-2-overlay {
  transition: all 0.25s ease-out;
}

.card-hover-2-header p {
  transform: translateY(100%);
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s cubic-bezier(0.42, 0.01, 0.23, 1);
}

.card-hover-2-footer {
  position: relative;
}
.card-hover-2-footer .card-hover-2-footer-link {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.card-hover-2-footer .tags {
  -ms-transform: translateY(0%);
  transform: translateY(0%);
  opacity: 1;
  transition: opacity 0.3s, transform 0.3s cubic-bezier(0.42, 0.01, 0.23, 1);
}

.card-hover-2-footer-link {
  display: flex;
  width: 100%;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
}
.card-hover-2-footer-link span {
  display: block;
  opacity: 0;
  transform: translateY(100%);
  transition: opacity 0.3s, transform 0.3s cubic-bezier(0.42, 0.01, 0.23, 1);
}
.card-hover-2-footer-link::after {
  content: "";
  width: 24px;
  height: 24px;
  display: block;
  background-size: 24px 24px;
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 0.3s, transform 0.3s cubic-bezier(0.42, 0.01, 0.23, 1);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF' viewBox='0 0 24 24'%3E%3Cpath d='M22 12l-4-4v3H3v2h15v3z'/%3E%3C/svg%3E");
}

.card-hover-2 img {
  transition: opacity 0.25s, transform 0.4s;
}
.card-hover-2:hover img {
  opacity: 0.5;
}
.card-hover-2:hover .card-hover-2-overlay {
  transition: all 0.6s ease-in;
}
.card-hover-2:hover .card-hover-2-header p {
  opacity: 1;
  transform: translateY(0%);
}
.card-hover-2:hover .card-hover-2-footer .tags {
  opacity: 0;
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
}
.card-hover-2:hover .card-hover-2-footer .card-hover-2-footer-link span {
  opacity: 1;
  transform: translateY(0);
}
.card-hover-2:hover .card-hover-2-footer .card-hover-2-footer-link::after {
  opacity: 1;
  transform: translateX(0);
}

.card-reveal-effect {
  visibility: hidden;
  overflow: hidden;
  position: relative;
  transition-property: transform;
  will-change: transform;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}
.card-reveal-effect a {
  display: block;
}
.card-reveal-effect > * {
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  position: relative;
  animation-fill-mode: forwards;
}
.card-reveal-effect::after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: left top;
  transform: scale(0.05, 0);
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  background-color: currentColor;
}

.card-reveal-effect.aos-animate, .is-inview .card-reveal-effect, .aos-animate .card-reveal-effect {
  transform: translate(0, 0);
  visibility: visible;
}
.card-reveal-effect.aos-animate > *, .is-inview .card-reveal-effect > *, .aos-animate .card-reveal-effect > * {
  animation-name: show-img-1;
}
.card-reveal-effect.aos-animate:after, .is-inview .card-reveal-effect:after, .aos-animate .card-reveal-effect:after {
  animation-name: slide-bg-2;
}

.bg-pattern {
  background-image: radial-gradient(circle, currentColor 1px, rgba(0, 0, 0, 0) 1px);
  background-size: 1rem 1rem;
}

img.bg-image {
  object-fit: cover;
}

.bg-image {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.bg-image:not([class*=position-absolute]):not([class*=position-relative]) {
  position: absolute;
}
.bg-image + * {
  position: relative;
  z-index: 1;
}

.bg-gradient-primary {
  background-image: linear-gradient(95.4deg, #389769, #4FC04D);
}

.bg-gradient-secondary {
  background-image: radial-gradient(200% 160% at 75% 130%, #2B323A 0%, #14191e 100%);
}

.bg-gradient-light {
  background-image: radial-gradient(200% 160% at 75% 130%, #fff 0%, #f8fbfe 100%);
}

.bg-gradient-white {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), to(#fff));
  mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), to(#fff));
  -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), to(#fff));
}

.bg-gradient-dark {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(10, 13, 16, 0)), to(#0a0d10));
  mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(10, 13, 16, 0)), to(#0a0d10));
  -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(10, 13, 16, 0)), to(#0a0d10));
}

.bg-gradient-tint {
  background-image: radial-gradient(200% 160% at 75% 130%, #f4fbf4, #f7fbf9);
}

.bg-tint-primary {
  background-color: #d7eae1 !important;
}

.bg-tint-secondary {
  background-color: #d5d6d8 !important;
}

.bg-tint-success {
  background-color: #dcf2db !important;
}

.bg-tint-info {
  background-color: #cce3ff !important;
}

.bg-tint-warning {
  background-color: #fef4d8 !important;
}

.bg-tint-danger {
  background-color: #fcdee0 !important;
}

.bg-tint-light {
  background-color: #fefeff !important;
}

.bg-tint-white {
  background-color: white !important;
}

.bg-tint-gray-200 {
  background-color: #fcfdfe !important;
}

.bg-tint-gray-800 {
  background-color: #d2d3d5 !important;
}

.bg-tint-dark {
  background-color: #cecfcf !important;
}

.bg-shade-primary {
  background-color: #0e261a !important;
}

.bg-shade-secondary {
  background-color: #0b0d0f !important;
}

.bg-shade-success {
  background-color: #143013 !important;
}

.bg-shade-info {
  background-color: #001d40 !important;
}

.bg-shade-warning {
  background-color: #3f320f !important;
}

.bg-shade-danger {
  background-color: #3c171a !important;
}

.bg-shade-light {
  background-color: #3e3f40 !important;
}

.bg-shade-white {
  background-color: #404040 !important;
}

.bg-shade-gray-200 {
  background-color: #3c3d3f !important;
}

.bg-shade-gray-800 {
  background-color: #08090b !important;
}

.bg-shade-dark {
  background-color: #030304 !important;
}

.bg-overlay-gradient {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
}
.bg-overlay-gradient::before, .bg-overlay-gradient::after {
  content: "";
  position: absolute;
  z-index: -1;
}
.bg-overlay-gradient::after {
  content: "";
  top: 0;
  background: radial-gradient(50% 50% at 50% 50%, #ebf5f0 0, rgba(204, 244, 100, 0) 78.74%);
  display: block;
  position: absolute;
  width: 118vh;
  height: 118vh;
  right: 20vh;
  transform: translate(50%, -50%);
}
.bg-overlay-gradient::before {
  background: radial-gradient(50% 50% at 50% 50%, #edf9ed 0, rgba(248, 205, 209, 0) 78.74%);
  position: absolute;
  display: block;
  content: "";
  top: -60%;
  left: -90%;
  width: 200%;
  padding-bottom: 200%;
}

.badge {
  line-height: 1.3;
}
.badge:not(.bg-light):not(.bg-white):not(.bg-gradient-light) {
  color: #fff;
}
.badge.badge-pill {
  border-radius: 50rem;
}

.btn {
  text-transform: capitalize;
  letter-spacing: 0.005em;
  font-weight: 400;
}

.btn-primary {
  color: #fff;
  background-color: #389769;
  border-color: #389769;
}
.btn-primary:hover {
  color: #fff;
  background-color: #308059;
  border-color: #2d7954;
  box-shadow: 0 20px 25px -5px rgba(86, 167, 128, 0.125);
}
.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #308059;
  border-color: #2d7954;
  box-shadow: 0 20px 25px -5px rgba(86, 167, 128, 0.125);
}
.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #2d7954;
  border-color: #2a714f;
}
.btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 20px 25px -5px rgba(86, 167, 128, 0.125);
}
.btn-primary:disabled, .btn-primary.disabled {
  color: #fff;
  background-color: #389769;
  border-color: #389769;
}

.btn-secondary {
  color: #fff;
  background-color: #2B323A;
  border-color: #2B323A;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #252b31;
  border-color: #22282e;
  box-shadow: 0 20px 25px -5px rgba(75, 81, 88, 0.125);
}
.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #fff;
  background-color: #252b31;
  border-color: #22282e;
  box-shadow: 0 20px 25px -5px rgba(75, 81, 88, 0.125);
}
.btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #22282e;
  border-color: #20262c;
}
.btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 20px 25px -5px rgba(75, 81, 88, 0.125);
}
.btn-secondary:disabled, .btn-secondary.disabled {
  color: #fff;
  background-color: #2B323A;
  border-color: #2B323A;
}

.btn-success {
  color: #fff;
  background-color: #4FC04D;
  border-color: #4FC04D;
}
.btn-success:hover {
  color: #fff;
  background-color: #43a341;
  border-color: #3f9a3e;
  box-shadow: 0 20px 25px -5px rgba(105, 201, 104, 0.125);
}
.btn-check:focus + .btn-success, .btn-success:focus {
  color: #fff;
  background-color: #43a341;
  border-color: #3f9a3e;
  box-shadow: 0 20px 25px -5px rgba(105, 201, 104, 0.125);
}
.btn-check:checked + .btn-success, .btn-check:active + .btn-success, .btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #3f9a3e;
  border-color: #3b903a;
}
.btn-check:checked + .btn-success:focus, .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 20px 25px -5px rgba(105, 201, 104, 0.125);
}
.btn-success:disabled, .btn-success.disabled {
  color: #fff;
  background-color: #4FC04D;
  border-color: #4FC04D;
}

.btn-info {
  color: #fff;
  background-color: #0072ff;
  border-color: #0072ff;
}
.btn-info:hover {
  color: #fff;
  background-color: #0061d9;
  border-color: #005bcc;
  box-shadow: 0 20px 25px -5px rgba(38, 135, 255, 0.125);
}
.btn-check:focus + .btn-info, .btn-info:focus {
  color: #fff;
  background-color: #0061d9;
  border-color: #005bcc;
  box-shadow: 0 20px 25px -5px rgba(38, 135, 255, 0.125);
}
.btn-check:checked + .btn-info, .btn-check:active + .btn-info, .btn-info:active, .btn-info.active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #005bcc;
  border-color: #0056bf;
}
.btn-check:checked + .btn-info:focus, .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 20px 25px -5px rgba(38, 135, 255, 0.125);
}
.btn-info:disabled, .btn-info.disabled {
  color: #fff;
  background-color: #0072ff;
  border-color: #0072ff;
}

.btn-warning {
  color: #0A0D10;
  background-color: #FCC63B;
  border-color: #FCC63B;
}
.btn-warning:hover {
  color: #0A0D10;
  background-color: #fccf58;
  border-color: #fccc4f;
  box-shadow: 0 20px 25px -5px rgba(216, 170, 53, 0.125);
}
.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #0A0D10;
  background-color: #fccf58;
  border-color: #fccc4f;
  box-shadow: 0 20px 25px -5px rgba(216, 170, 53, 0.125);
}
.btn-check:checked + .btn-warning, .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active, .show > .btn-warning.dropdown-toggle {
  color: #0A0D10;
  background-color: #fdd162;
  border-color: #fccc4f;
}
.btn-check:checked + .btn-warning:focus, .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 20px 25px -5px rgba(216, 170, 53, 0.125);
}
.btn-warning:disabled, .btn-warning.disabled {
  color: #0A0D10;
  background-color: #FCC63B;
  border-color: #FCC63B;
}

.btn-danger {
  color: #fff;
  background-color: #F15B66;
  border-color: #F15B66;
}
.btn-danger:hover {
  color: #fff;
  background-color: #cd4d57;
  border-color: #c14952;
  box-shadow: 0 20px 25px -5px rgba(243, 116, 125, 0.125);
}
.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #fff;
  background-color: #cd4d57;
  border-color: #c14952;
  box-shadow: 0 20px 25px -5px rgba(243, 116, 125, 0.125);
}
.btn-check:checked + .btn-danger, .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #c14952;
  border-color: #b5444d;
}
.btn-check:checked + .btn-danger:focus, .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 20px 25px -5px rgba(243, 116, 125, 0.125);
}
.btn-danger:disabled, .btn-danger.disabled {
  color: #fff;
  background-color: #F15B66;
  border-color: #F15B66;
}

.btn-light {
  color: #0A0D10;
  background-color: #F8FBFE;
  border-color: #F8FBFE;
}
.btn-light:hover {
  color: #0A0D10;
  background-color: #f9fcfe;
  border-color: #f9fbfe;
  box-shadow: 0 20px 25px -5px rgba(212, 215, 218, 0.125);
}
.btn-check:focus + .btn-light, .btn-light:focus {
  color: #0A0D10;
  background-color: #f9fcfe;
  border-color: #f9fbfe;
  box-shadow: 0 20px 25px -5px rgba(212, 215, 218, 0.125);
}
.btn-check:checked + .btn-light, .btn-check:active + .btn-light, .btn-light:active, .btn-light.active, .show > .btn-light.dropdown-toggle {
  color: #0A0D10;
  background-color: #f9fcfe;
  border-color: #f9fbfe;
}
.btn-check:checked + .btn-light:focus, .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 20px 25px -5px rgba(212, 215, 218, 0.125);
}
.btn-light:disabled, .btn-light.disabled {
  color: #0A0D10;
  background-color: #F8FBFE;
  border-color: #F8FBFE;
}

.btn-white {
  color: #0A0D10;
  background-color: #fff;
  border-color: #fff;
}
.btn-white:hover {
  color: #0A0D10;
  background-color: white;
  border-color: white;
  box-shadow: 0 20px 25px -5px rgba(218, 219, 219, 0.125);
}
.btn-check:focus + .btn-white, .btn-white:focus {
  color: #0A0D10;
  background-color: white;
  border-color: white;
  box-shadow: 0 20px 25px -5px rgba(218, 219, 219, 0.125);
}
.btn-check:checked + .btn-white, .btn-check:active + .btn-white, .btn-white:active, .btn-white.active, .show > .btn-white.dropdown-toggle {
  color: #0A0D10;
  background-color: white;
  border-color: white;
}
.btn-check:checked + .btn-white:focus, .btn-check:active + .btn-white:focus, .btn-white:active:focus, .btn-white.active:focus, .show > .btn-white.dropdown-toggle:focus {
  box-shadow: 0 20px 25px -5px rgba(218, 219, 219, 0.125);
}
.btn-white:disabled, .btn-white.disabled {
  color: #0A0D10;
  background-color: #fff;
  border-color: #fff;
}

.btn-gray-200 {
  color: #0A0D10;
  background-color: #F0F5FA;
  border-color: #F0F5FA;
}
.btn-gray-200:hover {
  color: #0A0D10;
  background-color: #f2f7fb;
  border-color: #f2f6fb;
  box-shadow: 0 20px 25px -5px rgba(206, 210, 215, 0.125);
}
.btn-check:focus + .btn-gray-200, .btn-gray-200:focus {
  color: #0A0D10;
  background-color: #f2f7fb;
  border-color: #f2f6fb;
  box-shadow: 0 20px 25px -5px rgba(206, 210, 215, 0.125);
}
.btn-check:checked + .btn-gray-200, .btn-check:active + .btn-gray-200, .btn-gray-200:active, .btn-gray-200.active, .show > .btn-gray-200.dropdown-toggle {
  color: #0A0D10;
  background-color: #f3f7fb;
  border-color: #f2f6fb;
}
.btn-check:checked + .btn-gray-200:focus, .btn-check:active + .btn-gray-200:focus, .btn-gray-200:active:focus, .btn-gray-200.active:focus, .show > .btn-gray-200.dropdown-toggle:focus {
  box-shadow: 0 20px 25px -5px rgba(206, 210, 215, 0.125);
}
.btn-gray-200:disabled, .btn-gray-200.disabled {
  color: #0A0D10;
  background-color: #F0F5FA;
  border-color: #F0F5FA;
}

.btn-gray-800 {
  color: #fff;
  background-color: #1F252C;
  border-color: #1F252C;
}
.btn-gray-800:hover {
  color: #fff;
  background-color: #1a1f25;
  border-color: #191e23;
  box-shadow: 0 20px 25px -5px rgba(65, 70, 76, 0.125);
}
.btn-check:focus + .btn-gray-800, .btn-gray-800:focus {
  color: #fff;
  background-color: #1a1f25;
  border-color: #191e23;
  box-shadow: 0 20px 25px -5px rgba(65, 70, 76, 0.125);
}
.btn-check:checked + .btn-gray-800, .btn-check:active + .btn-gray-800, .btn-gray-800:active, .btn-gray-800.active, .show > .btn-gray-800.dropdown-toggle {
  color: #fff;
  background-color: #191e23;
  border-color: #171c21;
}
.btn-check:checked + .btn-gray-800:focus, .btn-check:active + .btn-gray-800:focus, .btn-gray-800:active:focus, .btn-gray-800.active:focus, .show > .btn-gray-800.dropdown-toggle:focus {
  box-shadow: 0 20px 25px -5px rgba(65, 70, 76, 0.125);
}
.btn-gray-800:disabled, .btn-gray-800.disabled {
  color: #fff;
  background-color: #1F252C;
  border-color: #1F252C;
}

.btn-dark {
  color: #fff;
  background-color: #0A0D10;
  border-color: #0A0D10;
}
.btn-dark:hover {
  color: #fff;
  background-color: #090b0e;
  border-color: #080a0d;
  box-shadow: 0 20px 25px -5px rgba(47, 49, 52, 0.125);
}
.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #fff;
  background-color: #090b0e;
  border-color: #080a0d;
  box-shadow: 0 20px 25px -5px rgba(47, 49, 52, 0.125);
}
.btn-check:checked + .btn-dark, .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #080a0d;
  border-color: #080a0c;
}
.btn-check:checked + .btn-dark:focus, .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 20px 25px -5px rgba(47, 49, 52, 0.125);
}
.btn-dark:disabled, .btn-dark.disabled {
  color: #fff;
  background-color: #0A0D10;
  border-color: #0A0D10;
}

.btn-outline-primary {
  color: #389769;
  border-color: #389769;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #389769;
  border-color: #389769;
}
.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 20px 25px -5px rgba(56, 151, 105, 0.125);
}
.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #389769;
  border-color: #389769;
}
.btn-check:checked + .btn-outline-primary:focus, .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 20px 25px -5px rgba(56, 151, 105, 0.125);
}
.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #389769;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #2B323A;
  border-color: #2B323A;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #2B323A;
  border-color: #2B323A;
}
.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 20px 25px -5px rgba(43, 50, 58, 0.125);
}
.btn-check:checked + .btn-outline-secondary, .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #fff;
  background-color: #2B323A;
  border-color: #2B323A;
}
.btn-check:checked + .btn-outline-secondary:focus, .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 20px 25px -5px rgba(43, 50, 58, 0.125);
}
.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #2B323A;
  background-color: transparent;
}

.btn-outline-success {
  color: #4FC04D;
  border-color: #4FC04D;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #4FC04D;
  border-color: #4FC04D;
}
.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 20px 25px -5px rgba(79, 192, 77, 0.125);
}
.btn-check:checked + .btn-outline-success, .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #fff;
  background-color: #4FC04D;
  border-color: #4FC04D;
}
.btn-check:checked + .btn-outline-success:focus, .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 20px 25px -5px rgba(79, 192, 77, 0.125);
}
.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #4FC04D;
  background-color: transparent;
}

.btn-outline-info {
  color: #0072ff;
  border-color: #0072ff;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #0072ff;
  border-color: #0072ff;
}
.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 20px 25px -5px rgba(0, 114, 255, 0.125);
}
.btn-check:checked + .btn-outline-info, .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #fff;
  background-color: #0072ff;
  border-color: #0072ff;
}
.btn-check:checked + .btn-outline-info:focus, .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 20px 25px -5px rgba(0, 114, 255, 0.125);
}
.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #0072ff;
  background-color: transparent;
}

.btn-outline-warning {
  color: #FCC63B;
  border-color: #FCC63B;
}
.btn-outline-warning:hover {
  color: #0A0D10;
  background-color: #FCC63B;
  border-color: #FCC63B;
}
.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 20px 25px -5px rgba(252, 198, 59, 0.125);
}
.btn-check:checked + .btn-outline-warning, .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #0A0D10;
  background-color: #FCC63B;
  border-color: #FCC63B;
}
.btn-check:checked + .btn-outline-warning:focus, .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 20px 25px -5px rgba(252, 198, 59, 0.125);
}
.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #FCC63B;
  background-color: transparent;
}

.btn-outline-danger {
  color: #F15B66;
  border-color: #F15B66;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #F15B66;
  border-color: #F15B66;
}
.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 20px 25px -5px rgba(241, 91, 102, 0.125);
}
.btn-check:checked + .btn-outline-danger, .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #fff;
  background-color: #F15B66;
  border-color: #F15B66;
}
.btn-check:checked + .btn-outline-danger:focus, .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 20px 25px -5px rgba(241, 91, 102, 0.125);
}
.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #F15B66;
  background-color: transparent;
}

.btn-outline-light {
  color: #F8FBFE;
  border-color: #F8FBFE;
}
.btn-outline-light:hover {
  color: #0A0D10;
  background-color: #F8FBFE;
  border-color: #F8FBFE;
}
.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 20px 25px -5px rgba(248, 251, 254, 0.125);
}
.btn-check:checked + .btn-outline-light, .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #0A0D10;
  background-color: #F8FBFE;
  border-color: #F8FBFE;
}
.btn-check:checked + .btn-outline-light:focus, .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 20px 25px -5px rgba(248, 251, 254, 0.125);
}
.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #F8FBFE;
  background-color: transparent;
}

.btn-outline-white {
  color: #fff;
  border-color: #fff;
}
.btn-outline-white:hover {
  color: #0A0D10;
  background-color: #fff;
  border-color: #fff;
}
.btn-check:focus + .btn-outline-white, .btn-outline-white:focus {
  box-shadow: 0 20px 25px -5px rgba(255, 255, 255, 0.125);
}
.btn-check:checked + .btn-outline-white, .btn-check:active + .btn-outline-white, .btn-outline-white:active, .btn-outline-white.active, .btn-outline-white.dropdown-toggle.show {
  color: #0A0D10;
  background-color: #fff;
  border-color: #fff;
}
.btn-check:checked + .btn-outline-white:focus, .btn-check:active + .btn-outline-white:focus, .btn-outline-white:active:focus, .btn-outline-white.active:focus, .btn-outline-white.dropdown-toggle.show:focus {
  box-shadow: 0 20px 25px -5px rgba(255, 255, 255, 0.125);
}
.btn-outline-white:disabled, .btn-outline-white.disabled {
  color: #fff;
  background-color: transparent;
}

.btn-outline-gray-200 {
  color: #F0F5FA;
  border-color: #F0F5FA;
}
.btn-outline-gray-200:hover {
  color: #0A0D10;
  background-color: #F0F5FA;
  border-color: #F0F5FA;
}
.btn-check:focus + .btn-outline-gray-200, .btn-outline-gray-200:focus {
  box-shadow: 0 20px 25px -5px rgba(240, 245, 250, 0.125);
}
.btn-check:checked + .btn-outline-gray-200, .btn-check:active + .btn-outline-gray-200, .btn-outline-gray-200:active, .btn-outline-gray-200.active, .btn-outline-gray-200.dropdown-toggle.show {
  color: #0A0D10;
  background-color: #F0F5FA;
  border-color: #F0F5FA;
}
.btn-check:checked + .btn-outline-gray-200:focus, .btn-check:active + .btn-outline-gray-200:focus, .btn-outline-gray-200:active:focus, .btn-outline-gray-200.active:focus, .btn-outline-gray-200.dropdown-toggle.show:focus {
  box-shadow: 0 20px 25px -5px rgba(240, 245, 250, 0.125);
}
.btn-outline-gray-200:disabled, .btn-outline-gray-200.disabled {
  color: #F0F5FA;
  background-color: transparent;
}

.btn-outline-gray-800 {
  color: #1F252C;
  border-color: #1F252C;
}
.btn-outline-gray-800:hover {
  color: #fff;
  background-color: #1F252C;
  border-color: #1F252C;
}
.btn-check:focus + .btn-outline-gray-800, .btn-outline-gray-800:focus {
  box-shadow: 0 20px 25px -5px rgba(31, 37, 44, 0.125);
}
.btn-check:checked + .btn-outline-gray-800, .btn-check:active + .btn-outline-gray-800, .btn-outline-gray-800:active, .btn-outline-gray-800.active, .btn-outline-gray-800.dropdown-toggle.show {
  color: #fff;
  background-color: #1F252C;
  border-color: #1F252C;
}
.btn-check:checked + .btn-outline-gray-800:focus, .btn-check:active + .btn-outline-gray-800:focus, .btn-outline-gray-800:active:focus, .btn-outline-gray-800.active:focus, .btn-outline-gray-800.dropdown-toggle.show:focus {
  box-shadow: 0 20px 25px -5px rgba(31, 37, 44, 0.125);
}
.btn-outline-gray-800:disabled, .btn-outline-gray-800.disabled {
  color: #1F252C;
  background-color: transparent;
}

.btn-outline-dark {
  color: #0A0D10;
  border-color: #0A0D10;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #0A0D10;
  border-color: #0A0D10;
}
.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 20px 25px -5px rgba(10, 13, 16, 0.125);
}
.btn-check:checked + .btn-outline-dark, .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #0A0D10;
  border-color: #0A0D10;
}
.btn-check:checked + .btn-outline-dark:focus, .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 20px 25px -5px rgba(10, 13, 16, 0.125);
}
.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #0A0D10;
  background-color: transparent;
}

.btn-rise {
  overflow: hidden;
  position: relative;
  transition: all 0.3s;
  box-shadow: none;
  z-index: 0;
}
.btn-rise.p-0 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
}
.btn-rise .btn-rise-bg {
  z-index: -1;
  display: block;
  width: 125%;
  height: 100%;
  position: absolute;
  background-color: currentColor;
  top: 0;
  left: 50%;
  pointer-events: none;
  transform: translate(-50%, 105%);
  border-radius: 50%;
  transition: transform 0.65s cubic-bezier(0.77, 0, 0.175, 1), border-radius 0.65s cubic-bezier(0.77, 0, 0.175, 1);
}
.btn-rise .btn-rise-text {
  position: relative;
  transition: color 0.4s;
}
.btn-rise[class^=btn-outline-]:hover, .btn-rise[class^=btn-outline-]:focus, .btn-rise[class^=btn-outline-]:active, .btn-rise[class^=btn-outline-].active, .btn-rise[class*=btn-outline-]:hover, .btn-rise[class*=btn-outline-]:focus, .btn-rise[class*=btn-outline-]:active, .btn-rise[class*=btn-outline-].active {
  background-color: transparent;
}
.btn-rise:hover:not(:disabled):not(.disabled) .btn-rise-bg, .btn-rise.active:not(:disabled):not(.disabled) .btn-rise-bg, .btn-rise.focus:not(:disabled):not(.disabled) .btn-rise-bg {
  transform: translate(-50%, 0);
  border-radius: 0;
}

.btn-hover-arrow {
  position: relative;
  overflow: hidden;
}
.btn-hover-arrow span {
  display: inline-block;
  transform: translate3d(12px, 0, 0);
  transition: transform 0.3s cubic-bezier(0.42, 0.01, 0.23, 1);
}
.btn-hover-arrow::after {
  vertical-align: middle;
  content: "";
  font-family: boxicons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  line-height: 24px;
  font-size: 1.25rem;
  width: 24px;
  height: 24px;
  display: inline-block;
  opacity: 0;
  transform: translate3d(24px, 0, 0);
  transition: opacity 0.3s, transform 0.4s cubic-bezier(0.42, 0.01, 0.23, 1);
}
.btn-hover-arrow:hover span {
  transform: translatez(0px);
}
.btn-hover-arrow:hover::after {
  transform: translate3d(6px, 0, 0);
  opacity: 1;
}
.btn-hover-arrow.btn-lg::after, .btn-group-lg > .btn-hover-arrow.btn::after {
  font-size: 1.375rem;
}
.btn-hover-arrow.btn-lg:hover span, .btn-group-lg > .btn-hover-arrow.btn:hover span {
  transform: translate3d(-6px, 0, 0);
}
.btn-hover-arrow.btn-sm::after, .btn-group-sm > .btn-hover-arrow.btn::after {
  font-size: 1.125rem;
}
.btn-hover-arrow.btn-sm:hover span, .btn-group-sm > .btn-hover-arrow.btn:hover span {
  transform: translateZ(0px);
}

.btn-circle-ripple {
  position: relative;
}
.btn-circle-ripple::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  box-shadow: 0 0 0 1px currentColor;
  animation: pulse 2s infinite ease-in-out;
}

.btn-circle-ripple.btn-primary::before,
.btn-circle-ripple.btn-outline-primary::before {
  box-shadow: 0 0 0 1px #389769 !important;
}

.btn-circle-ripple.btn-secondary::before,
.btn-circle-ripple.btn-outline-secondary::before {
  box-shadow: 0 0 0 1px #2B323A !important;
}

.btn-circle-ripple.btn-success::before,
.btn-circle-ripple.btn-outline-success::before {
  box-shadow: 0 0 0 1px #4FC04D !important;
}

.btn-circle-ripple.btn-info::before,
.btn-circle-ripple.btn-outline-info::before {
  box-shadow: 0 0 0 1px #0072ff !important;
}

.btn-circle-ripple.btn-warning::before,
.btn-circle-ripple.btn-outline-warning::before {
  box-shadow: 0 0 0 1px #FCC63B !important;
}

.btn-circle-ripple.btn-danger::before,
.btn-circle-ripple.btn-outline-danger::before {
  box-shadow: 0 0 0 1px #F15B66 !important;
}

.btn-circle-ripple.btn-light::before,
.btn-circle-ripple.btn-outline-light::before {
  box-shadow: 0 0 0 1px #F8FBFE !important;
}

.btn-circle-ripple.btn-white::before,
.btn-circle-ripple.btn-outline-white::before {
  box-shadow: 0 0 0 1px #fff !important;
}

.btn-circle-ripple.btn-gray-200::before,
.btn-circle-ripple.btn-outline-gray-200::before {
  box-shadow: 0 0 0 1px #F0F5FA !important;
}

.btn-circle-ripple.btn-gray-800::before,
.btn-circle-ripple.btn-outline-gray-800::before {
  box-shadow: 0 0 0 1px #1F252C !important;
}

.btn-circle-ripple.btn-dark::before,
.btn-circle-ripple.btn-outline-dark::before {
  box-shadow: 0 0 0 1px #0A0D10 !important;
}

.btn-hover-text {
  position: relative;
}
.btn-hover-text .btn-hover-label.label-default {
  display: block;
  transition: transform 0.5s cubic-bezier(0.5, 0, 0, 1), opacity 0.5s cubic-bezier(0.5, 0, 0, 1);
  transition-delay: 0.1s;
}
.btn-hover-text .btn-hover-label.label-hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(1vw);
  opacity: 0;
  transition: transform 0.5s cubic-bezier(0.5, 0, 0, 1), opacity 0.5s cubic-bezier(0.5, 0, 0, 1);
  transition-delay: 0s;
}
.btn-hover-text:hover .btn-hover-label.label-default {
  transform: translateY(-1vw);
  opacity: 0;
  transition-delay: 0s;
}
.btn-hover-text:hover .btn-hover-label.label-hover {
  transform: translateY(0);
  opacity: 1;
  transition-delay: 0.1s;
}

.modal-header .btn-close {
  padding: 0;
  right: 0;
  top: 0;
  margin: 0;
}

.btn-close {
  width: 32px;
  height: 32px;
  background-image: none;
  opacity: 1;
}

.nav .nav-link:not(.disabled) {
  color: #14191E;
}
.nav .nav-link:not(.disabled).active, .nav .nav-link:not(.disabled):hover {
  color: #389769;
}
.nav.nav-pills .nav-link.active {
  color: #fff;
}
.nav.nav-pills .nav-link.disabled {
  color: #39414A;
}

.nav.flex-column .nav-link {
  padding-left: 0;
}

.nav.nav-filter .nav-link {
  position: relative;
  padding: 0;
  color: #14191E;
}
.nav.nav-filter .nav-link.active, .nav.nav-filter .nav-link:hover {
  color: #39414A;
}
.nav.nav-filter .nav-link.active {
  text-decoration: line-through;
}
.nav.nav-filter:not(.flex-column) .nav-item:not(:last-child) {
  margin-right: 1rem;
}

.nav-overflow {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 1px;
}
.nav-overflow::-webkit-scrollbar {
  display: none;
}

.text-white .nav .nav-link,
.text-light .nav .nav-link {
  color: rgba(255, 255, 255, 0.75);
}
.text-white .nav .nav-link.active, .text-white .nav .nav-link:hover,
.text-light .nav .nav-link.active,
.text-light .nav .nav-link:hover {
  color: #fff;
}
.text-white .nav.nav-filter .nav-link.active,
.text-light .nav.nav-filter .nav-link.active {
  text-decoration: line-through;
}

.nav-tabs-vertical {
  flex-direction: column;
  flex-shrink: 0;
  flex: 1;
}

.nav.nav-pills.nav-pills-white .nav-link.active {
  background-color: #fff;
  color: #389769;
}

.navbar .navbar-nav .nav-link {
  text-transform: capitalize;
  font-weight: 500;
  font-size: 1rem;
  font-family: var(--bs-font-sans-serif);
  letter-spacing: 0.005em;
}
.navbar:not(.position-fixed):not(.position-absolute):not(.fixed-top) {
  position: relative;
}
.navbar .dropdown-menu {
  z-index: 1022;
}

.navbar-no-collapse-items .btn {
  white-space: nowrap;
}
.navbar-no-collapse-items .nav-link {
  padding: 0.5rem 0;
  color: rgba(10, 13, 16, 0.55);
}
.navbar-no-collapse-items .nav-link:hover {
  color: rgba(10, 13, 16, 0.7);
}

.navbar-link-white .navbar-no-collapse-items .nav-link,
.navbar-dark .navbar-no-collapse-items .nav-link {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-link-white .navbar-no-collapse-items .nav-link:hover, .navbar-link-white .navbar-no-collapse-items .nav-link:focus,
.navbar-dark .navbar-no-collapse-items .nav-link:hover,
.navbar-dark .navbar-no-collapse-items .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-link-white .navbar-no-collapse-items .nav-link.active, .navbar-link-white .navbar-no-collapse-items .nav-link.show,
.navbar-dark .navbar-no-collapse-items .nav-link.active,
.navbar-dark .navbar-no-collapse-items .nav-link.show {
  color: #fff;
}

.scroll-up .navbar-light.navbar-link-white .navbar-no-collapse-items .nav-link,
.is-sticky .navbar-light.navbar-link-white .navbar-no-collapse-items .nav-link {
  color: rgba(10, 13, 16, 0.55);
}
.scroll-up .navbar-light.navbar-link-white .navbar-no-collapse-items .nav-link:hover, .scroll-up .navbar-light.navbar-link-white .navbar-no-collapse-items .nav-link:focus,
.is-sticky .navbar-light.navbar-link-white .navbar-no-collapse-items .nav-link:hover,
.is-sticky .navbar-light.navbar-link-white .navbar-no-collapse-items .nav-link:focus {
  color: rgba(10, 13, 16, 0.7);
}
.scroll-up .navbar-light.navbar-link-white .navbar-no-collapse-items .nav-link.active, .scroll-up .navbar-light.navbar-link-white .navbar-no-collapse-items .nav-link.show,
.is-sticky .navbar-light.navbar-link-white .navbar-no-collapse-items .nav-link.active,
.is-sticky .navbar-light.navbar-link-white .navbar-no-collapse-items .nav-link.show {
  color: rgba(10, 13, 16, 0.9);
}

.header-transparent .navbar {
  background-color: transparent;
  border-bottom: 0px;
}

.header-absolute-top {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1030;
}

.header-fixed-top {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1030;
}

.navbar-brand {
  width: 5.5rem;
  display: block;
  position: relative;
}
.navbar-brand .navbar-brand-transparent,
.navbar-brand .navbar-brand-sticky {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
}

@media (min-width: 992px) {
  header.header-absolute-top .navbar {
    max-width: calc(100vw - 17px);
  }

  header.header-center-logo .navbar-brand {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .navbar.navbar-brand-center .navbar-brand {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  header.sticky-reverse.scroll-up .navbar.navbar-expand-lg, header.header-sticky.is-sticky .navbar.navbar-expand-lg {
    border-radius: 0px;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1030;
    width: 100%;
    transition: all 0.3s;
    animation: fadeDown 0.4s both;
    border-width: 1px;
  }
  header.sticky-reverse.scroll-up .navbar.navbar-expand-lg.navbar-light, header.header-sticky.is-sticky .navbar.navbar-expand-lg.navbar-light {
    animation: fadeDown 0.4s both;
    border-bottom-color: #F0F5FA;
    box-shadow: 0 16px 32px 0 rgba(10, 13, 16, 0.06);
    backdrop-filter: saturate(180%) blur(20px);
    background-color: rgba(255, 255, 255, 0.95);
  }
  header.sticky-reverse.scroll-up .navbar.navbar-expand-lg.navbar-dark, header.header-sticky.is-sticky .navbar.navbar-expand-lg.navbar-dark {
    backdrop-filter: saturate(180%) blur(20px);
    background-color: rgba(10, 13, 16, 0.85);
    animation: fadeDown 0.4s both;
    box-shadow: 0 16px 32px 0 rgba(10, 13, 16, 0.0975);
  }

  header.header-boxed.is-sticky .navbar-boxed, header.header-boxed.scroll-up .navbar-boxed {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1030;
    padding: 0.5rem 0;
  }
  header.header-boxed.is-sticky .navbar-boxed .navbar.navbar-expand-lg, header.header-boxed.scroll-up .navbar-boxed .navbar.navbar-expand-lg {
    position: relative;
    padding: 0 1rem;
  }

  header:not(.scroll-up):not(.is-sticky):not(.is-fixed) .navbar-light.navbar-link-white .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.55);
  }
  header:not(.scroll-up):not(.is-sticky):not(.is-fixed) .navbar-light.navbar-link-white .navbar-nav .nav-link:hover, header:not(.scroll-up):not(.is-sticky):not(.is-fixed) .navbar-light.navbar-link-white .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75);
  }
  header:not(.scroll-up):not(.is-sticky):not(.is-fixed) .navbar-light.navbar-link-white .navbar-nav .nav-link.active {
    color: #fff;
  }

  .navbar-expand-lg .navbar-brand {
    width: 5.5rem;
  }
  .navbar-expand-lg.navbar {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-lg .nav-item > .nav-link,
.navbar-expand-lg .navbar-no-collapse-items .nav-item {
    height: 4.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .navbar-expand-lg .navbar-no-collapse-items .nav-item .nav-link {
    height: auto;
  }
  .navbar-expand-lg .nav-item:not(:first-child) {
    margin-left: 0.3rem;
  }

  header .navbar-link-white .navbar-brand .navbar-brand-sticky {
    opacity: 0;
    visibility: hidden;
  }
  header.scroll-up .navbar-link-white .navbar-brand .navbar-brand-sticky, header.is-sticky .navbar-link-white .navbar-brand .navbar-brand-sticky {
    opacity: 1;
    visibility: visible;
  }
  header.scroll-up .navbar-link-white .navbar-brand .navbar-brand-transparent, header.is-sticky .navbar-link-white .navbar-brand .navbar-brand-transparent {
    opacity: 0;
    visibility: hidden;
  }
}
@media (max-width: 991.98px) {
  header.sticky-reverse .navbar-link-white .navbar-brand .navbar-brand-sticky {
    opacity: 0;
    visibility: hidden;
  }

  .navbar-expand-lg.navbar .navbar-collapse {
    max-height: 90vh;
    overflow-y: auto;
    margin-top: 0.5rem;
    border-bottom-left-radius: 0.575rem;
    border-bottom-right-radius: 0.575rem;
  }
  .navbar-expand-lg.navbar .navbar-collapse > .navbar-nav {
    padding: 1rem;
  }
  .navbar-expand-lg.navbar.navbar-light .navbar-collapse {
    background-color: #fff;
    box-shadow: 0 0.5rem 1rem rgba(10, 13, 16, 0.1);
  }
  .navbar-expand-lg.navbar.navbar-dark .navbar-collapse {
    background-color: #0A0D10;
  }
}
.navbar-dark .navbar-toggler,
.navbar-link-white .navbar-toggler {
  color: #fff;
}

.navbar-toggler {
  padding: 0;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
}
.navbar-toggler:focus {
  outline: 0;
  box-shadow: none;
}
.navbar-toggler .navbar-toggler-icon {
  background-image: none;
  width: 24px;
  height: 24px;
  position: relative;
  display: inline-block;
  transition: all 0.5s ease;
  vertical-align: middle;
  font-size: 0;
  cursor: pointer;
}
.navbar-toggler .navbar-toggler-icon > i {
  width: 100%;
  height: 2px;
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -1px;
  background-color: currentColor;
  transition: background-color 0s 0.3s;
}
.navbar-toggler .navbar-toggler-icon > i::before, .navbar-toggler .navbar-toggler-icon > i::after {
  content: "";
  width: 100%;
  height: 2px;
  position: absolute;
  display: block;
  left: 0;
  background-color: currentColor;
}
.navbar-toggler .navbar-toggler-icon > i::before {
  top: 8px;
  transition: top 0.3s 0.3s, transform 0.3s 0s, background-color 0.3s 0s;
}
.navbar-toggler .navbar-toggler-icon > i::after {
  bottom: 8px;
  transition: bottom 0.3s 0.3s, transform 0.3s 0s, background-color 0.3s 0s;
}
.navbar-toggler[aria-expanded=true] .navbar-toggler-icon > i {
  background-color: transparent;
  transition: background-color 0s 0.2s;
}
.navbar-toggler[aria-expanded=true] .navbar-toggler-icon > i:before {
  top: 0;
  transform: rotate(45deg);
  transition: top 0.2s 0s, transform 0.3s 0.2s, background-color 0.2s 0s;
}
.navbar-toggler[aria-expanded=true] .navbar-toggler-icon > i::after {
  bottom: 0;
  transition: bottom 0.2s 0s, transform 0.2s 0.2s, background-color 0.2s 0s;
  transform: rotate(-45deg);
}

header.header-transparent.sticky-fixed .navbar {
  padding: 20px 0;
  transition: transform 0.5s cubic-bezier(0.55, 0.04, 0.43, 1);
}
header.header-transparent.sticky-fixed .navbar .navbar-fixed-bg {
  transform: translateY(-100%);
  transition: transform 400ms cubic-bezier(0.215, 0.61, 0.355, 1);
  height: calc(100% - 10px);
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}
header.header-transparent.sticky-fixed.is-fixed .navbar {
  transform: translateY(-10px);
}
header.header-transparent.sticky-fixed.is-fixed .navbar .navbar-fixed-bg {
  transform: translateY(0);
  transition: transform 400ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
header.header-transparent.sticky-fixed.is-fixed .navbar-light .navbar-fixed-bg {
  background-color: rgba(255, 255, 255, 0.985);
}
header.header-transparent.sticky-fixed.is-fixed .navbar-light.navbar-link-white .navbar-brand > .navbar-brand-transparent {
  opacity: 0;
  visibility: hidden;
}
header.header-transparent.sticky-fixed.is-fixed .navbar-light.navbar-link-white .navbar-brand > .navbar-brand-sticky {
  opacity: 1;
  visibility: visible;
}
header.header-transparent.sticky-fixed.is-fixed .navbar-light .navbar-toggler .navbar-toggler-icon > i {
  color: rgba(10, 13, 16, 0.55);
}
header.header-transparent.sticky-fixed.is-fixed .navbar-dark .navbar-fixed-bg {
  background-color: rgba(10, 13, 16, 0.985);
}

.dropdown-menu {
  box-shadow: 0 1rem 3rem rgba(10, 13, 16, 0.125);
}

.dropdown-item {
  font-family: var(--bs-font-sans-serif);
  transition: all 0.2s ease-in-out;
  text-transform: capitalize;
  letter-spacing: 0em;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 0.375rem;
}

.text-white .dropdown-divider,
.bg-dark .dropdown-divider,
.dropdown-menu-dark .dropdown-divider {
  opacity: 0.2;
}

.dropdown-menu .dropdown-header {
  text-transform: capitalize;
}

.navbar-no-collapse-items .dropdown-menu.dropdown-menu-xs {
  min-width: 300px;
  width: 300px;
}

@media (min-width: 992px) {
  .navbar .dropdown-menu-end {
    right: 0;
    left: auto;
  }

  .navbar .dropdown-menu {
    display: block;
    visibility: hidden;
    transform: translateY(16px);
    opacity: 0;
  }
  .navbar .dropdown-menu.show {
    transition: opacity 0.15s, transform 0.25s cubic-bezier(0.25, 0.5, 0.75, 1);
    transform: none;
    opacity: 1;
    visibility: visible;
  }

  .dropdown-menu-fw {
    width: auto;
    max-width: none;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    right: 0;
  }
  .dropdown-menu-fw.dropdown-menu[data-bs-popper] {
    right: 0;
  }

  .navbar-boxed .navbar .dropdown-menu-fw {
    margin-left: 0;
    margin-right: 0;
  }

  .dropdown-menu-md {
    width: 720px;
  }

  .dropdown-menu-sm {
    width: 540px;
  }

  .navbar-no-collapse-items .dropdown-menu.dropdown-menu-xs {
    min-width: 360px;
    width: 360px;
  }

  .dropdown-menu-xs {
    width: 360px;
  }
}
@media (min-width: 1200px) {
  .navbar .dropdown-menu-lg {
    width: auto;
    max-width: none;
  }
  .navbar .dropdown-menu-lg.dropdown-menu-end[data-bs-popper] {
    right: 0;
    margin-right: 0.75rem;
  }
  .navbar .dropdown-menu-md {
    width: 720px;
  }
  .navbar .dropdown-menu-sm {
    width: 540px;
  }
  .navbar .dropdown-menu-xs {
    width: 340px;
  }
}
@media (max-width: 991.98px) {
  .navbar.navbar-expand-lg .dropdown-menu {
    box-shadow: none;
    padding-top: 0;
    border-radius: 0;
  }
  .navbar.navbar-expand-lg .dropend .dropdown-menu,
.navbar.navbar-expand-lg .dropstart .dropdown-menu {
    opacity: 1;
    position: static;
    padding-top: 0.25rem;
    padding-right: 0;
    padding-left: 1rem;
    padding-bottom: 0;
  }
  .navbar.navbar-expand-lg .nav-item.dropdown {
    position: static;
  }
  .navbar.navbar-expand-lg .navbar-no-collapse-items .dropdown-menu {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
  }
}
.dropend .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0;
}

.dropstart .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-left: 0;
}

.dropdown-menu.dropdown-menu-xxs {
  max-width: 160px;
  min-width: 160px;
}

.swiper-classic {
  height: 440px;
}
.swiper-classic .swiper-slide {
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 992px) {
  .swiper-classic {
    height: 700px;
  }
}
.carousel-layers li {
  transition: opacity 0.3s, transform cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.35s;
  overflow: hidden;
  height: auto;
  display: block;
  opacity: 0;
}
.carousel-layers [data-carousel-layer=fade-up] {
  transform: translate3d(0, 50px, 0);
}
.carousel-layers [data-carousel-layer=fade-down] {
  transform: translate3d(0, -50px, 0);
}
.carousel-layers [data-carousel-layer=fade-start] {
  transform: translate3d(-50px, 0, 0);
}
.carousel-layers [data-carousel-layer=fade-end] {
  transform: translate3d(50px, 0, 0);
}
.carousel-layers [data-carousel-layer=zoom-in] {
  transform: translate3d(0, -50px, 0) scale(0.6);
}

.carousel-item.active .carousel-layers li,
.swiper-slide-active .carousel-layers li {
  transform: translateZ(0) scale(1);
  opacity: 1;
  transition-duration: 800ms;
  transition-delay: 300ms;
}
.carousel-item.active .carousel-layers li:nth-child(2),
.swiper-slide-active .carousel-layers li:nth-child(2) {
  transition-delay: 600ms;
}
.carousel-item.active .carousel-layers li:nth-child(3),
.swiper-slide-active .carousel-layers li:nth-child(3) {
  transition-delay: 800ms;
}
.carousel-item.active .carousel-layers li:nth-child(4),
.swiper-slide-active .carousel-layers li:nth-child(4) {
  transition-delay: 1s;
}

.swiper-parallax-bg .swiper-slide {
  height: 75vh;
  display: flex;
  align-items: center;
}
.swiper-parallax-bg .parallax-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 135%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.swiper-progress .swiper-pagination-progressbar {
  bottom: 0;
  top: auto;
  height: 6px;
  background-color: rgba(255, 255, 255, 0.125);
}
.swiper-progress .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background-color: rgba(255, 255, 255, 0.5);
}

.swiper-vertical {
  height: 100%;
  width: 100%;
}
.swiper-vertical .swiper-slide {
  display: flex;
  align-items: center;
  height: 100%;
}

.swiper-pagination .swiper-pagination-bullet {
  opacity: 0.5;
  background-color: currentColor;
  border-radius: 100px;
  transition: opacity 0.3s, width 0.3s ease-in-out;
  position: relative;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 0.25rem;
}
.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  opacity: 1;
  width: 1.5rem;
}
.swiper-pagination .swiper-pagination-bullet:not(:first-child) {
  margin-left: 0.5rem;
}

.swiper-button-next,
.swiper-button-prev {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border-radius: 2.5rem;
  background-color: #cde5da;
  color: #0A0D10;
  transition: all 0.25s;
}
.swiper-button-next:focus,
.swiper-button-prev:focus {
  outline: none !important;
}
.swiper-button-next::after, .swiper-button-next::before,
.swiper-button-prev::after,
.swiper-button-prev::before {
  content: "";
  font-family: inherit;
}
.swiper-button-next::after,
.swiper-button-prev::after {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 10px;
  height: 2px;
  background: currentColor;
  transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  transform: translate(-50%, -50%) scaleX(0.5);
  transform-origin: right;
  opacity: 0;
}
.swiper-button-next::before,
.swiper-button-prev::before {
  border: solid currentColor;
  border-width: 0 2px 2px 0;
  display: inline-block;
  width: 8px;
  height: 8px;
  position: relative;
  transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1);
  transform-origin: center;
  transform: rotate(-45deg);
}

.swiper-button-prev::before {
  transform: rotate(135deg);
}
.swiper-button-prev::after {
  transform-origin: left;
}

.swiper-button-next:not(.swiper-button-disabled):hover::after {
  opacity: 1;
  transform: translate(-50%, -50%) scaleX(1);
  transition: transform 0.8s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.7s cubic-bezier(0.19, 1, 0.22, 1);
}
.swiper-button-next:not(.swiper-button-disabled):hover::before {
  transform: translate(2px, 0) rotate(-45deg);
}

.swiper-button-prev:not(.swiper-button-disabled):hover::after {
  opacity: 1;
  transform: translate(-50%, -50%) scaleX(1);
  transition: transform 0.8s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.7s cubic-bezier(0.19, 1, 0.22, 1);
}
.swiper-button-prev:not(.swiper-button-disabled):hover::before {
  transform: translate(-2px, 0) rotate(135deg);
}

.text-white .swiper-pagination .swiper-pagination-bullet,
.text-light .swiper-pagination .swiper-pagination-bullet {
  background-color: white;
}

.swiper-thumbs .swiper-slide {
  transition: opacity 0.3s;
  opacity: 0.35;
  cursor: pointer;
}
.swiper-thumbs .swiper-slide img {
  width: 100%;
  max-width: 90px;
  height: auto;
  display: block;
  margin: 0 auto;
}
.swiper-thumbs .swiper-slide.swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-slide-thumb-active .swiper-pagination-progress-bar-inner {
  transition-property: width;
  transition-timing-function: linear;
  width: 100%;
}

.swiper-pagination-progress {
  cursor: pointer;
}

.swiper-pagination-progress-bar {
  background-color: rgba(10, 13, 16, 0.125);
  height: 4px;
  position: relative;
}

.swiper-pagination-progress-bar-inner {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 0;
  height: 100%;
  background-color: #0A0D10;
  transition: none;
}

.text-white .swiper-pagination-progress-bar {
  background-color: rgba(255, 255, 255, 0.25);
}

.text-white .swiper-pagination-progress-bar-inner {
  background-color: #fff;
}

.spinner-loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

body.loaded .spinner-loader {
  opacity: 0;
  visibility: hidden;
  transition: 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

.form-control.text-white::placeholder, input.text-white::placeholder, select.text-white::placeholder, textarea.text-white::placeholder {
  color: rgba(255, 255, 255, 0.65);
}

.input-icon-group {
  position: relative;
}
.input-icon-group .form-control {
  padding-left: 3rem;
}
.input-icon-group .form-control-lg {
  padding-left: 3.5rem;
}
.input-icon-group .form-control-sm {
  padding-left: 2.25rem;
}
.input-icon-group .input-icon {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
}
.input-icon-group .input-icon::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 1px;
  height: 100%;
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(240, 245, 250, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
}
.input-icon-group .input-icon > i {
  font-size: 1rem;
  opacity: 0.3;
}
.input-icon-group.input-icon-group-sm .input-icon {
  width: 2rem;
}
.input-icon-group.input-icon-group-sm .input-icon > i {
  font-size: 0.875rem;
}
.input-icon-group.input-icon-group-lg .input-icon {
  width: 3rem;
}
.input-icon-group.input-icon-group-lg .input-icon > i {
  font-size: 1.25rem;
}

.form-switch > .form-check-input {
  height: 1.5rem;
}

.fill-primary *, .fill-primary {
  fill: #389769 !important;
}

.stroke-primary, .stroke-primary * {
  stroke: #389769 !important;
}

.text-outline-primary {
  color: transparent !important;
  -webkit-text-stroke: 1px #389769;
}

.fill-secondary *, .fill-secondary {
  fill: #2B323A !important;
}

.stroke-secondary, .stroke-secondary * {
  stroke: #2B323A !important;
}

.text-outline-secondary {
  color: transparent !important;
  -webkit-text-stroke: 1px #2B323A;
}

.fill-success *, .fill-success {
  fill: #4FC04D !important;
}

.stroke-success, .stroke-success * {
  stroke: #4FC04D !important;
}

.text-outline-success {
  color: transparent !important;
  -webkit-text-stroke: 1px #4FC04D;
}

.fill-info *, .fill-info {
  fill: #0072ff !important;
}

.stroke-info, .stroke-info * {
  stroke: #0072ff !important;
}

.text-outline-info {
  color: transparent !important;
  -webkit-text-stroke: 1px #0072ff;
}

.fill-warning *, .fill-warning {
  fill: #FCC63B !important;
}

.stroke-warning, .stroke-warning * {
  stroke: #FCC63B !important;
}

.text-outline-warning {
  color: transparent !important;
  -webkit-text-stroke: 1px #FCC63B;
}

.fill-danger *, .fill-danger {
  fill: #F15B66 !important;
}

.stroke-danger, .stroke-danger * {
  stroke: #F15B66 !important;
}

.text-outline-danger {
  color: transparent !important;
  -webkit-text-stroke: 1px #F15B66;
}

.fill-light *, .fill-light {
  fill: #F8FBFE !important;
}

.stroke-light, .stroke-light * {
  stroke: #F8FBFE !important;
}

.text-outline-light {
  color: transparent !important;
  -webkit-text-stroke: 1px #F8FBFE;
}

.fill-white *, .fill-white {
  fill: #fff !important;
}

.stroke-white, .stroke-white * {
  stroke: #fff !important;
}

.text-outline-white {
  color: transparent !important;
  -webkit-text-stroke: 1px #fff;
}

.fill-gray-200 *, .fill-gray-200 {
  fill: #F0F5FA !important;
}

.stroke-gray-200, .stroke-gray-200 * {
  stroke: #F0F5FA !important;
}

.text-outline-gray-200 {
  color: transparent !important;
  -webkit-text-stroke: 1px #F0F5FA;
}

.fill-gray-800 *, .fill-gray-800 {
  fill: #1F252C !important;
}

.stroke-gray-800, .stroke-gray-800 * {
  stroke: #1F252C !important;
}

.text-outline-gray-800 {
  color: transparent !important;
  -webkit-text-stroke: 1px #1F252C;
}

.fill-dark *, .fill-dark {
  fill: #0A0D10 !important;
}

.stroke-dark, .stroke-dark * {
  stroke: #0A0D10 !important;
}

.text-outline-dark {
  color: transparent !important;
  -webkit-text-stroke: 1px #0A0D10;
}

.fill-currentColor {
  fill: currentColor !important;
}
.fill-currentColor > * {
  fill: currentColor !important;
}

.stroke-currentColor {
  stroke: currentColor !important;
}
.stroke-currentColor > * {
  stroke: currentColor !important;
}

.link-underline {
  display: inline-block;
  position: relative;
  padding-bottom: 0.125rem;
  overflow: hidden;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: 0.15s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  vertical-align: middle;
  transition: color 0.25s cubic-bezier(0.28, 0.44, 0.49, 1);
}
.link-underline::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  width: 100%;
  will-change: transform;
  background-color: currentColor;
  opacity: 0.35;
}
.link-underline:hover::after {
  opacity: 1;
  animation: scaleOut 0.25s cubic-bezier(0.28, 0.44, 0.49, 1) 0s forwards, scaleIn 0.25s cubic-bezier(0.28, 0.44, 0.49, 1) 0.25s forwards;
}

.link-decoration {
  text-decoration: underline;
}
.link-decoration:hover {
  text-decoration: none;
}

.link-hover-decoration {
  text-decoration: none;
}
.link-hover-decoration:hover {
  text-decoration: underline;
}

.link-hover-underline {
  position: relative;
  display: inline-block;
}
.link-hover-underline::after {
  content: "";
  display: block;
  position: absolute;
  height: 1px;
  top: 100%;
  left: 0.05em;
  right: 0.05em;
  background: currentColor;
  transform-origin: 100% 50%;
  transform: scaleX(0);
  transition: -webkit-transform 0.6s cubic-bezier(1, 0, 0, 1);
  transition: transform 0.6s cubic-bezier(1, 0, 0, 1);
}
.link-hover-underline:hover::after {
  transform: scaleX(1);
  transition-duration: 0.9s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transform-origin: 0 50%;
}

.link-decoration:not(:hover):not(.active) {
  text-decoration: underline;
}

.link-hover-move-arrow {
  position: relative;
  display: inline-block;
  transition: padding 0.35s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.link-hover-move-arrow svg {
  width: 10px;
  height: auto;
  margin-right: 2px;
}
.link-hover-move-arrow svg > path {
  fill: currentColor;
}
.link-hover-move-arrow:hover {
  padding-left: 0.5rem;
}

.link-both-underline {
  display: inline-block;
  text-decoration: none;
  position: relative;
  overflow: hidden;
}
.link-both-underline::after {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  transform: translate3d(-102%, 0, 0);
  content: "";
  display: block;
  height: 1px;
  background-color: currentColor;
}
.link-both-underline:hover::after {
  animation: underline-both 1s ease;
}

.link-hover-no-underline {
  display: inline-block;
  position: relative;
  padding-bottom: 0.02em;
}
.link-hover-no-underline::after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 1px;
  background: currentColor;
  width: 100%;
  right: 0;
  left: 0;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}
.link-hover-no-underline:hover:after {
  width: 0;
  left: 50%;
  right: auto;
}

/* Recommended styles for Splitting */
.splitting .word,
.splitting .char {
  display: inline-block;
}

/* Psuedo-element chars */
.splitting .char {
  white-space: nowrap;
  position: relative;
}

.link-underline > *,
.link-hover-underline > *,
.btn > *,
.nav-link > * {
  pointer-events: none;
}

.link-multiline {
  display: inline;
  background-image: linear-gradient(#0A0D10, #0A0D10);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 1px;
  transition: background-size 0.3s, color 0.3s;
}
.link-multiline:hover {
  background-size: 100% 1px;
}

.text-light .link-multiline, .text-white .link-multiline {
  background-image: linear-gradient(#fff, #fff);
}

.link-2x.link-underline, .link-2x.link-hover-underline, .link-2x.link-hover-nounderline, .link-2x.link-both-underline {
  padding-bottom: 0.2rem;
}
.link-2x.link-underline::after, .link-2x.link-hover-underline::after, .link-2x.link-hover-nounderline::after, .link-2x.link-both-underline::after {
  height: 2px;
}

.link-arrow-bounce {
  animation-name: bounce;
  animation-iteration-count: infinite;
  animation-duration: 1s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
}

.list-inline-divided .list-inline-item::after {
  content: "/";
  margin-left: 0.5rem;
  font-size: 0.739375rem;
  color: currentColor;
  opacity: 0.25;
  display: inline-block;
  vertical-align: middle;
  font-weight: light;
}
.list-inline-divided .list-inline-item:last-child::after {
  display: none;
}

.list-disc, .list-circle {
  padding-left: 1rem;
}

.list-disc {
  list-style-type: disc;
}

.list-circle {
  list-style-type: circle;
}

.text-white .list-group-item, .text-light .list-group-item {
  color: #fff;
  border-color: rgba(255, 255, 255, 0.25);
}

.stripes {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -1;
}

.stripes-container {
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.stripes-item {
  background-color: #F8FBFE;
  height: 100%;
  width: 1px;
}

.text-white .stripes-item, .text-light .stripes-item {
  background-color: rgba(255, 255, 255, 0.075);
}

.progress-page {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: none;
  border: 0;
  height: auto;
  margin: 0;
  padding: 0;
}
.progress-page progress {
  position: relative;
  display: block;
  height: 5px;
  width: 100%;
  border: 0;
  background-color: transparent;
}
.progress-page progress::-moz-progress-bar {
  background-color: #b9dbcb;
}
.progress-page progress::-webkit-progress-bar {
  background-color: transparent;
}
.progress-page progress::-webkit-progress-value {
  background-color: #b9dbcb;
}

.progress-bar.aos-init {
  width: 0;
}

.toTop {
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 1rem;
  margin-bottom: 1rem;
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  background-color: #fff;
  color: #14191E;
  box-shadow: 0 0.5rem 1rem rgba(10, 13, 16, 0.1);
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  line-height: 1;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-30px);
  transition: 0.4s cubic-bezier(0.33, 1, 0.68, 1);
  z-index: 9999;
}
.toTop.show {
  visibility: visible;
  opacity: 1;
  transform: none;
}

.connect-line {
  display: block;
  bottom: 0;
  width: 1px;
  height: 4rem;
  background-color: currentColor;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  z-index: 10;
  animation: connectLine 1.5s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  -webkit-animation: connectLine 1.5s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

.c-scrollbar_thumb {
  background-color: rgba(56, 151, 105, 0.5);
  border-radius: 0px;
  margin: 0px;
}

.si {
  display: inline-block;
  position: relative;
  overflow: hidden;
  width: 40px;
  height: 40px;
  font-size: 1.25rem;
  color: #14191E;
}
.si > i {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.15s, transform 0.45s cubic-bezier(0.19, 1, 0.22, 1);
  line-height: 1;
}
.si > i:last-child {
  transform: translateY(100%);
  opacity: 0;
  visibility: hidden;
}
.si:hover > i:first-child {
  transform: translateY(-100%);
  opacity: 0;
  visibility: hidden;
}
.si:hover > i:last-child {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}
.si.si-border {
  border: 1px solid #F0F5FA;
}

.si-list-inline li .si + .si {
  margin-left: 0.25rem;
}

.si-circle {
  border-radius: 100%;
}

.si-hover-facebook:hover {
  background-color: #3b5998 !important;
  border-color: #3b5998 !important;
  color: #fff !important;
}

.si-hover-twitter:hover {
  background-color: #1da1f2 !important;
  border-color: #1da1f2 !important;
  color: #fff !important;
}

.si-hover-google:hover {
  background-color: #ff0000 !important;
  border-color: #ff0000 !important;
  color: #fff !important;
}

.si-hover-skype:hover {
  background-color: #00aff0 !important;
  border-color: #00aff0 !important;
  color: #fff !important;
}

.si-hover-linkedin:hover {
  background-color: #0077b5 !important;
  border-color: #0077b5 !important;
  color: #fff !important;
}

.si-hover-pinterest:hover {
  background-color: #c8232c !important;
  border-color: #c8232c !important;
  color: #fff !important;
}

.si-hover-tumblr:hover {
  background-color: #34526f !important;
  border-color: #34526f !important;
  color: #fff !important;
}

.si-hover-vimeo:hover {
  background-color: #33b3f2 !important;
  border-color: #33b3f2 !important;
  color: #fff !important;
}

.si-hover-digg:hover {
  background-color: #191919 !important;
  border-color: #191919 !important;
  color: #fff !important;
}

.si-hover-reddit:hover {
  background-color: #c6c6c6 !important;
  border-color: #c6c6c6 !important;
  color: #fff !important;
}

.si-hover-spotify:hover {
  background-color: #1ed760 !important;
  border-color: #1ed760 !important;
  color: #fff !important;
}

.si-hover-blogger:hover {
  background-color: #fc4f08 !important;
  border-color: #fc4f08 !important;
  color: #fff !important;
}

.si-hover-dribbble:hover {
  background-color: #ea4c89 !important;
  border-color: #ea4c89 !important;
  color: #fff !important;
}

.si-hover-flickr:hover {
  background-color: #ff0084 !important;
  border-color: #ff0084 !important;
  color: #fff !important;
}

.si-hover-youtube:hover {
  background-color: #ff0000 !important;
  border-color: #ff0000 !important;
  color: #fff !important;
}

.si-hover-yahoo:hover {
  background-color: #720e9e !important;
  border-color: #720e9e !important;
  color: #fff !important;
}

.si-hover-dropbox:hover {
  background-color: #3d9ae8 !important;
  border-color: #3d9ae8 !important;
  color: #fff !important;
}

.si-hover-ebay:hover {
  background-color: #89c507 !important;
  border-color: #89c507 !important;
  color: #fff !important;
}

.si-hover-github:hover {
  background-color: #171515 !important;
  border-color: #171515 !important;
  color: #fff !important;
}

.si-hover-google-play:hover {
  background-color: #dd4b39 !important;
  border-color: #dd4b39 !important;
  color: #fff !important;
}

.si-hover-itunes:hover {
  background-color: #222 !important;
  border-color: #222 !important;
  color: #fff !important;
}

.si-hover-soundcloud:hover {
  background-color: #f70 !important;
  border-color: #f70 !important;
  color: #fff !important;
}

.si-hover-wordpress:hover {
  background-color: #1e8cbe !important;
  border-color: #1e8cbe !important;
  color: #fff !important;
}

.si-hover-yelp:hover {
  background-color: #c41200 !important;
  border-color: #c41200 !important;
  color: #fff !important;
}

.si-hover-drupal:hover {
  background-color: #27537a !important;
  border-color: #27537a !important;
  color: #fff !important;
}

.si-hover-bitbucket:hover {
  background-color: #0e4984 !important;
  border-color: #0e4984 !important;
  color: #fff !important;
}

.si-hover-stackoverflow:hover {
  background-color: #ef8236 !important;
  border-color: #ef8236 !important;
  color: #fff !important;
}

.si-hover-xing:hover {
  background-color: #126567 !important;
  border-color: #126567 !important;
  color: #fff !important;
}

.si-hover-amazon:hover {
  background-color: #e47911 !important;
  border-color: #e47911 !important;
  color: #fff !important;
}

.si-hover-appstore:hover {
  background-color: #0070c9 !important;
  border-color: #0070c9 !important;
  color: #fff !important;
}

.si-hover-android:hover {
  background-color: #a4c639 !important;
  border-color: #a4c639 !important;
  color: #fff !important;
}

.si-hover-wikipedia:hover {
  background-color: #333 !important;
  border-color: #333 !important;
  color: #fff !important;
}

.si-hover-foursquare:hover {
  background-color: #25a0ca !important;
  border-color: #25a0ca !important;
  color: #fff !important;
}

.si-hover-bitcoin:hover {
  background-color: #ecbc13 !important;
  border-color: #ecbc13 !important;
  color: #fff !important;
}

.si-hover-adobe:hover {
  background-color: #ff0000 !important;
  border-color: #ff0000 !important;
  color: #fff !important;
}

.si-hover-chrome:hover {
  background-color: #174ea6 !important;
  border-color: #174ea6 !important;
  color: #fff !important;
}

.si-hover-gulp:hover {
  background-color: #cf4647 !important;
  border-color: #cf4647 !important;
  color: #fff !important;
}

.si-hover-sass:hover {
  background-color: #b37399 !important;
  border-color: #b37399 !important;
  color: #fff !important;
}

.si-hover-paypal:hover {
  background-color: #0070ba !important;
  border-color: #0070ba !important;
  color: #fff !important;
}

.si-hover-magento:hover {
  background-color: #f26322 !important;
  border-color: #f26322 !important;
  color: #fff !important;
}

.si-hover-microsoft:hover {
  background-color: #0067b8 !important;
  border-color: #0067b8 !important;
  color: #fff !important;
}

.si-hover-angular:hover {
  background-color: #dd4b39 !important;
  border-color: #dd4b39 !important;
  color: #fff !important;
}

.si-hover-slack:hover {
  background-color: #e01e5a !important;
  border-color: #e01e5a !important;
  color: #fff !important;
}

.si-hover-behance:hover {
  background-color: #053eff !important;
  border-color: #053eff !important;
  color: #fff !important;
}

.si-hover-stumbleupon:hover {
  background-color: #f74425 !important;
  border-color: #f74425 !important;
  color: #fff !important;
}

.si-hover-internet-explorer:hover {
  background-color: #0084ff !important;
  border-color: #0084ff !important;
  color: #fff !important;
}

.si-hover-whatsapp:hover {
  background-color: #25D366 !important;
  border-color: #25D366 !important;
  color: #fff !important;
}

.si-hover-twitch:hover {
  background-color: #6441a5 !important;
  border-color: #6441a5 !important;
  color: #fff !important;
}

.si-colored-facebook {
  background-color: #3b5998 !important;
  border-color: #3b5998 !important;
  color: #fff !important;
}
.si-colored-facebook:hover {
  background-color: #293e6a !important;
  border-color: #23355b !important;
}

.si-colored-twitter {
  background-color: #1da1f2 !important;
  border-color: #1da1f2 !important;
  color: #fff !important;
}
.si-colored-twitter:hover {
  background-color: #1471a9 !important;
  border-color: #116191 !important;
}

.si-colored-google {
  background-color: #ff0000 !important;
  border-color: #ff0000 !important;
  color: #fff !important;
}
.si-colored-google:hover {
  background-color: #b30000 !important;
  border-color: #990000 !important;
}

.si-colored-skype {
  background-color: #00aff0 !important;
  border-color: #00aff0 !important;
  color: #fff !important;
}
.si-colored-skype:hover {
  background-color: #007ba8 !important;
  border-color: #006990 !important;
}

.si-colored-linkedin {
  background-color: #0077b5 !important;
  border-color: #0077b5 !important;
  color: #fff !important;
}
.si-colored-linkedin:hover {
  background-color: #00537f !important;
  border-color: #00476d !important;
}

.si-colored-pinterest {
  background-color: #c8232c !important;
  border-color: #c8232c !important;
  color: #fff !important;
}
.si-colored-pinterest:hover {
  background-color: #8c191f !important;
  border-color: #78151a !important;
}

.si-colored-tumblr {
  background-color: #34526f !important;
  border-color: #34526f !important;
  color: #fff !important;
}
.si-colored-tumblr:hover {
  background-color: #24394e !important;
  border-color: #1f3143 !important;
}

.si-colored-vimeo {
  background-color: #33b3f2 !important;
  border-color: #33b3f2 !important;
  color: #fff !important;
}
.si-colored-vimeo:hover {
  background-color: #247da9 !important;
  border-color: #1f6b91 !important;
}

.si-colored-digg {
  background-color: #191919 !important;
  border-color: #191919 !important;
  color: #fff !important;
}
.si-colored-digg:hover {
  background-color: #121212 !important;
  border-color: #0f0f0f !important;
}

.si-colored-reddit {
  background-color: #c6c6c6 !important;
  border-color: #c6c6c6 !important;
  color: #fff !important;
}
.si-colored-reddit:hover {
  background-color: #8b8b8b !important;
  border-color: #777777 !important;
}

.si-colored-spotify {
  background-color: #1ed760 !important;
  border-color: #1ed760 !important;
  color: #fff !important;
}
.si-colored-spotify:hover {
  background-color: #159743 !important;
  border-color: #12813a !important;
}

.si-colored-blogger {
  background-color: #fc4f08 !important;
  border-color: #fc4f08 !important;
  color: #fff !important;
}
.si-colored-blogger:hover {
  background-color: #b03706 !important;
  border-color: #972f05 !important;
}

.si-colored-dribbble {
  background-color: #ea4c89 !important;
  border-color: #ea4c89 !important;
  color: #fff !important;
}
.si-colored-dribbble:hover {
  background-color: #a43560 !important;
  border-color: #8c2e52 !important;
}

.si-colored-flickr {
  background-color: #ff0084 !important;
  border-color: #ff0084 !important;
  color: #fff !important;
}
.si-colored-flickr:hover {
  background-color: #b3005c !important;
  border-color: #99004f !important;
}

.si-colored-youtube {
  background-color: #ff0000 !important;
  border-color: #ff0000 !important;
  color: #fff !important;
}
.si-colored-youtube:hover {
  background-color: #b30000 !important;
  border-color: #990000 !important;
}

.si-colored-yahoo {
  background-color: #720e9e !important;
  border-color: #720e9e !important;
  color: #fff !important;
}
.si-colored-yahoo:hover {
  background-color: #500a6f !important;
  border-color: #44085f !important;
}

.si-colored-dropbox {
  background-color: #3d9ae8 !important;
  border-color: #3d9ae8 !important;
  color: #fff !important;
}
.si-colored-dropbox:hover {
  background-color: #2b6ca2 !important;
  border-color: #255c8b !important;
}

.si-colored-ebay {
  background-color: #89c507 !important;
  border-color: #89c507 !important;
  color: #fff !important;
}
.si-colored-ebay:hover {
  background-color: #608a05 !important;
  border-color: #527604 !important;
}

.si-colored-github {
  background-color: #171515 !important;
  border-color: #171515 !important;
  color: #fff !important;
}
.si-colored-github:hover {
  background-color: #100f0f !important;
  border-color: #0e0d0d !important;
}

.si-colored-google-play {
  background-color: #dd4b39 !important;
  border-color: #dd4b39 !important;
  color: #fff !important;
}
.si-colored-google-play:hover {
  background-color: #9b3528 !important;
  border-color: #852d22 !important;
}

.si-colored-itunes {
  background-color: #222 !important;
  border-color: #222 !important;
  color: #fff !important;
}
.si-colored-itunes:hover {
  background-color: #181818 !important;
  border-color: #141414 !important;
}

.si-colored-soundcloud {
  background-color: #f70 !important;
  border-color: #f70 !important;
  color: #fff !important;
}
.si-colored-soundcloud:hover {
  background-color: #b35300 !important;
  border-color: #994700 !important;
}

.si-colored-wordpress {
  background-color: #1e8cbe !important;
  border-color: #1e8cbe !important;
  color: #fff !important;
}
.si-colored-wordpress:hover {
  background-color: #156285 !important;
  border-color: #125472 !important;
}

.si-colored-yelp {
  background-color: #c41200 !important;
  border-color: #c41200 !important;
  color: #fff !important;
}
.si-colored-yelp:hover {
  background-color: #890d00 !important;
  border-color: #760b00 !important;
}

.si-colored-drupal {
  background-color: #27537a !important;
  border-color: #27537a !important;
  color: #fff !important;
}
.si-colored-drupal:hover {
  background-color: #1b3a55 !important;
  border-color: #173249 !important;
}

.si-colored-bitbucket {
  background-color: #0e4984 !important;
  border-color: #0e4984 !important;
  color: #fff !important;
}
.si-colored-bitbucket:hover {
  background-color: #0a335c !important;
  border-color: #082c4f !important;
}

.si-colored-stackoverflow {
  background-color: #ef8236 !important;
  border-color: #ef8236 !important;
  color: #fff !important;
}
.si-colored-stackoverflow:hover {
  background-color: #a75b26 !important;
  border-color: #8f4e20 !important;
}

.si-colored-xing {
  background-color: #126567 !important;
  border-color: #126567 !important;
  color: #fff !important;
}
.si-colored-xing:hover {
  background-color: #0d4748 !important;
  border-color: #0b3d3e !important;
}

.si-colored-amazon {
  background-color: #e47911 !important;
  border-color: #e47911 !important;
  color: #fff !important;
}
.si-colored-amazon:hover {
  background-color: #a0550c !important;
  border-color: #89490a !important;
}

.si-colored-appstore {
  background-color: #0070c9 !important;
  border-color: #0070c9 !important;
  color: #fff !important;
}
.si-colored-appstore:hover {
  background-color: #004e8d !important;
  border-color: #004379 !important;
}

.si-colored-android {
  background-color: #a4c639 !important;
  border-color: #a4c639 !important;
  color: #fff !important;
}
.si-colored-android:hover {
  background-color: #738b28 !important;
  border-color: #627722 !important;
}

.si-colored-wikipedia {
  background-color: #333 !important;
  border-color: #333 !important;
  color: #fff !important;
}
.si-colored-wikipedia:hover {
  background-color: #242424 !important;
  border-color: #1f1f1f !important;
}

.si-colored-foursquare {
  background-color: #25a0ca !important;
  border-color: #25a0ca !important;
  color: #fff !important;
}
.si-colored-foursquare:hover {
  background-color: #1a708d !important;
  border-color: #166079 !important;
}

.si-colored-bitcoin {
  background-color: #ecbc13 !important;
  border-color: #ecbc13 !important;
  color: #fff !important;
}
.si-colored-bitcoin:hover {
  background-color: #a5840d !important;
  border-color: #8e710b !important;
}

.si-colored-adobe {
  background-color: #ff0000 !important;
  border-color: #ff0000 !important;
  color: #fff !important;
}
.si-colored-adobe:hover {
  background-color: #b30000 !important;
  border-color: #990000 !important;
}

.si-colored-chrome {
  background-color: #174ea6 !important;
  border-color: #174ea6 !important;
  color: #fff !important;
}
.si-colored-chrome:hover {
  background-color: #103774 !important;
  border-color: #0e2f64 !important;
}

.si-colored-gulp {
  background-color: #cf4647 !important;
  border-color: #cf4647 !important;
  color: #fff !important;
}
.si-colored-gulp:hover {
  background-color: #913132 !important;
  border-color: #7c2a2b !important;
}

.si-colored-sass {
  background-color: #b37399 !important;
  border-color: #b37399 !important;
  color: #fff !important;
}
.si-colored-sass:hover {
  background-color: #7d516b !important;
  border-color: #6b455c !important;
}

.si-colored-paypal {
  background-color: #0070ba !important;
  border-color: #0070ba !important;
  color: #fff !important;
}
.si-colored-paypal:hover {
  background-color: #004e82 !important;
  border-color: #004370 !important;
}

.si-colored-magento {
  background-color: #f26322 !important;
  border-color: #f26322 !important;
  color: #fff !important;
}
.si-colored-magento:hover {
  background-color: #a94518 !important;
  border-color: #913b14 !important;
}

.si-colored-microsoft {
  background-color: #0067b8 !important;
  border-color: #0067b8 !important;
  color: #fff !important;
}
.si-colored-microsoft:hover {
  background-color: #004881 !important;
  border-color: #003e6e !important;
}

.si-colored-angular {
  background-color: #dd4b39 !important;
  border-color: #dd4b39 !important;
  color: #fff !important;
}
.si-colored-angular:hover {
  background-color: #9b3528 !important;
  border-color: #852d22 !important;
}

.si-colored-slack {
  background-color: #e01e5a !important;
  border-color: #e01e5a !important;
  color: #fff !important;
}
.si-colored-slack:hover {
  background-color: #9d153f !important;
  border-color: #861236 !important;
}

.si-colored-behance {
  background-color: #053eff !important;
  border-color: #053eff !important;
  color: #fff !important;
}
.si-colored-behance:hover {
  background-color: #042bb3 !important;
  border-color: #032599 !important;
}

.si-colored-stumbleupon {
  background-color: #f74425 !important;
  border-color: #f74425 !important;
  color: #fff !important;
}
.si-colored-stumbleupon:hover {
  background-color: #ad301a !important;
  border-color: #942916 !important;
}

.si-colored-internet-explorer {
  background-color: #0084ff !important;
  border-color: #0084ff !important;
  color: #fff !important;
}
.si-colored-internet-explorer:hover {
  background-color: #005cb3 !important;
  border-color: #004f99 !important;
}

.si-colored-whatsapp {
  background-color: #25D366 !important;
  border-color: #25D366 !important;
  color: #fff !important;
}
.si-colored-whatsapp:hover {
  background-color: #1a9447 !important;
  border-color: #167f3d !important;
}

.si-colored-twitch {
  background-color: #6441a5 !important;
  border-color: #6441a5 !important;
  color: #fff !important;
}
.si-colored-twitch:hover {
  background-color: #462e74 !important;
  border-color: #3c2763 !important;
}

.si-colored-instagram, .si-hover-instagram:hover, .si-colored-instagram:hover {
  background-image: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%) !important;
  color: #fff;
  border-color: transparent;
}

.form-pricing-switch.form-switch {
  margin-bottom: 0;
}
.form-pricing-switch.form-switch > .form-check-input {
  height: 1.75rem;
  width: 3.25rem;
  margin-top: 0;
}

.nav-tabs {
  border-bottom: 1px solid #F0F5FA;
}
.nav-tabs .nav-link {
  padding-left: 0px;
  padding-right: 0px;
}
.nav-tabs .nav-link:not(:first-child) {
  margin-left: 1.5rem;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  border-bottom: 1px solid transparent;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  background-color: transparent;
}
.nav-tabs .nav-link.active {
  background-color: transparent;
  border-bottom-color: #389769;
}

.fullscreen-wrapper {
  overflow: hidden;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  max-width: 100vw;
  height: 100%;
  z-index: 99999;
  transition: all 0.6s ease-out;
  visibility: hidden;
}
.fullscreen-wrapper::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: visibility 0s linear 1.5s, transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 1s;
  transform: translateY(-100%);
  visibility: hidden;
  background-color: #163c2a;
}
.fullscreen-wrapper .fullscreen-inner {
  padding: 2rem;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  width: calc(100vw + 17px);
  position: relative;
}

.fullscreen-close {
  z-index: 4;
  position: fixed;
  right: 0.75rem;
  top: 0.75rem;
  width: 3rem;
  height: 3rem;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  background-color: #389769;
  transition: opacity 0s linear 0.3s, transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.3ms;
  opacity: 0;
  transform: translateX(-1rem);
  color: #fff;
}

.fullscreen-item {
  transition: opacity 0.5s linear 0s, transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  opacity: 0;
  transform: translateY(3.5rem);
}

.fullscreen-inner .js-hover-img {
  transition: none;
}
.fullscreen-inner .js-hover-img .js-hover-img-item {
  transition: opacity 0.2s, transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s;
  transform: translate3d(0, 30px, 0);
  opacity: 0;
}
.fullscreen-inner .js-hover-img .js-hover-img-item .js-hover-img-link {
  transition: none;
}
.fullscreen-inner .js-hover-img .js-hover-img-item .js-hover-img-link:not(:hover):not(.active) {
  mix-blend-mode: screen;
}

.fullscreen-open {
  overflow: hidden;
  height: 100%;
  width: 100vw;
}
.fullscreen-open .fullscreen-inner .js-hover-img .js-hover-img-item {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.5s linear 0.8s, transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.8s;
}
.fullscreen-open .fullscreen-inner .js-hover-img .js-hover-img-item:nth-child(1) {
  transition-delay: 300ms;
}
.fullscreen-open .fullscreen-inner .js-hover-img .js-hover-img-item:nth-child(2) {
  transition-delay: 600ms;
}
.fullscreen-open .fullscreen-inner .js-hover-img .js-hover-img-item:nth-child(3) {
  transition-delay: 900ms;
}
.fullscreen-open .fullscreen-inner .js-hover-img .js-hover-img-item:nth-child(4) {
  transition-delay: 1200ms;
}
.fullscreen-open .fullscreen-inner .js-hover-img .js-hover-img-item:nth-child(5) {
  transition-delay: 1500ms;
}
.fullscreen-open .fullscreen-inner .js-hover-img .js-hover-img-item:nth-child(6) {
  transition-delay: 1800ms;
}
.fullscreen-open .fullscreen-inner .js-hover-img .js-hover-img-item:nth-child(7) {
  transition-delay: 2100ms;
}
.fullscreen-open .fullscreen-inner .js-hover-img .js-hover-img-item:nth-child(8) {
  transition-delay: 2400ms;
}
.fullscreen-open .fullscreen-inner .js-hover-img .js-hover-img-item:nth-child(9) {
  transition-delay: 2700ms;
}
.fullscreen-open .fullscreen-inner .js-hover-img .js-hover-img-item:nth-child(10) {
  transition-delay: 3000ms;
}
.fullscreen-open .fullscreen-inner .js-hover-img .js-hover-img-item:nth-child(11) {
  transition-delay: 3300ms;
}
.fullscreen-open .fullscreen-inner .js-hover-img .js-hover-img-item:nth-child(12) {
  transition-delay: 3600ms;
}
.fullscreen-open .fullscreen-inner .js-hover-img .js-hover-img-item:nth-child(13) {
  transition-delay: 3900ms;
}
.fullscreen-open .fullscreen-inner .js-hover-img .js-hover-img-item:nth-child(14) {
  transition-delay: 4200ms;
}
.fullscreen-open .fullscreen-inner .js-hover-img .js-hover-img-item:nth-child(15) {
  transition-delay: 4500ms;
}
.fullscreen-open .fullscreen-inner .js-hover-img .js-hover-img-item:nth-child(16) {
  transition-delay: 4800ms;
}
.fullscreen-open .fullscreen-inner .js-hover-img .js-hover-img-item:nth-child(17) {
  transition-delay: 5100ms;
}
.fullscreen-open .fullscreen-inner .js-hover-img .js-hover-img-item:nth-child(18) {
  transition-delay: 5400ms;
}
.fullscreen-open .fullscreen-inner .js-hover-img .js-hover-img-item:nth-child(19) {
  transition-delay: 5700ms;
}
.fullscreen-open .fullscreen-inner .js-hover-img .js-hover-img-item:nth-child(20) {
  transition-delay: 6000ms;
}
.fullscreen-open .fullscreen-inner .js-hover-img .js-hover-img-item:nth-child(21) {
  transition-delay: 6300ms;
}
.fullscreen-open .fullscreen-item {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.5s linear 0.8s, transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.8s;
}
.fullscreen-open .fullscreen-item:nth-child(1) {
  transition-delay: 300ms;
}
.fullscreen-open .fullscreen-item:nth-child(2) {
  transition-delay: 600ms;
}
.fullscreen-open .fullscreen-item:nth-child(3) {
  transition-delay: 900ms;
}
.fullscreen-open .fullscreen-item:nth-child(4) {
  transition-delay: 1200ms;
}
.fullscreen-open .fullscreen-item:nth-child(5) {
  transition-delay: 1500ms;
}
.fullscreen-open .fullscreen-item:nth-child(6) {
  transition-delay: 1800ms;
}
.fullscreen-open .fullscreen-item:nth-child(7) {
  transition-delay: 2100ms;
}
.fullscreen-open .fullscreen-item:nth-child(8) {
  transition-delay: 2400ms;
}
.fullscreen-open .fullscreen-item:nth-child(9) {
  transition-delay: 2700ms;
}
.fullscreen-open .fullscreen-item:nth-child(10) {
  transition-delay: 3000ms;
}
.fullscreen-open .fullscreen-item:nth-child(11) {
  transition-delay: 3300ms;
}
.fullscreen-open .fullscreen-item:nth-child(12) {
  transition-delay: 3600ms;
}
.fullscreen-open .fullscreen-item:nth-child(13) {
  transition-delay: 3900ms;
}
.fullscreen-open .fullscreen-item:nth-child(14) {
  transition-delay: 4200ms;
}
.fullscreen-open .fullscreen-item:nth-child(15) {
  transition-delay: 4500ms;
}
.fullscreen-open .fullscreen-item:nth-child(16) {
  transition-delay: 4800ms;
}
.fullscreen-open .fullscreen-item:nth-child(17) {
  transition-delay: 5100ms;
}
.fullscreen-open .fullscreen-item:nth-child(18) {
  transition-delay: 5400ms;
}
.fullscreen-open .fullscreen-item:nth-child(19) {
  transition-delay: 5700ms;
}
.fullscreen-open .fullscreen-item:nth-child(20) {
  transition-delay: 6000ms;
}
.fullscreen-open .fullscreen-item:nth-child(21) {
  transition-delay: 6300ms;
}
.fullscreen-open .fullscreen-close {
  opacity: 1;
  transform: none;
  transition: opacity 0s linear 0.4s, transform 0.4s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.4s;
}
.fullscreen-open .fullscreen-wrapper {
  visibility: visible;
}
.fullscreen-open .fullscreen-wrapper::before {
  visibility: visible;
  transform: translateY(0%);
  transition: visibility 0s linear 0.1s, transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s;
}

@media (min-width: 992px) {
  .fullscreen-wrapper .fullscreen-inner {
    display: flex;
    align-items: center;
  }
}
.offcanvas {
  transition: transform 0.25s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.offcanvas.offcanvas-start, .offcanvas.offcanvas-end, .offcanvas.offcanvas-bottom {
  border: 0px;
}
.offcanvas .offcanvas-header .btn-close {
  padding: 0px;
}
.offcanvas .nav-link {
  font-weight: 500;
  color: #14191E;
  display: block;
}
.offcanvas .nav-link:hover, .offcanvas .nav-link.active {
  color: #389769;
}
.offcanvas ul > li {
  transform: translateY(30px);
  opacity: 0;
}
.offcanvas ul.no-animation > li {
  transform: none;
  opacity: 1;
  transition: none;
}
.offcanvas.show {
  transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.offcanvas.show ul > li {
  overflow: hidden;
  transition: opacity 0.45s, transform 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
  opacity: 1;
  transform: translateZ(0);
}
.offcanvas.show ul > li:nth-child(1) {
  transition-delay: 0.25s;
}
.offcanvas.show ul > li:nth-child(2) {
  transition-delay: 0.5s;
}
.offcanvas.show ul > li:nth-child(3) {
  transition-delay: 0.75s;
}
.offcanvas.show ul > li:nth-child(4) {
  transition-delay: 1s;
}
.offcanvas.show ul > li:nth-child(5) {
  transition-delay: 1.25s;
}

th {
  font-weight: 500;
}

.table > :not(:first-child) {
  border-top: 1px solid currentColor;
}

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  background-color: #389769;
  border-color: #389769;
}

.flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
  background-color: #ebf5f0;
  border-color: #ebf5f0;
}

.flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #ebf5f0, 5px 0 0 #ebf5f0;
  box-shadow: -5px 0 0 #ebf5f0, 5px 0 0 #ebf5f0;
}

.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover, .flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay, .flatpickr-day.notAllowed, .flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.notAllowed.nextMonthDay {
  color: #DAE1E8;
}

.flatpickr-current-month {
  font-size: 1rem;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background-color: #ebf5f0;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  border-radius: 0.375rem;
}

.flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month {
  fill: #14191E;
  color: #14191E;
}

.flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg {
  fill: #389769;
}

.flatpickr-day {
  color: #14191E;
}

.flatpickr-day.today {
  border-color: #E5EBF1;
}

.flatpickr-day.today:hover, .flatpickr-day.today:focus {
  border-color: #2B323A;
  background: #2B323A;
  color: #fff;
}

.page-wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.page-scroll {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.page-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  left: 0;
  top: 0;
  transition: all 0.3s;
  width: 260px;
  height: 100%;
  overflow: hidden;
  z-index: 1030;
}

.page-header {
  z-index: 1040;
}

.navbar-brand .navbar-brand-icon {
  width: 2.5rem;
  height: 2.5rem;
  background-color: #389769;
  border-radius: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-sidebar .page-sidebar-title,
.page-sidebar .nav .nav-link,
.page-sidebar .page-sidebar-brand {
  padding: 0.25rem 1rem;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  align-items: center;
  transition: all 0.25s;
}
.page-sidebar .nav .nav-link[data-bs-toggle=collapse]:after {
  opacity: 0.5;
  margin-right: 1rem;
  font-size: 0.75rem;
}
.page-sidebar .page-sidebar-title {
  opacity: 0.5;
  font-size: 0.875rem;
  padding-left: 1.75;
  position: relative;
}
.page-sidebar .page-sidebar-title i, .page-sidebar .page-sidebar-title span {
  transition: all 0.2s;
}
.page-sidebar .page-sidebar-title i {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateX(-100%) translateY(-50%);
  opacity: 0;
  margin-left: 1.75rem;
}
.page-sidebar .nav-sidebar-icon {
  width: 2.25rem;
  height: 2.25rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  font-style: normal;
  border-radius: 0.25rem;
  margin-right: 0.75rem;
}

.page-content {
  padding-left: 260px;
  transition: all 0.3s;
}

.page-content, .page-sidebar {
  padding-top: 63px;
}

.sidebar-dropdown {
  margin: 0;
  padding: 0;
  padding-left: 3rem;
  display: flex;
  flex-direction: column;
}
.sidebar-dropdown .sidebar-link {
  display: flex;
  align-items: center;
  padding: 0.4rem 1rem;
  font-size: 0.875rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.sidebar-dropdown .sidebar-link[data-bs-toggle=collapse]::after {
  opacity: 0.5;
  margin-right: 1rem;
  font-size: 0.75rem;
}

.sidebar-dropdown.sd-second-level, .sidebar-dropdown.sd-thrid-level {
  padding-left: 0.75rem;
}

.sidebar-light .nav .nav-link {
  color: #14191E;
}
.sidebar-light .sidebar-dropdown .sidebar-link {
  color: #14191E;
}

.sidebar-toggler {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  padding: 0;
  position: relative;
}
.sidebar-toggler::before, .sidebar-toggler::after {
  content: "";
  height: 2px;
  background-color: currentColor;
  position: absolute;
  left: 15%;
  width: 70%;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.25s;
}
.sidebar-toggler::before {
  margin-top: 6px;
}
.sidebar-toggler::after {
  margin-top: -6px;
}
.sidebar-toggler i {
  transition: all 0.25s;
  display: block;
  position: absolute;
  width: 70%;
  height: 100%;
  left: 15%;
  top: 50%;
  transform: translateY(-50%);
  height: 2px;
  background-color: currentColor;
}
.sidebar-toggler.sidebar-toggled i {
  width: 50%;
  transition-delay: 0.15s;
}
.sidebar-toggler.sidebar-toggled::before {
  width: 25%;
  transition-delay: 0.25s;
}

@media (min-width: 992px) {
  body.page-collapsed .page-sidebar:not(:hover) {
    width: 72px;
  }
  body.page-collapsed .page-sidebar:not(:hover) .sidebar-dropdown.collapse.show {
    height: 0px;
    overflow: hidden;
    transition: height 0.3s ease 0s;
  }
  body.page-collapsed .page-sidebar:not(:hover) .nav-link-text, body.page-collapsed .page-sidebar:not(:hover) .navbar-brand-text {
    opacity: 0;
  }
  body.page-collapsed .page-sidebar:not(:hover) .sidebar-toggler {
    display: none;
  }
  body.page-collapsed .page-sidebar:not(:hover) .page-sidebar-title span {
    opacity: 0;
  }
  body.page-collapsed .page-sidebar:not(:hover) .page-sidebar-title i {
    opacity: 1;
    transform: translateX(0) translateY(-50%);
  }
  body.page-collapsed .page-content {
    padding-left: 72px;
  }
}
@media (max-width: 991.98px) {
  .page-sidebar {
    transform: translateX(-100%);
  }

  .page-content {
    padding-left: 0;
  }

  body.page-collapsed .page-sidebar {
    transform: translateX(0);
  }
}
.list-timeline-v {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.list-timeline-v li {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}
.list-timeline-v li:not(:last-child) {
  margin-bottom: 1.5rem;
}
.list-timeline-v li .timeline-icon {
  margin-right: 1.5rem;
  z-index: 1;
  position: relative;
}
.list-timeline-v li .timeline-icon + div {
  -ms-flex: 1;
  flex: 1;
}

.timeline-icon {
  width: 3rem;
  height: 3rem;
  display: flex;
  line-height: 1;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 1.25rem;
  color: #389769;
}

@media (min-width: 768px) {
  .list-timeline-v li {
    width: 50%;
    margin-left: 50%;
    position: relative;
  }
  .list-timeline-v li .timeline-icon {
    margin-left: -1.5rem;
  }
  .list-timeline-v li:nth-child(even) {
    flex-direction: row-reverse;
    text-align: right;
    margin-left: 0;
    margin-right: 50%;
  }
  .list-timeline-v li:nth-child(even) .timeline-icon {
    margin-right: -1.5rem;
    margin-left: 1.5rem;
  }
  .list-timeline-v li:not(:last-child) {
    margin-bottom: 3rem;
    padding-bottom: 0;
  }
  .list-timeline-v li:not(:last-child):after {
    content: "";
    display: block;
    width: 1px;
    height: calc(100% - 2rem);
    border-left: 1px dashed #F0F5FA;
    position: absolute;
    top: 4rem;
  }
}
.step .step-item {
  margin-bottom: 1.25rem;
}
.step .step-row {
  position: relative;
  display: flex;
  padding-bottom: 1.25rem;
}
.step .step-icon {
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  margin-right: 1.25rem;
}
.step .step-icon::after {
  border-left: 2px dashed #F0F5FA;
  content: "";
  position: absolute;
  top: 3.75rem;
  left: calc(1.5rem - 1px);
  width: 1rem;
  height: calc(100% - 3.25rem);
}
.step .step-item:last-child .step-icon::after {
  height: 0;
}

.dropdown-toggle::after {
  margin-left: 0.25rem;
  opacity: 0.5;
}

.dropend .dropdown-toggle::after {
  margin-left: 0.25rem;
}

.dropstart .dropdown-toggle::before {
  margin-right: 0.75rem;
  opacity: 0.5;
  vertical-align: -0.15em;
}

.dropdown-toggle-split.dropdown-toggle::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split.dropdown-toggle::before {
  margin-right: 0px;
}

.page-item:not(:first-child) {
  margin-left: 0.375rem;
}
.page-item .page-link {
  border-radius: 0.375rem;
}

.pagination-sm .page-item:not(:first-child) {
  margin-left: 0.25rem;
}
.pagination-sm .page-link {
  border-radius: 0.25rem;
}

.pagination-lg .page-item:not(:first-child) {
  margin-left: 0.5rem;
}
.pagination-lg .page-link {
  border-radius: 0.575rem;
}

.choices {
  overflow: visible;
}

.choices__list {
  padding: 0px;
}

.choices__list.dropdown-menu {
  padding: 0px;
  min-width: auto;
  max-width: 100%;
  margin-top: -1px;
  border-radius: 0.375rem;
  width: 100%;
  border: 1px solid #E5EBF1;
  overflow: hidden;
}
.choices__list.dropdown-menu .dropdown-item {
  border-radius: 0px;
}
.choices__list.dropdown-menu .form-control {
  border-radius: 0px !important;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
}

.choices__list.dropdown-menu .choices__list {
  max-height: 300px;
  overflow: auto;
}

.form-control-sm + .choices__list.dropdown-menu {
  border-radius: 0.25rem;
}
.form-control-sm + .choices__list.dropdown-menu .dropdown-item {
  padding: 0.325rem 0.75rem;
  font-size: 0.875rem;
}

.form-control-lg + .choices__list.dropdown-menu {
  border-radius: 0.575rem;
}
.form-control-lg + .choices__list.dropdown-menu .dropdown-item {
  padding: 0.75rem 1.75rem;
  font-size: 1.25rem;
}

.is-open > .form-control {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.is-open .choices__list.dropdown-menu {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.is-open.is-flipped .choices__list.dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: -1px;
}

.is-open.is-flipped .form-control {
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.is-open.is-flipped .form-control {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.is-open.is-flipped .form-control + .choices__list.dropdown-menu {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.is-open.is-flipped .form-control-sm + .choices__list.dropdown-menu {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.is-open.is-flipped .form-control-lg + .choices__list.dropdown-menu {
  border-top-left-radius: 0.575rem;
  border-top-right-radius: 0.575rem;
}

.choices__list--multiple + .form-control {
  padding: 0px;
  display: inline-block;
  border: 0;
  box-shadow: none;
  width: auto !important;
  min-width: 3rem !important;
  background-color: transparent;
}

.choices__list--multiple .choices__item {
  font-size: 0.875rem;
  border-radius: 0.575rem;
  background-color: #389769;
  border-color: #358f64;
}

.choices[data-type*=select-multiple] .choices__button, .choices[data-type*=text] .choices__button {
  border-left-color: #6ab18f;
}

.choices__list--single .choices__item.choices__item--selectable {
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 1rem;
}

.choices.is-focused > .form-control {
  border-color: #9ccbb4;
  box-shadow: 0 0 0 0.25rem rgba(56, 151, 105, 0.1);
}

.choices[data-type*=select-one]::after {
  width: 0.75rem;
  height: 0.75rem;
  border-width: 0px;
  border-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -0.375rem;
  font-family: boxicons !important;
  font-weight: normal;
  content: "";
  transition: all 0.25s;
  box-sizing: content-box;
  font-size: 0.6rem;
  opacity: 0.5;
  line-height: 1;
}

.choices[data-type*=select-one].is-open::after {
  transform: rotate(180deg);
}

.jarallax {
  position: relative;
  z-index: 0;
}

.jarallax > .jarallax-img {
  position: absolute;
  object-fit: cover;
  /* support for plugin https://github.com/bfred-it/object-fit-images */
  font-family: "object-fit: cover;";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.js-hover-img {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  text-align: left;
}
.js-hover-img li {
  position: relative;
}
.js-hover-img li img {
  pointer-events: none;
  position: absolute;
  opacity: 0;
  bottom: 0;
  width: auto;
  height: 30vh;
  transition: opacity 0.4s;
}
.js-hover-img li .js-hover-img-link {
  position: relative;
  transition: all 0.6s;
  z-index: 2;
}
.js-hover-img li .js-hover-img-link a {
  display: block;
  text-decoration: none;
  color: currentColor;
  z-index: 3;
  line-height: 1.4;
  transition: all 0.35s;
}
.js-hover-img li .js-hover-img-link a > * {
  pointer-events: none;
  display: block;
}
.js-hover-img li:hover .img {
  opacity: 0.8;
}
.js-hover-img li:hover .js-hover-img-link {
  mix-blend-mode: luminosity;
}

.text-white .js-hover-img li:hover .js-hover-img-link, .text-light .js-hover-img li:hover .js-hover-img-link {
  mix-blend-mode: color-dodge;
}

:not(pre) > code[class*=language-], pre[class*=language-] {
  background: #0A0D10;
}

.token.comment, .token.block-comment, .token.prolog, .token.doctype, .token.cdata {
  color: #4FC04D;
}

.token.punctuation {
  color: #aeaeae;
}

pre[class*=language-] {
  margin: 0;
}

code[class*=language-], pre[class*=language-] {
  color: white;
  font-size: 13px;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.token.operator, .token.entity, .token.url {
  color: #fff;
}

.token.tag, .token.attr-name, .token.namespace, .token.deleted {
  color: #2ea1ff;
}

span.token.attr-name {
  color: #4bd7ff;
}

.token.string, .token.char, .token.attr-value, .token.regex, .token.variable {
  color: #ffa280;
}

.token.boolean, .token.number, .token.function {
  color: #4bd7ff;
}

.token.selector, .token.important, .token.atrule, .token.keyword, .token.builtin {
  color: #aeaeae;
}

.token.property, .token.class-name, .token.constant, .token.symbol {
  color: #ff9252;
}

#marker {
  display: inline-flex;
  font-family: boxicons !important;
  font-style: normal;
  font-weight: normal !important;
  position: relative;
  width: 2.75rem;
  height: 2.75rem;
  background-color: #389769;
  box-sizing: 0 2rem 4rem rgba(10, 13, 16, 0.175);
  color: #fff;
  align-items: center;
  justify-content: center;
  line-height: 1;
  font-size: 1.25rem;
  cursor: pointer;
  border-radius: 50rem;
}
#marker::after {
  content: "";
}

.mapboxgl-popup-content {
  padding: 1.25rem;
  border-radius: 0.575rem;
  font-family: var(--bs-font-sans-serif);
  font-size: 1rem;
}

.ms-layer.ms-title {
  font-size: 6rem;
  line-height: 1.1;
  font-family: var(--bs-font-sans-serif);
  font-weight: 600;
  letter-spacing: -0.015em;
}
.ms-layer.ms-title-alt {
  font-size: 4.25rem;
  line-height: 1.1;
  font-family: var(--bs-font-sans-serif);
  font-weight: 600;
  letter-spacing: -0.03rem;
}
.ms-layer.ms-caption {
  font-weight: normal;
  font-size: 2.25rem;
}

.ms-slider-alt .ms-view {
  background-color: #0A0D10;
  background: none;
}

.ms-btn .btn {
  font-size: 1rem;
  padding: 0.475rem 1.5rem;
}

@media (min-width: 768px) {
  .ms-btn .btn {
    padding: 1rem 1.75rem;
    font-size: 1.125rem;
  }
}
@media (max-width: 575.98px) {
  .ms-btn .btn {
    margin-top: 1.5rem;
  }
}
.ms-timerbar {
  height: 6px !important;
}

.ms-time-bar {
  height: 6px !important;
  background-color: rgba(56, 151, 105, 0.1);
  border-top: 0 !important;
}

@media (any-pointer: fine) {
  .cursor {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    pointer-events: none;
    z-index: 9999999;
    mix-blend-mode: difference;
  }

  .cursor__inner {
    display: block;
    margin-top: -0.25rem;
    margin-left: -0.25rem;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-color: #fff;
    opacity: 1;
    transform: scale(1);
    transition: all 0.3s ease-out;
  }

  .cursor.cHover .cursor__inner {
    width: 0.5rem;
    height: 0.5rem;
    margin-top: 0.25rem;
    margin-left: 0.25rem;
  }
}
@media (max-width: 767.98px) {
  .cursor {
    display: none;
  }
}
.ql-toolbar.ql-snow, .ql-container.ql-snow {
  border-color: #F0F5FA;
}

.ql-container {
  font-size: var(--bs-font-sans-serif);
  font-family: var(--bs-font-sans-serif);
}

.ql-editor {
  padding: 1rem 1rem;
  background-color: #fff;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  font-family: var(--bs-font-sans-serif);
}

.ql-container {
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.ql-toolbar.ql-snow {
  font-family: var(--bs-font-sans-serif);
  padding: 0.5rem 1rem;
  background-color: #fff;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  border-color: #F0F5FA;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: #14191E;
}

.ql-snow .ql-editor a {
  text-decoration: none;
  font-weight: 600;
}
.ql-snow .ql-editor img {
  border-radius: 0.575rem;
  box-shadow: 0 1rem 3rem rgba(10, 13, 16, 0.125);
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  border-color: transparent;
  box-shadow: 0 1rem 3rem rgba(10, 13, 16, 0.125);
  padding: 1rem;
}

.ql-snow .ql-editor code, .ql-snow .ql-editor pre {
  background-color: #F8FBFE;
  border-radius: 0.375rem;
}

.ql-snow .ql-editor code {
  padding: 4px 8px;
}

.ql-snow .ql-editor blockquote {
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 16px;
  background: transparent;
  border: 1px solid #F0F5FA;
  border-left-width: 5px;
  border-left-color: #389769;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.typed-cursor {
  font-weight: 300;
  font-size: 75%;
  display: inline-block;
  line-height: inherit;
  vertical-align: middle;
}
.typed-cursor.typed-cursor--blink {
  animation: blink 0.6s both infinite;
}

/* Recommended styles for Splitting */
.splitting .word,
.splitting .char {
  display: inline-block;
}

/* Psuedo-element chars */
.splitting .char {
  position: relative;
}

/**
 * Populate the psuedo elements with the character to allow for expanded effects
 * Set to `display: none` by default; just add `display: block` when you want
 * to use the psuedo elements
 */
.splitting .char::before,
.splitting .char::after {
  content: attr(data-char);
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  transition: inherit;
  user-select: none;
}

/* Expanded CSS Variables */
.splitting {
  /* The center word index */
  --word-center: calc((var(--word-total) - 1) / 2);
  /* The center character index */
  --char-center: calc((var(--char-total) - 1) / 2);
  /* The center character index */
  --line-center: calc((var(--line-total) - 1) / 2);
}

.splitting .word {
  /* Pecent (0-1) of the word's position */
  --word-percent: calc(var(--word-index) / var(--word-total));
  /* Pecent (0-1) of the line's position */
  --line-percent: calc(var(--line-index) / var(--line-total));
}

.splitting .char {
  --char-percent: calc(var(--char-index) / var(--char-total));
  --char-offset: calc(var(--char-index) - var(--char-center));
  --distance: calc((var(--char-offset) * var(--char-offset)) / var(--char-center));
  --distance-sine: calc(var(--char-offset) / var(--char-center));
  --distance-percent: calc((var(--distance) / var(--char-center)));
  line-height: 1.3;
}

.splitting.splitting-vertical .char,
.splitting.splitting-horizontal .char {
  overflow: hidden;
  color: transparent;
}
.splitting.splitting-vertical .char::before, .splitting.splitting-vertical .char::after,
.splitting.splitting-horizontal .char::before,
.splitting.splitting-horizontal .char::after {
  visibility: visible;
  color: #0A0D10;
  transition: transform 0.5s cubic-bezier(0.9, 0, 0.2, 1);
  transition-delay: calc(0.2s + (0.02s * (var(--char-index))));
}
.splitting.splitting-vertical .char::before,
.splitting.splitting-horizontal .char::before {
  transition-delay: calc(0.02s * (var(--char-index)));
}

.splitting.splitting-vertical .char::before {
  transform: translateY(100%);
}

.splitting.splitting-horizontal .char::before {
  transform: translateX(110%);
}

.shift-right:before {
  content: "";
  font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: normal !important;
  vertical-align: middle;
  display: inline-block;
  transform: scaleY(0) translateX(-1em);
  margin-right: -0.25em;
  font-size: 1.5rem;
}
.shift-right:before,
.shift-right .char {
  transition: transform 0.8s cubic-bezier(0.75, 0, 0.24, 0.98);
  transition-delay: calc(0.015s * var(--char-index));
}
.shift-right .char {
  transform: translateX(-0.15em);
}
.shift-right:hover:before {
  transform: scaleY(1) translateX(0em);
}
.shift-right:hover .char {
  transform: translateX(1em);
  transform: translateX(0.5em) translateX(calc(0.1em * var(--char-index)));
}

.spliflip-it .char,
.spliflip-3d .char {
  transition: transform 0.6s cubic-bezier(0.6, 0, 0, 0.6);
  transition-delay: calc(0.3s * (1 - var(--distance-percent)));
  transition-delay: calc(0.3s * var(--distance-percent));
}
.spliflip-it:hover .char,
.spliflip-3d:hover .char {
  transform: rotateX(-1turn);
  transition-duration: 1s;
  transition-delay: calc(0.3s * var(--distance-percent));
  transition-delay: calc(0.3s * (1 - var(--distance-percent)));
}

.spliflip-3d {
  perspective: 200px;
  transform-style: preserve-3d;
}

.text-white .splitting-vertical .char:before, .text-white .splitting-vertical .char:after,
.text-white .splitting-horizontal .char:before,
.text-white .splitting-horizontal .char:after,
.text-light .splitting-vertical .char:before,
.text-light .splitting-vertical .char:after,
.text-light .splitting-horizontal .char:before,
.text-light .splitting-horizontal .char:after {
  color: #fff;
}

.split-hover:hover .splitting-vertical .char::before,
.split-hover:hover .splitting-horizontal .char::before {
  transition-delay: calc(0.2s + (0.02s * (var(--char-index))));
}
.split-hover:hover .splitting-vertical .char::after,
.split-hover:hover .splitting-horizontal .char::after {
  transition-delay: calc(0.02s * (var(--char-index)));
}
.split-hover:hover .splitting-vertical .char {
  transform: translateY(0);
}
.split-hover:hover .splitting-vertical .char::before {
  transform: translateY(0%);
}
.split-hover:hover .splitting-vertical .char::after {
  transform: translateY(-100%);
}
.split-hover:hover .splitting-horizontal .char::before {
  transform: translateX(0%);
}
.split-hover:hover .splitting-horizontal .char::after {
  transform: translateX(-110%);
}
.split-hover:hover .splitting-vertical .char::before {
  transform: translateY(100%);
}
.split-hover:hover .splitting-vertical .char::before {
  transform: translateY(0%);
}
.split-hover:hover .splitting-vertical .char::after {
  transform: translateY(-100%);
}
.split-hover:hover.split-hover:hover .splitting-vertical .char,
.split-hover:hover.split-hover:hover .splitting-horizontal .char {
  opacity: 1;
}
.split-hover:hover.split-hover:hover .splitting-vertical .char::before,
.split-hover:hover.split-hover:hover .splitting-horizontal .char::before {
  transition-delay: calc(0.2s + (0.02s * (var(--char-index))));
}
.split-hover:hover.split-hover:hover .splitting-vertical .char::after,
.split-hover:hover.split-hover:hover .splitting-horizontal .char::after {
  transition-delay: calc(0.06s * (var(--char-index)));
}

.splitting {
  overflow: hidden;
}
.splitting .char {
  transition: transform 0.3s cubic-bezier(0.3, 0, 0.3, 1), opacity 0.3s linear;
  transition-delay: calc(30ms + (20ms * var(--char-index)));
}
.splitting[class^=splitting-]:not(.splitting-vertical):not(.splitting-horizontal):not(.splitting-shift-right):not(.spliflip-3d):not(.spliflip-it) .char, .splitting[class*=splitting-]:not(.splitting-vertical):not(.splitting-horizontal):not(.splitting-shift-right):not(.spliflip-3d):not(.spliflip-it) .char {
  transition-delay: calc(0ms + (0ms * var(--char-index)));
  opacity: 0;
}
.splitting.splitting-up .char {
  transform: translateY(100%);
}
.splitting.splitting-down .char {
  transform: translateY(-100%);
}
.splitting.splitting-left .char {
  transform: translateX(calc(-0.2em * var(--char-index)));
}
.splitting.splitting-right .char {
  transform: translateX(calc(0.2em * var(--char-index)));
}
.splitting.splitting-zoomIn .char {
  transform: scale3d(0.6, 0.8, 0.6);
}
.splitting.splitting-zoomOut .char {
  transform: scale3d(1.6, 1.3, 1.6);
}

.aos-init.aos-animate .splitting[class^=splitting-]:not(.splitting-vertical):not(.splitting-horizontal):not(.splitting-shift-right):not(.spliflip-3d):not(.spliflip-it) .char, .aos-init.aos-animate .splitting[class*=splitting-]:not(.splitting-vertical):not(.splitting-horizontal):not(.splitting-shift-right):not(.spliflip-3d):not(.spliflip-it) .char,
.card-split-hover:hover .splitting[class^=splitting-]:not(.splitting-vertical):not(.splitting-horizontal):not(.splitting-shift-right):not(.spliflip-3d):not(.spliflip-it) .char,
.card-split-hover:hover .splitting[class*=splitting-]:not(.splitting-vertical):not(.splitting-horizontal):not(.splitting-shift-right):not(.spliflip-3d):not(.spliflip-it) .char {
  transition-delay: calc(40ms + (20ms * var(--char-index)));
  opacity: 1;
  transform: none;
}

.splitting.cells img {
  width: 100%;
  display: block;
}

@supports (display: grid) {
  .splitting.cells {
    position: relative;
    overflow: hidden;
    background-size: cover;
    visibility: hidden;
  }

  .splitting .cell-grid {
    background: inherit;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template: repeat(var(--row-total), 1fr)/repeat(var(--col-total), 1fr);
  }

  .splitting .cell {
    background: inherit;
    position: relative;
    overflow: hidden;
  }

  .splitting .cell-inner {
    background: inherit;
    position: absolute;
    visibility: visible;
    width: calc(100% * var(--col-total));
    height: calc(100% * var(--row-total));
    /* Position properly */
    left: calc(-100% * var(--col-index));
    top: calc(-100% * var(--row-index));
  }

  .splitting .cell {
    --center-x: calc((var(--col-total) - 1) / 2);
    --center-y: calc((var(--row-total) - 1) / 2);
    --offset-x: calc(var(--col-index) - var(--center-x));
    --offset-y: calc(var(--row-index) - var(--center-y));
    --distance-x: calc( (var(--offset-x) * var(--offset-x)) / var(--center-x) );
    --distance-y: calc( (var(--offset-y) * var(--offset-y)) / var(--center-y) );
  }
}
.splitting .cell,
.splitting .cell__inner,
.splitting img {
  transition: transform 1s cubic-bezier(0.63, 0.01, 0.07, 0.99), opacity 1s linear;
  /* No animation on leave */
  transition-delay: calc( 0.1s * var(--cell-index) );
  /* Reverse the animation */
  transition-delay: calc( 0.1s * (var(--cell-total) - var(--cell-index)) );
}
.splitting img {
  transform: scale(0.8);
  transition-delay: 0s;
  visibility: visible;
  opacity: 0;
}
.splitting:hover .cell,
.splitting:hover .cell__inner {
  transition-delay: calc( 0.1s * var(--cell-index) );
}
.splitting:hover img {
  opacity: 0.99;
  transform: scale(1);
  transition-delay: calc( 0.1s * var(--cell-total) );
}

.splitting-img.img-vertical:hover .cell {
  transform: translateY(100%);
}

.splitting-img.img-horizontal:hover .cell {
  transform: translateX(100%);
}

.dz-preview {
  display: flex;
  flex-wrap: wrap;
}
.dz-preview .badge {
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropzone-image-preview img {
  max-width: 100%;
}

.listing-img-preview {
  margin-right: 0.35rem;
}